import React, { ReactEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Column, TableInstance } from 'react-table';
import { InstanceType } from 'types';
import EntityListPagesWrapper from '../EntityListPagesWrapper';
import { instancesActions, instancesSelectors } from '../../modules/instances';
import Loader from '../Loader';
import { selectFilterMethod } from '../../utils/helpers/table';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';
import { INSTANCE_ORIGINS } from '../../modules/instances/instancesSelectors';
import OpenConfiguratorLink from '../OpenConfiguratorLink';
import { Button, CsvDownloader, Table } from '../Atoms';
import { getVisitorUrl } from '../../utils/appRoutes';
import InstanceOriginFilter from './InstanceOriginFilter';
import { InstanceForm } from './InstanceForm';

const useInstancesList: () => { list: InstanceType[]; loading: boolean } = () => {
  const list = useSelector<any, InstanceType[]>(instancesSelectors.selectInstanceList);
  const loading = useSelector(instancesSelectors.selectInstancesRequest);
  const [requested, setRequested] = useState(false);

  const dispatch = useDispatch();
  // eslint-disable-next-line padding-line-between-statements
  useEffect(() => {
    if (list.length === 0 && !requested) {
      setRequested(true);
      dispatch(instancesActions.fetchInstances());
    }
  }, [dispatch, list.length, requested]);

  return { list, loading };
};

const getColumns: (arg0: ReactEventHandler, arg1: (i: string) => void) => Column[] = (
  onDeleteInstance,
  setCurrentInstance
) => {
  return [
    {
      accessor: '_id',
      Header: 'Id',
      minWidth: 150,
      className: 'p-2',
      Cell: (table: TableInstance) => <OpenConfiguratorLink type="id" link={table.value} caption={table.value} />
    },
    {
      accessor: 'populated.seed.name',
      Header: 'Seed',
      Cell: (table: TableInstance) => (
        <>
          {table.value} <br /> <small className="text-muted">{table.row.original.populated?.seed?.version}</small>
        </>
      ),
      className: 'p-2',
      minWidth: 150
    },
    {
      accessor: 'origin',
      width: 50,
      filterMethod: selectFilterMethod,
      // eslint-disable-next-line react/prop-types
      Filter: InstanceOriginFilter,
      Cell: (table: TableInstance) => {
        if (table.value === INSTANCE_ORIGINS.CREATOMUS) {
          return <i className="fa fa-user" title="Registered user" />;
        }

        if (table.value === INSTANCE_ORIGINS.PARTNER) {
          return <i className="fa fa-user-o" title="Partner user" />;
        }

        return <i className="fa fa-user-secret" title="Unegistered user" />;
      },
      resizable: false
    },
    {
      Header: 'Username',
      accessor: 'populated.visitor.username',
      filterable: true,
      minWidth: 150,
      Cell: (table: TableInstance) => {
        if (table.value) {
          return (
            <Link title={table.value} to={getVisitorUrl(table.row.original.createdBy)}>
              {table.value}
            </Link>
          );
        }

        if (table.row.original.partnerUserId) {
          return (
            <small className="text-muted" title={`Partner user: ${table.row.original.partnerUserId}`}>
              {table.row.original.partnerUserId}
            </small>
          );
        }

        return null;
      }
    },
    {
      accessor: 'hasLead',
      Cell: (table: TableInstance) => (table.value ? <i className="fa fa-address-card" title="Has lead" /> : null),
      width: 30,
      resizable: false,
      disableFilters: true
    },
    {
      accessor: 'instanceName',
      Header: 'Name',
      filterable: true,
      Cell: (table: TableInstance) => <span title={table.value}>{table.value}</span>
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      maxWidth: 100,
      Cell: (table: TableInstance) => new Date(table.value).toLocaleDateString(),

      disableFilters: true
    },
    {
      accessor: '_id',
      id: 'delete',
      Cell: (table: TableInstance) => (
        <Button
          key={table.row.original._id}
          size="sm"
          color="link"
          data-id={table.value}
          title="Delete"
          onClick={onDeleteInstance}
        >
          <i className="fa fa-trash" />
        </Button>
      ),
      width: 50,
      disableFilters: true,
      disableSortBy: true
    },
    {
      accessor: 'csv',
      width: 150,
      Header: 'Download data',
      disableFilters: true,
      disableSortBy: true,
      Cell: (table: TableInstance) => {
        const { populated = {}, _id, createdAt } = table.row.original;
        const { seed = {} } = populated;
        const { name = 'unnamed', version = '0' } = seed;

        return table.value ? (
          <CsvDownloader
            filename={`${name}.${version}.${_id}.${createdAt}`}
            title="Download CSV"
            size="sm"
            data={table.value}
          />
        ) : (
          <small className="text-danger">No choice data</small>
        );
      }
    },
    {
      accessor: '_id',
      width: 50,
      id: 'edit',
      disableFilters: true,
      disableSortBy: true,
      Cell: (table: TableInstance) => (
        <Button
          title="Edit"
          color="link"
          data-id={table.value}
          onClick={e => setCurrentInstance(e.currentTarget.dataset.id || '')}
        >
          <span className="fa fa-edit" />
        </Button>
      )
    }
  ];
};
const InstanceList = () => {
  const dispatch = useDispatch();
  const hasRights = useUserRights();

  const { list, loading } = useInstancesList();
  const [currentInstance, setCurrentInstance] = useState('');

  const handleDeleteInstance: ReactEventHandler<HTMLButtonElement> = useCallback(
    e => {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm('Are you sure you wish to delete this instance?');
      const { id } = e.currentTarget.dataset;

      if (confirm && id) {
        dispatch(instancesActions.deleteEntity(id));
      }
    },
    [dispatch]
  );

  const columns = useMemo(() => {
    return getColumns(handleDeleteInstance, setCurrentInstance);
  }, [handleDeleteInstance]);

  const hiddenColumns: string[] = useMemo(() => {
    const result = [];

    if (!hasRights([RIGHTS.INSTANCES__DELETE])) {
      result.push('delete');
    }

    if (!hasRights([RIGHTS.INSTANCES__UPDATE])) {
      result.push('edit');
    }

    return result;
  }, [hasRights]);

  return (
    <Loader loading={loading}>
      <Table
        columns={columns}
        hiddenColumns={hiddenColumns}
        data={list}
        className="app-entity-list -striped -highlight"
      />
      {currentInstance ? <InstanceForm instanceId={currentInstance} onClose={() => setCurrentInstance('')} /> : null}
    </Loader>
  );
};

const InstancesPage = () => {
  return <EntityListPagesWrapper header="Instances" list={<InstanceList />} />;
};

export default InstancesPage;
