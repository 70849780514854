import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { partsSelectors, partsActions } from '../../../modules/parts';
import { usePartList, useQueryParams, useUserRights } from '../../../utils/hooks';
import Loader from '../../Loader';
import Graph from '../../Graph';
import PartModals, { MODAL_TYPES } from '../PartList/PartModals';
import usePartEditor from '../usePartEditor';
import PartForm from '../PartList/PartModals/PartForm';
import { MODAL_MODES } from '../PartList/PartModals/PartModals';
import { RIGHTS } from '../../../utils/rights';
import PartControls from './PartControls';

const usePartsGraph = (onOpenPartModal, handleDelete) => {
  const hasRights = useUserRights();
  const setParams = useQueryParams();

  const onOpenPart = useCallback(
    e => {
      const { id } = e.currentTarget.dataset;

      setParams({ type: MODAL_TYPES.PART, partId: id });
    },
    [setParams]
  );

  const onOpenPartRaw = useCallback(
    e => {
      const { id } = e.currentTarget.dataset;

      setParams({ type: MODAL_TYPES.PART, partId: id, mode: MODAL_MODES.RAW });
    },
    [setParams]
  );

  // memoized so new object wouldn't be created
  const nodeActions = useMemo(() => {
    const actions = [
      { name: 'edit', label: 'Edit', clickHandler: onOpenPart },
      { name: 'editRaw', label: 'Edit Raw', clickHandler: onOpenPartRaw }
    ];

    if (hasRights([RIGHTS.PARTS__DELETE])) {
      actions.push({ name: 'delete', label: 'Delete', clickHandler: e => handleDelete(e.currentTarget.dataset.id) });
    }

    return actions;
  }, [handleDelete, hasRights, onOpenPart, onOpenPartRaw]);

  const graphActions = useMemo(
    () => [
      {
        name: 'addPart',
        onClick: onOpenPartModal,
        label: '+ Add part'
      }
    ],
    [onOpenPartModal]
  );

  return {
    nodeActions,
    graphActions
  };
};

const PartGraph = () => {
  const { seedId, projectId, onOpenPartModal, isPartModalOpen, onClosePartModal, onCreatePart, handleDelete } =
    usePartEditor();

  const { request } = usePartList(seedId);
  const dispatch = useDispatch();

  const { nodes, edges, rootNodeId } = useSelector(state => partsSelectors.selectPartsNodesWithEdges(state, seedId));
  const { nodeActions, graphActions } = usePartsGraph(onOpenPartModal, handleDelete);

  const nodeUpdateHandler = useCallback(
    ({ nodeId, dX, dY, initialGraphX, initialGraphY }) => {
      dispatch(
        partsActions.updatePartPosition({ _id: nodeId, graphX: initialGraphX + dX, graphY: initialGraphY + dY })
      );
    },
    [dispatch]
  );

  const nodesUpdateHandler = useCallback(
    calculatedNodes => {
      dispatch(partsActions.reorderParts(calculatedNodes));
    },
    [dispatch]
  );

  const submitHandler = useCallback(() => {
    dispatch(partsActions.saveUnsavedParts(seedId));
  }, [dispatch, seedId]);

  const resetHandler = useCallback(() => {
    dispatch(partsActions.resetUnsavedPartsInfo());
  }, [dispatch]);

  const isPristine = !useSelector(partsSelectors.selectArePartsUnsaved);

  return (
    <Loader loading={request}>
      <Graph
        nodes={nodes}
        edges={edges}
        rootNodeId={rootNodeId}
        nodeActions={nodeActions}
        NodeChildren={PartControls}
        onUpdateNode={nodeUpdateHandler}
        onReorderNodes={nodesUpdateHandler}
        onSubmit={submitHandler}
        onReset={resetHandler}
        isPristine={isPristine}
        graphActions={graphActions}
      />
      <PartModals projectId={projectId} />
      {isPartModalOpen ? (
        <PartForm
          onSubmit={onCreatePart}
          close={onClosePartModal}
          part={{}}
          creation
          projectId={projectId}
          seedId={seedId}
        />
      ) : null}
    </Loader>
  );
};

export default PartGraph;
