import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table-6';
import filesize from 'filesize';
import { Button } from '../../Atoms';
import { TYPE_ASSET_LIST, TYPE_ASSET_PARENTS } from '../../../utils/propTypes';
import { defaultFilterMethod } from '../../../utils/helpers/table';
import Loader from '../../Loader';
import { useModal } from '../../../utils/hooks';
import AssetModal from '../AssetModal';
import { ASSET_TYPES } from '../../../modules/assets';
import Expander from './Expander';
import AssetNameGroup from './AssetNameGroup';

const groupListByFilename = list => {
  // group by filename;
  const indices = {};
  const groupedList = [];

  list.forEach(asset => {
    const { filename, uploadDate, length, _id, metadata } = asset;
    const normalisedDate = new Date(uploadDate);

    if (indices[asset.filename] === undefined) {
      groupedList.push({
        _id,
        filename,
        uploadDate: normalisedDate,
        length,
        metadata,
        list: [{ ...asset, uploadDate: normalisedDate }]
      });
      indices[asset.filename] = groupedList.length - 1;
    } else {
      const index = indices[asset.filename];

      groupedList[index].list.push({ ...asset, uploadDate: normalisedDate });

      if (groupedList[index].uploadDate < normalisedDate) {
        groupedList[index] = { ...groupedList[index], length, uploadDate: normalisedDate, _id };
      }
    }
  });

  return groupedList;
};

const AssetGroup = ({
  assetType,
  list,
  deleteAsset,
  setAssetProject,
  getAssetParent,
  copyAssetToProject,
  getAssetUrl,
  isLoading,
  assetsInfo,
  assetsList,
  assetParentsRequesting,
  selectedProject,
  hideChangingProjects
}) => {
  const { isOpen, open, close } = useModal();
  const [selectedAsset, selectAsset] = useState();
  const [expanded, setExpanded] = useState({});

  const resetExpanded = useCallback(() => setExpanded({}), []);

  // reset expanded rows when list changes
  useEffect(() => {
    resetExpanded();
  }, [list, resetExpanded]);

  // overwrite expander onClick if only one asset in list
  const getExpanderProps = useCallback((state, rowInfo) => {
    if (rowInfo && rowInfo.original && rowInfo.original.list && rowInfo.original.list.length < 2) {
      return { onClick: () => {} };
    }

    return {};
  }, []);

  const assetClickHandler = useCallback(
    e => {
      const { assetId } = e.currentTarget.dataset;

      selectAsset(assetId);
      open();
    },
    [open]
  );

  const renderFileCell = useCallback(
    row =>
      assetType === ASSET_TYPES.MODELS ? (
        <Button
          tag="a"
          color="link"
          block
          size="sm"
          title="Preview"
          href={getAssetUrl({
            fileName: row.value,
            projectId: row.original?.metadata?.projectId || ''
          })}
          download={row.value}
        >
          {row.value}
        </Button>
      ) : (
        <Button
          color="link"
          data-asset-id={row.original._id}
          onClick={assetClickHandler}
          block
          size="sm"
          title="Preview"
        >
          {row.value}
        </Button>
      ),
    [assetClickHandler, assetType, getAssetUrl]
  );
  const renderSizeCell = useCallback(row => <span title={row.value}>{filesize(row.value)}</span>, []);
  const renderDateCell = useCallback(row => {
    return row.value && row.value.toLocaleString();
  }, []);

  const renderAssetModal = useCallback(() => {
    /* This is for all assetslist because asset gets removed from regular list when updating project   */
    const asset = assetsList.find(({ _id }) => _id === selectedAsset);

    return asset ? (
      <AssetModal
        asset={asset}
        assetInfo={assetsInfo[asset.filename]}
        getAssetUrl={getAssetUrl}
        setAssetProject={setAssetProject}
        getAssetParent={getAssetParent}
        copyAssetToProject={copyAssetToProject}
        deleteAsset={deleteAsset}
        onClose={close}
        assetParentsRequesting={assetParentsRequesting}
        selectedProject={selectedProject}
        hideChangingProjects={hideChangingProjects}
      />
    ) : null;
  }, [
    assetsList,
    assetsInfo,
    getAssetUrl,
    setAssetProject,
    getAssetParent,
    copyAssetToProject,
    deleteAsset,
    close,
    assetParentsRequesting,
    selectedProject,
    hideChangingProjects,
    selectedAsset
  ]);

  const columns = [
    { expander: true, Expander, width: 50, getProps: getExpanderProps },
    {
      Header: 'File',
      accessor: 'filename',
      filterable: true,
      Cell: renderFileCell,
      minWidth: 250
    },
    {
      Header: 'Size',
      filterable: false,
      accessor: 'length',
      Cell: renderSizeCell,
      maxWidth: 100
    },
    {
      Header: 'Date',
      accessor: 'uploadDate',
      id: 'uploadDate',
      filterable: false,
      Cell: renderDateCell,

      width: 200
    }
  ];

  const groupedList = useMemo(() => groupListByFilename(list), [list]);

  return (
    <Loader loading={isLoading && list.length === 0}>
      <ReactTable
        data={groupedList}
        defaultPageSize={20}
        columns={columns}
        filterable
        resizable={false}
        defaultSorted={[{ id: 'uploadDate', desc: true }]}
        defaultFilterMethod={defaultFilterMethod}
        SubComponent={AssetNameGroup(assetClickHandler)}
        expanded={expanded}
        onExpandedChange={setExpanded}
        onPageChange={resetExpanded}
        onPageSizeChange={resetExpanded}
        className="app-entity-list -striped -highlight"
      />
      {isOpen ? renderAssetModal() : null}
    </Loader>
  );
};

AssetGroup.propTypes = {
  list: TYPE_ASSET_LIST,
  assetsList: TYPE_ASSET_LIST,
  deleteAsset: PropTypes.func.isRequired,
  setAssetProject: PropTypes.func.isRequired,
  getAssetParent: PropTypes.func.isRequired,
  copyAssetToProject: PropTypes.func.isRequired,
  getAssetUrl: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  assetsInfo: TYPE_ASSET_PARENTS,
  assetParentsRequesting: PropTypes.bool,
  selectedProject: PropTypes.string,
  hideChangingProjects: PropTypes.bool,
  assetType: PropTypes.string.isRequired
};

AssetGroup.defaultProps = {
  list: [],
  assetsList: [],
  assetsInfo: [],
  assetParentsRequesting: false,
  isLoading: false,
  selectedProject: '',
  hideChangingProjects: false
};

export default AssetGroup;
