import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table-6';
import b from 'b_';
import { TYPE_VISITOR_LIST } from '../../../utils/propTypes';
import { defaultFilterMethod, selectFilterMethod } from '../../../utils/helpers/table';
import { instancesActions, instancesSelectors } from '../../../modules/instances';
import { factoryMapsActions } from '../../../modules/factoryMaps';
import { uiActions, uiSelectors } from '../../../modules/ui';
import Loader from '../../Loader';
import VisitorInstanceList from '../../VisitorInstanceList';
import SelectFilter from '../../SelectFilter';
import { getVisitorUrl } from '../../../utils/appRoutes';

import './VisitorList.scss';

const visitorList = b.with('visitor-list');

const VISITOR_STATUSES = instancesSelectors.VISITOR_STATUS.map(status => ({ label: status, value: status }));

export class VisitorList extends React.PureComponent {
  componentDidMount() {
    const { visitors, fetchInstances, fetchFactoryMaps } = this.props;

    if (!visitors.length) {
      fetchInstances();
      fetchFactoryMaps();
    }
  }

  renderSelectFilter = ({ filter, onChange }, options = []) => {
    return <SelectFilter options={options} filter={filter} onChange={onChange} />;
  };

  render() {
    const { request, visitors, expanded, changeExpanded } = this.props;

    const columns = [
      {
        resizable: false,
        expander: true
      },
      {
        id: 'username',
        Header: 'Visitor',
        accessor: 'username',
        className: 'p-2'
      },
      {
        Header: 'Name in request',
        accessor: 'name',
        className: 'p-2'
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'p-2',
        resizable: false,
        width: 100,
        filterMethod: selectFilterMethod,
        Filter: filter => this.renderSelectFilter(filter, VISITOR_STATUSES)
      },
      {
        width: 150,
        accessor: '_id',
        filterable: false,
        Cell: row => (
          <Link to={getVisitorUrl(row.value)}>
            Open separately <i className="fa fa-external-link" />
          </Link>
        )
      }
    ];

    return (
      <Loader loading={request}>
        <ReactTable
          data={visitors}
          pageSize={visitors.length}
          showPagination={false}
          columns={columns}
          expanded={expanded}
          filterable
          resizable={false}
          defaultSorted={[{ id: 'username', desc: false }]}
          defaultFilterMethod={defaultFilterMethod}
          SubComponent={row => (
            <div className={visitorList('instances')}>
              <VisitorInstanceList visitor={row.original} />
            </div>
          )}
          onExpandedChange={changeExpanded}
          className={`${visitorList()} app-entity-list -striped -highlight`}
        />
      </Loader>
    );
  }
}

VisitorList.defaultProps = {
  visitors: [],
  request: false
};

VisitorList.propTypes = {
  fetchInstances: PropTypes.func.isRequired,
  fetchFactoryMaps: PropTypes.func.isRequired,
  changeExpanded: PropTypes.func.isRequired,
  expanded: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  visitors: TYPE_VISITOR_LIST,
  request: PropTypes.bool
};

const mapStateToProps = state => ({
  visitors: instancesSelectors.selectVisitorList(state),
  request: instancesSelectors.selectInstancesRequest(state),
  expanded: uiSelectors.selectVisitorListExpanded(state)
});

const mapDispatchToProps = {
  fetchInstances: instancesActions.fetchInstances,
  fetchFactoryMaps: factoryMapsActions.fetchFactoryMaps,
  changeExpanded: uiActions.visitorListExpand
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorList);
