import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { State } from '../types';

interface SelectOptions {
  value: string;
  label: string;
}

export const FIELD_TYPES = {
  INPUT: {
    TEXT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
    PHONE: 'tel'
  },
  TEXTAREA: 'textarea',
  SELECT: 'select',
  COUNTRY_LIST: 'country-list',
  CHECKBOX: 'checkbox',
  CONSENT: 'consent',
  DIVIDER: '--- divider ---'
};

export const FIELDS = [
  { label: 'Text', value: FIELD_TYPES.INPUT.TEXT },
  { label: 'Number', value: FIELD_TYPES.INPUT.NUMBER },
  { label: 'Email', value: FIELD_TYPES.INPUT.EMAIL },
  { label: 'Phone', value: FIELD_TYPES.INPUT.PHONE },
  { label: 'Textarea', value: FIELD_TYPES.TEXTAREA },
  { label: 'Country List', value: FIELD_TYPES.COUNTRY_LIST },
  { label: 'Select', value: FIELD_TYPES.SELECT },
  { label: 'Checkbox', value: FIELD_TYPES.CHECKBOX },
  { label: 'Consent', value: FIELD_TYPES.CONSENT },
  { label: 'Divider', value: FIELD_TYPES.DIVIDER }
];

export const getFields = (type: string, index: number) => {
  const fields = [];

  if (![FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(type)) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].name`}
        name={`settings.contactForm.fields[${index}].name`}
        label="Name"
        required
      />
    );
  }

  if (FIELD_TYPES.CONSENT === type) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].label`}
        name={`settings.contactForm.fields[${index}].label`}
        label="Label"
      />
    );
  }

  if (FIELD_TYPES.SELECT === type) {
    fields.push(
      <FormField.ArrayWrapper<ProjectType>
        headerFormat={value => get(value, 'label', 'untitled')}
        name={`settings.contactForm.fields[${index}].options`}
        key={`settings.contactForm.fields[${index}].options`}
        label="Options"
        description="List of available options"
      >
        <FormField.Text<SelectOptions> key="value" name="value" label="Value" />
        <FormField.Text<SelectOptions> key="label" name="label" label="Label" />
      </FormField.ArrayWrapper>
    );
  }

  if (![FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(type)) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].autocomplete`}
        name={`settings.contactForm.fields[${index}].autocomplete`}
        label="Autocomplete"
      />
    );
  }

  if (![FIELD_TYPES.INPUT.EMAIL, FIELD_TYPES.CHECKBOX, FIELD_TYPES.CONSENT, FIELD_TYPES.DIVIDER].includes(type)) {
    fields.push(
      <FormField.Toggle<ProjectType>
        key={`settings.contactForm.fields[${index}].required`}
        name={`settings.contactForm.fields[${index}].required`}
        label="Required"
        description=""
      />
    );
  }

  if (FIELD_TYPES.CHECKBOX === type) {
    fields.push(
      <FormField.Text<ProjectType>
        key={`settings.contactForm.fields[${index}].title`}
        name={`settings.contactForm.fields[${index}].title`}
        label="Title"
        description=""
      />
    );
    fields.push(
      <FormField.Toggle<ProjectType>
        key={`settings.contactForm.fields[${index}].default`}
        name={`settings.contactForm.fields[${index}].default`}
        label="Prechecked"
        description=""
      />
    );
  }

  return fields;
};

export const ContactFormFieldsWrapper = (props: { index?: number; state?: State }) => {
  if (!props.state || props.index === undefined) {
    return null;
  }

  if (props.state && props.state.contactFormType) {
    return <>{getFields(props.state.contactFormType[props.index], props.index as number)}</>;
  }

  return null;
};
