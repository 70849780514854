import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { TYPE_INSTANCE } from '../../../../utils/propTypes';
import { RIGHTS } from '../../../../utils/rights';
import ProtectedButton from '../../../ProtectedButton';
import { instancesActions } from '../../../../modules/instances';
import ConfirmSendToProduction from '../MappedInstance/ConfirmSendToProduction';
import './SendToProduction.scss';

const sendToProduction = b.with('send-to-production');

export class SendToProduction extends React.PureComponent {
  state = {
    isConfirmModalShown: false
  };

  get isLeadCancelled() {
    const { instance } = this.props;

    return instance.lead && instance.lead.cancelOrder;
  }

  get isLeadSent() {
    const { instance } = this.props;

    return instance.lead && instance.lead.sentToProduction;
  }

  get hasMappedInstance() {
    const { instance } = this.props;

    return Boolean(instance.mappedInstanceId);
  }

  get hasFactoryMap() {
    const { instance } = this.props;

    return Boolean(instance.factoryMap);
  }

  handleSend = () => {
    const { instance } = this.props;

    this.props.sendToProduction(instance._id);
    this.closeConfirmModal();
  };

  handleSendToProduction = () => {
    this.showConfirmModal();
  };

  handleCancelOrder = () => {
    const { instance, cancelOrder } = this.props;
    const response = window.confirm('Are you sure that you want cancel the order?'); // eslint-disable-line no-alert

    if (response) {
      cancelOrder(instance._id);
    }
  };

  showConfirmModal = () => this.setState({ isConfirmModalShown: true });

  closeConfirmModal = () => this.setState({ isConfirmModalShown: false });

  renderDetails() {
    if (!this.isLeadSent) {
      return null;
    }

    const { sentToProductionAt, cancelOrderAt } = this.props.instance.lead;

    return (
      <div className={sendToProduction('details')}>
        <dl className="d-flex flex-wrap mb-0">
          <dt className={sendToProduction('label')}>Sent at</dt>
          <dd className={sendToProduction('info')}>{new Date(sentToProductionAt).toLocaleString()}</dd>
          {this.isLeadCancelled ? (
            <>
              <dt className={sendToProduction('label')}>Cancel at</dt>
              <dd className={sendToProduction('info')}>{new Date(cancelOrderAt).toLocaleString()}</dd>
            </>
          ) : null}
        </dl>
      </div>
    );
  }

  renderActions() {
    if (this.isLeadCancelled) {
      return null;
    }

    if (this.isLeadSent) {
      return (
        <ProtectedButton
          rights={[RIGHTS.VISITORS__CANCEL_ORDER]}
          onClick={this.handleCancelOrder}
          size="sm"
          color="danger"
          outline
        >
          <i className="fa fa-fw fa-ban" /> Cancel order
        </ProtectedButton>
      );
    }

    return (
      <ProtectedButton
        rights={[RIGHTS.VISITORS__SEND_TO_PRODUCTION]}
        onClick={this.handleSendToProduction}
        size="sm"
        color="primary"
        outline
      >
        <i className="fa fa-fw fa-paper-plane-o" /> Send to production
      </ProtectedButton>
    );
  }

  renderMappedInstanceGenerator() {
    if (this.state.isConfirmModalShown) {
      return (
        <ConfirmSendToProduction
          onClose={this.closeConfirmModal}
          onContinue={this.handleSend}
          hasMappedInstance={this.hasMappedInstance}
          hasFactoryMap={this.hasFactoryMap}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <div className={sendToProduction()}>
        <h5 className="text-primary">Production</h5>
        {this.renderActions()}
        {this.renderDetails()}
        {this.renderMappedInstanceGenerator()}
      </div>
    );
  }
}

SendToProduction.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  sendToProduction: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  sendToProduction: instancesActions.sendToProduction,
  cancelOrder: instancesActions.cancelOrder
};

export default connect(null, mapDispatchToProps)(SendToProduction);
