import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button } from '../../Atoms';
import { TYPE_STATISTICS } from '../../../utils/propTypes';
import { statisticsActions, statisticsSelectors } from '../../../modules/statistics';
import Loader from '../../Loader';
import { msToTime } from '../../../utils';
import StatisticsData from './StatisticsData';

import './VisitorStatistics.scss';

const visitorStatistics = b.with('visitor-statistics');

export class VisitorStatistics extends React.PureComponent {
  state = {
    selectedSeed: null
  };

  componentDidMount() {
    this.getStatistics();
  }

  getStatistics = () => {
    const { fetchStatistics, userId } = this.props;

    fetchStatistics(userId);
  };

  getSelectedSeed() {
    const { selectedSeed } = this.state;
    const { statistics } = this.props;

    return statistics.seeds.find(seed => seed._id === selectedSeed);
  }

  handleRowClick = seed => {
    this.setState({ selectedSeed: seed._id });
  };

  render() {
    const { statistics, request } = this.props;
    const { selectedSeed } = this.state;

    const columns = [
      {
        Header: 'Seed',
        accessor: '_id',
        minWidth: 150
      },
      {
        Header: 'Total visits',
        accessor: 'totalVisits',
        minWidth: 130
      },
      {
        Header: 'Total time',
        accessor: 'totalTime',
        Cell: row => (row.value ? msToTime(row.value) : null),
        minWidth: 130
      },
      {
        Header: 'Total requests',
        accessor: 'requests',
        Cell: row => row.value.length,
        minWidth: 130
      },
      {
        Header: 'First visit',
        accessor: 'visits',
        maxWidth: 350,
        Cell: row => (Array.isArray(row.value) && row.value[0] ? new Date(row.value[0].date).toLocaleString() : null),
        minWidth: 150
      },
      {
        Header: 'Last visit',
        accessor: 'visits',
        maxWidth: 350,
        Cell: row =>
          Array.isArray(row.value) && row.value.length > 0
            ? new Date(row.value[row.value.length - 1].date).toLocaleString()
            : null,
        minWidth: 150
      }
    ];

    return (
      <div className={visitorStatistics()}>
        <Loader loading={request}>
          <div className="mb-3">
            <Button color="primary" size="sm" onClick={this.getStatistics} outline>
              <i className="fa fa-fw fa-refresh" /> Update
            </Button>
          </div>
          {statistics && statistics.seeds.length ? (
            <ReactTable
              data={statistics.seeds}
              pageSize={statistics.seeds.length}
              loading={request}
              showPagination={false}
              columns={columns}
              defaultPageSize={10}
              getTrProps={(state, rowInfo) => ({
                className: visitorStatistics('row', { selected: rowInfo.original._id === selectedSeed }),
                onClick: () => this.handleRowClick(rowInfo.original)
              })}
              defaultSorted={[{ id: 'totalVisits', desc: true }]}
              className={`${visitorStatistics('table')} -striped -highlight`}
            />
          ) : (
            <p>No statistics for the current user</p>
          )}
          {selectedSeed ? <StatisticsData statistics={this.getSelectedSeed()} /> : null}
        </Loader>
      </div>
    );
  }
}

VisitorStatistics.defaultProps = {
  request: false
};

VisitorStatistics.propTypes = {
  userId: PropTypes.string.isRequired,
  fetchStatistics: PropTypes.func.isRequired,
  statistics: TYPE_STATISTICS, // eslint-disable-line react/require-default-props
  request: PropTypes.bool
};

const MapStateToProps = (state, ownProps) => ({
  request: statisticsSelectors.selectStatisticsRequest(state),
  statistics: statisticsSelectors.selectStatisticsByUser(ownProps.userId)(state)
});

const mapDispatchToProps = {
  fetchStatistics: statisticsActions.fetchUserStatistics
};

export default connect(MapStateToProps, mapDispatchToProps)(VisitorStatistics);
