import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import numberformat from 'number-format.js';
import b from 'b_';
import { authSelectors } from '../../modules/auth';
import { uiActions, uiSelectors } from '../../modules/ui';
import { TYPE_VISITOR } from '../../utils/propTypes';
import OpenConfiguratorLink from '../OpenConfiguratorLink';
import InstanceDetails from './InstanceDetails';

import './VisitorInstanceList.scss';

const visitorInstanceList = b.with('visitor-instance-list');

export class VisitorInstanceList extends React.PureComponent {
  renderPlot = ({ value = [] }) => {
    const plotPhase = value.find(section => section.phaseName === 'Plot');

    if (plotPhase && plotPhase.choices && plotPhase.choices.length > 0 && plotPhase.choices[0]) {
      return plotPhase.choices[0].option.name;
    }

    return '';
  };

  renderTotalPrice = ({ value = {} }) =>
    value.total ? (
      <span>
        {value.currency} {numberformat('### ###.', value.total)}
      </span>
    ) : (
      ''
    );

  handleRowClick = instance => {
    const { selectInstance, visitor } = this.props;

    selectInstance(visitor._id, instance._id);
  };

  render() {
    const {
      visitor,
      isPlotColumnVisible,
      isReservationFeeColumnVisible,
      isSendToProductionAvailable,
      selectedInstance
    } = this.props;
    const { instances } = visitor;

    const columns = [
      {
        id: 'primary',
        accessor: 'lead.primary',
        resizable: false,
        width: 30,
        Cell: row => <i className={`${visitorInstanceList('icon', { active: row.value })} fa fa-fw fa-bookmark`} />
      },
      {
        accessor: 'lead',
        resizable: false,
        width: 30,
        Cell: ({ value = {} }) => (
          <i className={`${visitorInstanceList('icon', { active: Boolean(value.firstName) })} fa fa-fw fa-envelope`} />
        )
      },
      {
        accessor: 'factoryMap',
        resizable: false,
        width: 30,
        show: isSendToProductionAvailable,
        Cell: row => (
          <i className={`${visitorInstanceList('icon', { active: Boolean(row.value) })} fa fa-fw fa-industry`} />
        )
      },
      {
        Header: 'House type',
        accessor: 'populated.seed.name',
        className: 'p-2'
      },
      {
        Header: 'Saved design',
        accessor: 'instanceName',
        className: 'p-2'
      },
      {
        Header: 'Plot',
        accessor: 'summary.sections',
        className: 'p-2',
        Cell: this.renderPlot,
        width: 100,
        resizable: false,
        show: isPlotColumnVisible
      },
      {
        Header: 'Reservation Fee',
        accessor: 'lead.reservationFee',
        className: 'p-2',
        Cell: row => row.value || 0,
        width: 120,
        resizable: false,
        show: isReservationFeeColumnVisible
      },
      {
        Header: 'Total Price',
        accessor: 'summary',
        className: 'p-2',
        width: 100,
        resizable: false,
        Cell: this.renderTotalPrice
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        className: 'p-2',
        width: 145,
        resizable: false,
        Cell: row => new Date(row.value).toLocaleString()
      },
      {
        Header: 'Updated',
        accessor: 'updatedAt',
        className: 'p-2',
        width: 145,
        resizable: false,
        Cell: row => new Date(row.value).toLocaleString()
      },
      {
        accessor: '_id',
        className: 'p-2',
        width: 150,
        Cell: row => <OpenConfiguratorLink type="id" link={row.value} caption="Open configurator" />
      }
    ];

    return (
      <div className={visitorInstanceList()}>
        <ReactTable
          data={instances}
          pageSize={instances.length}
          showPagination={false}
          resizable={false}
          columns={columns}
          defaultSorted={[
            { id: 'primary', desc: true },
            { id: 'createdAt', desc: true }
          ]}
          getTrProps={(state, rowInfo) => ({
            onClick: () => this.handleRowClick(rowInfo.original),
            className: visitorInstanceList('row', { selected: rowInfo.original._id === selectedInstance })
          })}
          className={`${visitorInstanceList('table')} -striped -highlight`}
        />
        {selectedInstance ? (
          <InstanceDetails instance={instances.find(instance => instance._id === selectedInstance)} />
        ) : null}
      </div>
    );
  }
}

VisitorInstanceList.defaultProps = {
  isPlotColumnVisible: false,
  isReservationFeeColumnVisible: false,
  isSendToProductionAvailable: false,
  selectedInstance: ''
};

VisitorInstanceList.propTypes = {
  selectInstance: PropTypes.func.isRequired,
  visitor: TYPE_VISITOR.isRequired,
  selectedInstance: PropTypes.string,
  isPlotColumnVisible: PropTypes.bool,
  isReservationFeeColumnVisible: PropTypes.bool,
  isSendToProductionAvailable: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  isPlotColumnVisible: authSelectors.selectUserRequestsShowPlot(state),
  isReservationFeeColumnVisible: authSelectors.selectUserRequestsShowReservationFee(state),
  isSendToProductionAvailable: authSelectors.selectUserRequestsShowSendToProduction(state),
  selectedInstance: uiSelectors.selectVisitorListSelectedInstance(state, ownProps.visitor._id)
});

const mapDispatchToProps = {
  selectInstance: uiActions.visitorListSelectInstance
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorInstanceList);
