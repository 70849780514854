import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { assetsActions, assetsSelectors } from '../modules/assets';
import { authSelectors } from '../modules/auth';
import { partsActions, partsSelectors } from '../modules/parts';
import { useSeed } from '../store/seed';
import api from './api';
import { RIGHTS } from './rights';

export const useSwitch = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggle = useCallback(() => {
    setIsOpen(current => !current);
  }, []);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, toggle, open, close };
};

export const useModal = useSwitch;

export const useImages = (projectId, request = true) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && request) dispatch(assetsActions.images.fetchList(projectId));
  }, [dispatch, projectId, request]);
};

export const useTextures = (projectId, request = true) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && request) dispatch(assetsActions.textures.fetchList(projectId));
  }, [dispatch, projectId, request]);
};

export const useModels = (projectId, request = true) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && request) dispatch(assetsActions.models.fetchList(projectId));
  }, [dispatch, projectId, request]);
};

export const useAssets = (projectId, request = true) => {
  const hasRights = useUserRights();
  const projectImages = useSelector(state => assetsSelectors.images.selectListByProjectId(state, projectId));
  const projectTextures = useSelector(state => assetsSelectors.textures.selectListByProjectId(state, projectId));
  const projectModels = useSelector(state => assetsSelectors.models.selectListByProjectId(state, projectId));

  useImages(projectId, request && hasRights([RIGHTS.IMAGES__READ]));
  useTextures(projectId, request && hasRights([RIGHTS.TEXTURES__READ]));
  useModels(projectId, request && hasRights([RIGHTS.MODELS__READ]));

  return { projectImages, projectTextures, projectModels };
};

export const useAssetsActions = projectId => {
  const projectImages = useSelector(state => assetsSelectors.images.selectListByProjectIdForForm(state, projectId));
  const projectTextures = useSelector(state => assetsSelectors.textures.selectListByProjectIdForForm(state, projectId));
  const dispatch = useDispatch();
  const uploadImage = useCallback(file => api.assets.images.upload(projectId)(file), [projectId]);
  const uploadTexture = useCallback(file => api.assets.textures.upload(projectId)(file), [projectId]);
  const updateImagesAfterUpload = useCallback(
    assets => dispatch(assetsActions.images.updateAssetsAfterUpload(assets)),
    [dispatch]
  );
  const updateTexturesAfterUpload = useCallback(
    assets => dispatch(assetsActions.textures.updateAssetsAfterUpload(assets)),
    [dispatch]
  );

  return {
    projectImages,
    projectTextures,
    uploadImage,
    uploadTexture,
    updateImagesAfterUpload,
    updateTexturesAfterUpload
  };
};

export const useUserRights = () => {
  const userRights = useSelector(authSelectors.selectUserRights);
  const collection = useMemo(() => new Set(userRights), [userRights]);

  return useCallback(
    (rights = []) => {
      if (rights.length === 0) {
        return true;
      }

      return [...rights].some(right => collection.has(right));
    },
    [collection]
  );
};

export const usePartList = seedId => {
  const dispatch = useDispatch();
  const parts = useSelector(state => partsSelectors.selectPopulatedPartListBySeed(state, seedId));
  const request = useSelector(partsSelectors.selectPartsRequest);

  useEffect(() => {
    if (parts.length === 0) {
      dispatch(partsActions.fetchParts(seedId));
    }
  }, [dispatch, parts.length, seedId]);

  return { parts, request };
};

export const usePart = partId => useSelector(state => partsSelectors.selectPart(state, partId));

export const useRootPartBySeedId = seedId => {
  const { seed } = useSeed(seedId);

  return usePart(seed?.rootPartId);
};

export const useParsedQueryParams = () => {
  const location = useLocation();

  return useMemo(() => qs.parse(location.search), [location.search]);
};

/* Use this hook if you want other params to persist and change only a handful */
export const useQueryParams = () => {
  const { push } = useHistory();
  const search = useParsedQueryParams();

  return useCallback(
    (params = {}) => {
      /* Store list of parameters used by this component */

      push({ search: qs.stringify({ ...search, ...params }) });
    },
    [push, search]
  );
};

export const useQueryParamsForLinkFormatter = () => {
  const search = useParsedQueryParams();

  return useCallback(params => qs.stringify({ ...search, ...params }), [search]);
};

const randomArray = length => [...new Array(length)].map(() => Math.random());

export const useKeys = initialLength => {
  const [keys, setKeys] = useState(randomArray(initialLength));

  const removeHandler = useCallback(index => {
    setKeys(current => {
      const updatedKeys = [...current];

      updatedKeys.splice(index, 1);

      return updatedKeys;
    });
  }, []);

  const addHandler = useCallback(() => {
    setKeys(current => {
      const updatedKeys = [...current];

      updatedKeys.push(Math.random());

      return updatedKeys;
    });
  }, []);
  const moveHandler = useCallback((oldIndex, newIndex) => {
    setKeys(current => {
      const updatedKeys = [...current];

      updatedKeys.splice(newIndex, 0, updatedKeys.splice(oldIndex, 1)[0]);

      return updatedKeys;
    });
  }, []);

  return { keys, removeHandler, addHandler, moveHandler };
};
