import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { ProjectType } from 'types';
import ConfiguratorLink from '../ConfiguratorLink';
import { Icon, ListPageItem } from '../Atoms';
import { getProjectUrl } from '../../utils/appRoutes';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';
import { useWindowSize } from '../../modules/ui/uiHooks';
import ProjectListItemActions from './ProjectListItemActions';
import ProjectListItemVersions from './ProjectListItemVersions';
import ProjectListItemStatistics from './ProjectListItemStatistics';

interface ProjectListItemProps {
  project: ProjectType;
}

const ProjectListItem: FC<ProjectListItemProps> = ({ project }) => {
  const hasRights = useUserRights();
  const { md, lg } = useWindowSize();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <ListPageItem ref={ref}>
      {md || lg ? null : <small className="d-block text-muted">{project.populated.partnerName}</small>}
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <Icon type="mdiFolderOutline" />
          <Link to={getProjectUrl(project._id)} title="Open project">
            <u className="ps-2 text-dark fw-bolder">{project.name}</u>
          </Link>
          {md || lg ? <small className="ps-2 text-muted">{project.populated.partnerName}</small> : null}
        </div>
        <ProjectListItemActions project={project} />
      </div>
      <div className="d-flex">
        <div className="d-block d-sm-flex">
          {hasRights([RIGHTS.PROJECTS__READ_STATISTICS]) ? (
            <ProjectListItemStatistics projectId={project._id} inView={inView} />
          ) : null}
          <ProjectListItemVersions projectId={project._id} publicSeedId={project.publicSeedId} />
        </div>
        <div className="ms-auto">
          <ConfiguratorLink type="project" link={project.link} label="Open in Configurator" />
        </div>
      </div>
    </ListPageItem>
  );
};

export default ProjectListItem;
