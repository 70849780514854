import { ComponentType, useMemo } from 'react';
import { useController } from 'react-hook-form';
import RSelect, { Theme } from 'react-select';
import RCSelect from 'react-select/creatable';
import { DefaultOptionType, FieldType, MultiSelectProps } from '../../types';
import FieldWrapper from '../FieldWrapper';
import { getConvertedRegisterOptions, getThemeForSelectComponent } from '../helpers';

const MultiSelect = <T, K = DefaultOptionType>({
  name,
  description,
  label,
  required,
  options = [],
  getOptionValue = (option?: any) => `${option?.value}`,
  getOptionLabel = (option?: any) => `${option?.label}`,
  getNewOptionData,
  disabled,
  creatable
}: MultiSelectProps<T, K>) => {
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const fieldError: any = fieldState.error;
  const fieldValue: string[] | undefined = field.value;

  const optionsDict = useMemo(() => {
    const result: Record<string, K> = {};

    options.forEach(option => {
      result[getOptionValue(option)] = option;
    });

    return result;
  }, [options, getOptionValue]);

  const value = Array.isArray(fieldValue)
    ? fieldValue.map((val: string) => optionsDict[val] || (getNewOptionData ? getNewOptionData(val) : undefined))
    : [];

  const MultiSelectComponent: ComponentType<any> = creatable ? RCSelect : RSelect;

  return (
    <FieldWrapper
      type={FieldType.MULTI_SELECT}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <MultiSelectComponent
        value={value}
        options={options}
        onChange={(values: K[]) => {
          const mapped = values.map(val => getOptionValue(val));

          field.onChange(mapped);
        }}
        onBlur={field.onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled}
        isClearable={!required}
        className={fieldState.invalid ? 'is-invalid' : ''}
        theme={(theme: Theme) => getThemeForSelectComponent(theme, fieldState.invalid)}
        getNewOptionData={getNewOptionData}
        isSearchable
        isMulti
      />
    </FieldWrapper>
  );
};

export default MultiSelect;
