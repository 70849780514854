import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { ChartContainer, ChartRow, Charts, Resizable, ScatterChart, YAxis } from 'react-timeseries-charts';
import { Button } from '../../../../Atoms';
import { msToTime } from '../../../../../utils';

const statisticsChart = b.with('statistics-chart');

class StatisticsChart extends React.PureComponent {
  state = {
    timeRange: this.props.series.range(),
    highlight: null
  };

  handleTimeRangeChange = timeRange => {
    this.setState({ timeRange });
  };

  handleMouseNear = highlight => {
    this.setState({ highlight });
  };

  resetZoom = () => {
    this.setState({ timeRange: this.props.series.range() });
  };

  render() {
    const { series } = this.props;
    const { timeRange, highlight } = this.state;

    let tooltip;
    let infoValues = [];

    if (highlight) {
      const value = msToTime(highlight.event.get(highlight.column) * 1000 * 60);

      tooltip = `Date: ${this.state.highlight.event.timestamp().toLocaleTimeString()}, Time: ${value}`;
      infoValues = [{ label: 'Time', value }];
    }

    return (
      <div className={statisticsChart()}>
        <div className="d-flex flex-row justify-content-between mb-2">
          {highlight ? <span>{tooltip}</span> : <span />}
          <Button color="primary" onClick={this.resetZoom} size="sm" outline>
            Reset zoom
          </Button>
        </div>
        <Resizable>
          <ChartContainer timeRange={timeRange} enableDragZoom onTimeRangeChanged={this.handleTimeRangeChange}>
            <ChartRow height="150">
              <YAxis id="time" label="Time (minutes)" format=".1f" hideAxisLine min={0} max={series.max('value')} />
              <Charts>
                <ScatterChart
                  axis="time"
                  series={series}
                  columns={['value']}
                  info={infoValues}
                  infoHeight={28}
                  infoWidth={110}
                  infoStyle={{
                    fill: 'black',
                    color: '#DDD'
                  }}
                  onMouseNear={this.handleMouseNear}
                  highlight={highlight}
                  radius={3}
                />
              </Charts>
            </ChartRow>
          </ChartContainer>
        </Resizable>
      </div>
    );
  }
}

StatisticsChart.propTypes = {
  series: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
};

export default StatisticsChart;
