import { FC } from 'react';
import { FieldType, FieldWrapperProps } from '../types';
import ShadowValue from '../ShadowValue';

const CLASSNAMES: Partial<Record<FieldType, string>> = {
  [FieldType.SWITCH]: 'form-check form-switch mb-3',
  [FieldType.CHECK]: 'form-check mb-3'
};

const LABEL_CLASSNAMES: Partial<Record<FieldType, string>> = {
  [FieldType.SWITCH]: 'form-check-label',
  [FieldType.CHECK]: 'form-check-label'
};

const getLabel = (name: string, label?: string, required?: boolean) => {
  if (label !== undefined) {
    return `${label}${required ? ' <sup>*</sup>' : ''}`;
  }

  return `${label || name}${required ? ' <sup>*</sup>' : ''}`;
};

/* eslint-disable react/no-danger */
const FieldWrapper: FC<FieldWrapperProps> = ({
  name,
  type,
  label = '',
  description = '',
  required,
  error,
  isInputTag,
  isCheckbox,
  children,
  useShadowValue
}) => {
  return (
    <div className={CLASSNAMES[type] || 'mb-3'}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={name}
        className={`${LABEL_CLASSNAMES[type] || 'form-label'} text-uppercase fw-bold me-2`}
        dangerouslySetInnerHTML={{ __html: getLabel(name, label, required) }}
      />

      {description && !isCheckbox ? <small className="form-text d-inline-block text-muted">{description}</small> : null}

      {isInputTag && !isCheckbox ? <div className="input-group">{children}</div> : children}

      {description && isCheckbox ? <small className="form-text d-block text-muted">{description}</small> : null}

      {error ? <small className="form-text text-danger">{error}</small> : null}

      {useShadowValue && <ShadowValue name={name} />}
    </div>
  );
};

export default FieldWrapper;
