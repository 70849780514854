import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { partsActions, partsSelectors } from '../../modules/parts';
import { useModal } from '../../utils/hooks';
import { useSeed } from '../../store/seed';

const usePartEditor = () => {
  const { seedId } = useParams();
  const { seed } = useSeed(seedId);
  const parts = useSelector(partsSelectors.selectParts);

  const { open, close, isOpen } = useModal(false);
  const dispatch = useDispatch();

  const onCreatePart = useCallback(
    value => {
      dispatch(partsActions.createPart({ ...value, seed: seedId }));
    },
    [dispatch, seedId]
  );

  const onDeletePart = useCallback(
    part => {
      dispatch(partsActions.deletePart(part));
    },
    [dispatch]
  );

  const handleDelete = useCallback(
    id => {
      const part = parts[id];

      if (part) {
        // eslint-disable-next-line no-alert
        const response = window.confirm(
          `Are you sure that you want to delete part "${part.ReferenceName} (${part.Option})"?`
        );

        if (response) {
          onDeletePart(part);
        }
      }
    },
    [onDeletePart, parts]
  );

  return {
    seedId,
    projectId: seed?.projectId,
    onOpenPartModal: open,
    isPartModalOpen: isOpen,
    onClosePartModal: close,
    onCreatePart,
    handleDelete
  };
};

export default usePartEditor;
