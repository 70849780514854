import React, { useState, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { FieldType, MarkdownProps } from '../../types';
import FieldWrapper from '../FieldWrapper';
import Suspense from '../../../Suspense/Suspense';
import 'react-mde/lib/styles/css/react-mde.css';
import 'react-mde/lib/styles/css/react-mde-editor.css';
import 'react-mde/lib/styles/css/react-mde-toolbar.css';

const ReactMde = React.lazy(() => import('react-mde'));

const uponSanitizeElement = (node: Element, data: any) => {
  if (data.tagName === 'iframe') {
    const src = node.getAttribute('src') || '';

    if (
      !src.startsWith('https://www.youtube.com/embed/') &&
      !src.startsWith('https://player.vimeo.com/') &&
      node.parentNode
    ) {
      node.parentNode.removeChild(node);
    }
  }
};

const afterSanitizeAttributes = (node: Element) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer noopener');
  }
};

type ParseFn = (src: string, options?: any) => string;

const md2html = async (str: string) =>
  Promise.all([import('marked'), import('dompurify')]).then(([marked, DOMPurify]) => {
    DOMPurify.addHook('uponSanitizeElement', uponSanitizeElement);
    DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributes);

    return Promise.resolve(
      DOMPurify.sanitize((marked.default as unknown as ParseFn)(str), {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target']
      })
    );
  });

const Markdown = <T,>({ name, label, description, required, classes }: MarkdownProps<T>) => {
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');
  const { field } = useController<T>({ name });
  const handleChange = useCallback(
    value => {
      field.onChange(value);
    },
    [field]
  );

  return (
    <FieldWrapper type={FieldType.TEXTAREA} name={name} label={label} description={description} required={required}>
      <Suspense>
        <ReactMde
          value={field.value}
          onChange={handleChange}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          classes={classes}
          generateMarkdownPreview={md2html}
        />
      </Suspense>
    </FieldWrapper>
  );
};

export default Markdown;
