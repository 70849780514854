import { reducer } from '../@helpers';
import partsActions from './partsActions';

const initialState = {
  ...reducer.baseInitialState,
  unsavedPositions: {},
  unsavedList: []
};

function moduleReducer(state = initialState, { type, payload = {} }) {
  switch (type) {
    case partsActions.updateParts.request.ACTION_TYPE:
    case partsActions.saveUnsavedParts.request.ACTION_TYPE:
      return { ...state, updatingParts: true };
    case partsActions.updateParts.success.ACTION_TYPE:
    case partsActions.updateParts.failure.ACTION_TYPE:
    case partsActions.saveUnsavedParts.failure.ACTION_TYPE:
      return { ...state, updatingParts: false };
    case partsActions.updatePartPosition.ACTION_TYPE: {
      return { ...state, unsavedPositions: { ...state.unsavedPositions, [payload._id]: { ...payload } } };
    }
    case partsActions.reorderParts.ACTION_TYPE: {
      const positions = payload.nodes.reduce((result, node) => {
        // eslint-disable-next-line no-param-reassign
        result[node.id] = { _id: node.id, graphX: node.x, graphY: node.y - node.height / 2 };

        return result;
      }, {});

      return { ...state, unsavedPositions: { ...state.unsavedPositions, ...positions } };
    }
    case partsActions.saveUnsavedParts.success.ACTION_TYPE: {
      const { parts = [] } = payload;

      const list = parts.reduce((result, part) => {
        // eslint-disable-next-line no-param-reassign
        result[part._id] = part;

        return result;
      }, {});

      const unsavedPositions = Object.values(state.unsavedPositions).reduce((result, current) => {
        if (!list[current._id]) {
          // eslint-disable-next-line no-param-reassign
          result[current._id] = current;
        }

        return result;
      }, {});

      return {
        ...state,
        updatingParts: false,
        list: { ...state.list, ...list },
        unsavedPositions: { ...unsavedPositions }
      };
    }
    case partsActions.resetUnsavedPartsInfo.ACTION_TYPE: {
      return { ...state, unsavedPositions: {} };
    }
    case partsActions.updatePart.success.ACTION_TYPE: {
      /* Remove part from unsaved list and update real list */
      const part = payload;
      const unsavedPositions = { ...state.unsavedPositions };
      const list = { ...state.list };

      delete unsavedPositions[part._id];

      list[part._id] = part;

      return { ...state, unsavedPositions, list, request: false, error: '', updated: true, updatingParts: false };
    }
    case partsActions.deletePart.success.ACTION_TYPE: {
      const { id } = payload;
      const list = { ...state.list };
      const unsavedPositions = { ...state.unsavedPositions };

      delete list[id];
      delete unsavedPositions[id];

      return { ...state, list, unsavedPositions, request: false, error: '', deleted: true };
    }
    default:
      return state;
  }
}

export default reducer.createBaseModuleReducer(partsActions, moduleReducer, initialState);
