import { useCallback, useMemo, useState } from 'react';
import { get, set } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { ProjectType } from 'types';
import { useSelector } from 'react-redux';
import { FormField, Col, Row } from '../../Atoms';
import { partsSelectors } from '../../../modules/parts';
import { usePartList } from '../../../utils/hooks';
import { seedsSelectors } from '../../../modules/seeds';
import { TAB_NAMES } from '../../../utils/defaultTabNames';

/* eslint-disable */
const ACTION_TYPES = {
  OPEN_TAB: 'open-tab',
  OPEN_DIALOG: 'open-dialog',
  OPEN_LINK: 'open-link',
  OPEN_FIRST_CONTROL_TAB: 'open-first-control-tab',
  OPEN_LAST_CONTROL_TAB: 'open-last-control-tab'
};

const DIALOG_TYPES = {
  AUTH: 'auth',
  LOGOUT: 'logout',
  PROFILE: 'profile',
  SAVE: 'save',
  SHARE: 'share',
  CONTACT: 'contact',
  PDF: 'pdf',
  OPEN_INSTANCE: 'open-instance',
  EXPORT_MODEL: 'export-model'
};

const REQUIRE_LOGIN_DIALOGS = {
  [DIALOG_TYPES.SAVE]: true,
  [DIALOG_TYPES.SHARE]: true,
  [DIALOG_TYPES.CONTACT]: true,
  [DIALOG_TYPES.PDF]: true
};

const ACTIONS = [
  { label: 'Open tab', value: ACTION_TYPES.OPEN_TAB },
  { label: 'Open dialog', value: ACTION_TYPES.OPEN_DIALOG },
  { label: 'Open link', value: ACTION_TYPES.OPEN_LINK },
  { label: 'Open first control tab', value: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB },
  { label: 'Open last control tab', value: ACTION_TYPES.OPEN_LAST_CONTROL_TAB }
];

const COLORS = [
  { label: 'Main Fill', value: 'main' },
  { label: 'Main Outline', value: 'main-outline' },
  { label: 'Accent Fill', value: 'accent' },
  { label: 'Accent Outline', value: 'accent-outline' },
  { label: 'Secondary Fill', value: 'secondary' },
  { label: 'Secondary Outline', value: 'secondary-hovered' },
  { label: 'Text Outline', value: 'text' },
  { label: 'Text Flat', value: 'text-borderless' }
];

const ICONS = [
  { label: 'Save', value: 'save' },
  { label: 'Web share', value: 'web-share' },
  { label: 'Cube', value: 'cube' },
  { label: 'Cogs', value: 'cogs' },
  { label: 'Chevron left', value: 'chevron-left' }
];

const DIALOGS = [
  { label: 'Auth', value: DIALOG_TYPES.AUTH },
  { label: 'Logout', value: DIALOG_TYPES.LOGOUT },
  { label: 'My Designs', value: DIALOG_TYPES.PROFILE },
  { label: 'Save', value: DIALOG_TYPES.SAVE },
  { label: 'Share', value: DIALOG_TYPES.SHARE },
  { label: 'Contact', value: DIALOG_TYPES.CONTACT },
  { label: 'PDF', value: DIALOG_TYPES.PDF },
  { label: 'Open instance', value: DIALOG_TYPES.OPEN_INSTANCE },
  { label: 'Export model', value: DIALOG_TYPES.EXPORT_MODEL }
];

const AUTHORIZATION_VISIBILITY_LIST = [
  { label: 'Hide for authorized users', value: 'authorized' },
  { label: 'Hide for unauthorized users', value: 'unauthorized' }
];

const SAVING_VISIBILITY_LIST = [
  { label: 'Hide for unsaved instances', value: 'unsaved' },
  { label: 'Hide for saved instances', value: 'saved' }
];

const REQUEST_VISIBILITY_LIST = [
  { label: 'Hide for instances with request', value: 'withRequest' },
  { label: 'Hide fot instances without request', value: 'withoutRequest' }
];

const CONFIGURATOR_MODE_VISIBILITY_LIST = [
  { label: 'Hide for project or seed mode', value: 'noInstance' },
  { label: 'Hide fot instance mode', value: 'instance' }
];

const getDefaultConfiguration = () => ({
  list: [
    {
      name: 'login',
      displayName: 'Log in',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.AUTH,
      hidingByAuth: 'authorized'
    },
    {
      name: 'logout',
      displayName: 'Log out',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.LOGOUT,
      hidingByAuth: 'unauthorized'
    },
    {
      name: 'my-designs',
      displayName: 'My Designs',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.PROFILE,
      hidingByAuth: 'unauthorized'
    },
    {
      name: 'save',
      displayName: 'Save',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.SAVE,
      hidingBySaving: 'saved'
    },
    {
      name: 'share',
      displayName: 'Share',
      description: 'Unique link to your design',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.SHARE
    },
    {
      name: 'contact-us',
      displayName: 'Contact us',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.CONTACT,
      hidingByRequest: 'withRequest'
    },
    {
      name: 'contact-us-again',
      displayName: 'Contact us again',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.CONTACT,
      hidingByRequest: 'withoutRequest'
    },
    {
      name: 'download-pdf',
      displayName: 'Download summary',
      description: 'File with complete information about your design',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.PDF,
      requireLogin: true
    },
    {
      name: 'design-your-home',
      displayName: 'Design Your Home',
      type: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB,
      hidingByConfiguratorMode: 'instance'
    },
    {
      name: 'review-design',
      displayName: 'Review the design',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review',
      hidingByConfiguratorMode: 'noInstance'
    },
    {
      name: 'edit',
      displayName: 'Edit',
      type: ACTION_TYPES.OPEN_FIRST_CONTROL_TAB,
      hidingByConfiguratorMode: 'noInstance'
    },
    {
      name: 'back',
      displayName: 'Back',
      type: ACTION_TYPES.OPEN_LAST_CONTROL_TAB,
      hidingByConfiguratorMode: 'instance'
    },
    {
      name: 'review',
      displayName: 'Review',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review'
    },
    {
      name: 'about-this-house',
      displayName: 'About this house',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'index'
    },
    {
      name: 'summary',
      displayName: 'Summary',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'summary'
    },
    {
      name: 'open-instance',
      displayName: 'Open...',
      type: ACTION_TYPES.OPEN_DIALOG,
      dialog: DIALOG_TYPES.OPEN_INSTANCE
    },
    {
      name: 'finish',
      displayName: 'Finish',
      type: ACTION_TYPES.OPEN_TAB,
      tab: 'review'
    }
  ],
  groups: {
    aboutTab: [
      { name: 'design-your-home', color: 'accent' },
      { name: 'review-design', color: 'accent' },
      { name: 'login', color: 'text-borderless' },
      { name: 'my-designs', color: 'text-borderless' }
    ],
    reviewTab: [
      {
        name: 'edit',
        color: 'main-outline'
      },
      {
        name: 'back',
        color: 'main-outline'
      },
      { name: 'save', color: 'main' },
      { name: 'summary', color: 'main' }
    ],
    summaryTab: [
      { name: 'contact-us', color: 'accent' },
      { name: 'contact-us-again', color: 'accent' },
      { name: 'download-pdf', color: 'accent-outline' }
    ],
    menu: [
      {
        section: 'actions',
        actions: [
          { name: 'my-designs' },
          { name: 'save' },
          { name: 'share' },
          { name: 'download-pdf' },
          { name: 'contact-us' },
          { name: 'contact-us-again' },
          { name: 'open-instance' }
        ]
      },
      { section: 'links', actions: [{ name: 'about-this-house' }] },
      { section: 'auth', actions: [{ name: 'login' }, { name: 'logout' }] }
    ],
    overviewPanel: [
      { name: 'contact-us', color: 'accent' },
      { name: 'contact-us-again', color: 'accent' },
      { name: 'summary', color: 'text-borderless' }
    ],
    summaryPanel: [
      { name: 'back', color: 'main-outline', icon: 'chevron-left' },
      { name: 'edit', color: 'main-outline', icon: 'cogs' },
      { name: 'review', color: 'main-outline', icon: 'cube' },
      { name: 'save', color: 'main', icon: 'save' },
      { name: 'share', color: 'main-outline', icon: 'web-share' }
    ],
    shareDialog: [{ name: 'contact-us', color: 'accent' }],
    summarySaveButton: 'save',
    finishButton: 'finish'
  }
});
/* eslint-enable */

interface Action {
  type: string;
  name: string;
  tab: string;
}

interface MenuItem {
  name: string;
  color: string;
  icon?: string;
}

interface MenuGroup {
  section: string;
  actions: MenuItem[];
}

interface ActionSettings {
  name: string;
  displayName: string;
  description: string;
  type: string;
  dialog: string;
  requireLogin: boolean;
  link: string;
  tab: string;
  hidingByAuth: string;
  hidingBySaving: string;
  hidingByRequest: string;
  hidingByConfiguratorMode: string;
  groups: {
    aboutTab: MenuItem[];
    reviewTab: MenuItem[];
    summaryTab: MenuItem[];
    menu: MenuGroup;
    overviewPanel: MenuItem[];
    summaryPanel: MenuItem[];
    shareDialog: MenuItem[];
    summarySaveButton: string;
    finishButton: string;
    homeButton: string;
  };
}

const getLabelPostfix = (flag: boolean) => (flag ? '(Currently hidden)' : '');

export const Actions = ({ seedId }: { seedId: string }) => {
  const { setValue } = useFormContext<ProjectType>();

  const tabs = useSelector(state => partsSelectors.selectTabNamesForSuggestions(state, seedId));
  const settings = useSelector(state => seedsSelectors.selectSeedSettingsMemoized(state, seedId));
  const tabsSuggestions = useMemo(() => {
    return [
      ...tabs,
      { label: 'About', value: TAB_NAMES.ABOUT },
      { label: 'Review', value: TAB_NAMES.REVIEW },
      { label: 'Summary', value: TAB_NAMES.SUMMARY }
    ];
  }, [tabs]);

  const handleClick = useCallback(() => {
    const response = window.confirm(`Are you sure that you want to reset settings to default?`); // eslint-disable-line no-alert
    const options = { shouldValidate: true, shouldDirty: true, shouldTouch: true };

    const defaults = getDefaultConfiguration();

    if (response) {
      // hook form only updates its state when updating one level of arrays
      setValue('settings.actions.list', defaults.list, options);
      setValue('settings.actions.groups.aboutTab', defaults.groups.aboutTab, options);
      setValue('settings.actions.groups.reviewTab', defaults.groups.reviewTab, options);
      setValue('settings.actions.groups.summaryTab', defaults.groups.summaryTab, options);
      setValue('settings.actions.groups.menu', defaults.groups.menu, options);
      setValue('settings.actions.groups.overviewPanel', defaults.groups.overviewPanel, options);
      setValue('settings.actions.groups.summaryPanel', defaults.groups.summaryPanel, options);
      setValue('settings.actions.groups.shareDialog', defaults.groups.shareDialog, options);
    }
  }, [setValue]);

  usePartList(seedId);

  const localSettings = useMemo(() => {
    const { seedSettings, projectSettings } = settings;
    const tabsMap: Record<string, any> = {};

    [
      ['aboutTabHidden', TAB_NAMES.ABOUT],
      ['reviewTabHidden', TAB_NAMES.REVIEW],
      ['summaryTabHidden', TAB_NAMES.SUMMARY]
    ].forEach(([flag, tab]) => {
      tabsMap[tab] = [true, false].includes(seedSettings.interface?.[flag])
        ? seedSettings.interface?.[flag]
        : projectSettings.interface?.[flag];
    });

    const actions: Record<string, any> = {};

    (projectSettings.actions?.list || seedSettings.actions?.list || [])
      .filter((action: Action) => action.type === ACTION_TYPES.OPEN_TAB)
      .forEach((action: Action) => {
        actions[action.name] = tabsMap[action.tab];
      });

    return { tabs: tabsMap, actions };
  }, [settings]);

  const headingFormat = useCallback(
    value => `${value.name} ${getLabelPostfix(localSettings.actions[value.name])}`,
    [localSettings.actions]
  );

  const [state, setState] = useState<{ type: { label: string; value: string }[] }>({ type: [] });

  const handleTypeChange = (value?: { label: string; value: string } | null, index?: number) => {
    set(state, `type[${index}]`, value);
    setState(state);
  };

  const TypeDependentFields = (props: { pageState: { type: { label: string; value: string }[] }; index?: number }) => {
    const typeValue = get(props.pageState, `type[${props.index}]`);
    const [showToggle, setShowToggle] = useState<boolean>(false);

    const handleDialogChange = (value: any) => {
      setShowToggle(!!REQUIRE_LOGIN_DIALOGS[value?.value]);
    };

    return (
      <>
        {typeValue?.value === ACTION_TYPES.OPEN_DIALOG && (
          <>
            <FormField.Select<ActionSettings>
              onChange={handleDialogChange}
              name="dialog"
              label="Dialog"
              options={DIALOGS}
              required
            />
            {showToggle && <FormField.Toggle<ActionSettings> name="requireLogin" label="Require login" />}
          </>
        )}
        {typeValue?.value === ACTION_TYPES.OPEN_LINK && (
          <FormField.Text<ActionSettings> name="link" label="Link" required />
        )}
        {typeValue?.value === ACTION_TYPES.OPEN_TAB && (
          <FormField.Select<ActionSettings> name="tab" label="Tab" options={tabsSuggestions} required />
        )}
      </>
    );
  };

  return (
    <>
      <Row>
        <Col xs="12" md="6">
          <div className="border border-danger p-5 mb-3 d-flex flex-column">
            <button onClick={handleClick} type="button" className="btn btn-danger">
              Reset to default
            </button>
            <span className="mt-2 text-center text-danger">
              This will restore all default values for all actions, use carefully
            </span>
          </div>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.list"
            label="All available actions"
            description="Define actions that will be used in the configuration"
            headerFormat={value => get(value, 'name', 'untitled')}
          >
            <FormField.Text<ActionSettings>
              name="name"
              label="Action name"
              description="Used to identify the action. Must be unique"
              required
            />
            <FormField.Text<ActionSettings> name="displayName" label="Display name" required />
            <FormField.Text<ActionSettings> name="description" label="Description" description="Used in the menu" />
            <FormField.Select<ActionSettings>
              name="type"
              label="Action type"
              description="Choose action type from the list"
              options={ACTIONS}
              required
              onChange={handleTypeChange}
            />
            <TypeDependentFields pageState={state} />
            <FormField.Select<ActionSettings>
              name="hidingByAuth"
              label="Visibility based on authorization"
              options={AUTHORIZATION_VISIBILITY_LIST}
            />
            <FormField.Select<ActionSettings>
              name="hidingBySaving"
              label="Visibility based on saving"
              options={SAVING_VISIBILITY_LIST}
            />
            <FormField.Select<ActionSettings>
              name="hidingByRequest"
              label="Visibility based on request"
              options={REQUEST_VISIBILITY_LIST}
            />
            <FormField.Select<ActionSettings>
              name="hidingByConfiguratorMode"
              label="Visibility based on configurator mode"
              options={CONFIGURATOR_MODE_VISIBILITY_LIST}
            />
          </FormField.ArrayWrapper>
        </Col>
        <Col xs="12" md="6">
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.aboutTab"
            label={`About tab actions ${getLabelPostfix(localSettings.tabs[TAB_NAMES.ABOUT])}`}
            headerFormat={headingFormat}
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.reviewTab"
            label={`Review tab actions ${getLabelPostfix(localSettings.tabs[TAB_NAMES.REVIEW])}`}
            headerFormat={headingFormat}
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.summaryTab"
            label={`Summary tab actions ${getLabelPostfix(localSettings.tabs[TAB_NAMES.SUMMARY])}`}
            headerFormat={headingFormat}
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              getOptionValue={(option?: any) => `${option?.name}`}
              getOptionLabel={(option?: any) => `${option?.name}`}
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>

          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.menu"
            label="Menu actions"
            headerFormat={value => `${get(value, 'section', '')}`}
          >
            <FormField.Text<MenuGroup> name="section" label="Section name" required />
            <FormField.ArrayWrapper<MenuGroup> name="actions" label="Actions" headerFormat={headingFormat}>
              <FormField.ControlledSelect<MenuItem, ProjectType>
                sourceField="settings.actions.list"
                name="name"
                label="Action name"
                getOptionValue={(option?: any) => `${option?.name}`}
                getOptionLabel={(option?: any) => `${option?.name}`}
                description="Select an action from the list"
                required
              />
            </FormField.ArrayWrapper>
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.overviewPanel"
            label="Overview panel actions"
            headerFormat={headingFormat}
          >
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.summaryPanel"
            label="Summary panel actions"
            headerFormat={headingFormat}
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="icon" label="Icon" options={ICONS} required />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
          <FormField.ArrayWrapper<ProjectType>
            name="settings.actions.groups.shareDialog"
            label="Share dialog actions"
            headerFormat={headingFormat}
          >
            <FormField.ControlledSelect<MenuItem, ProjectType>
              sourceField="settings.actions.list"
              name="name"
              label="Action name"
              description="Select an action from the list"
              required
            />
            <FormField.Select<MenuItem> name="color" label="Color" options={COLORS} />
          </FormField.ArrayWrapper>
        </Col>
      </Row>
      <FormField.ControlledSelect<ProjectType, ProjectType>
        sourceField="settings.actions.list"
        getOptionValue={(option?: any) => `${option?.name}`}
        getOptionLabel={(option?: any) => `${option?.name}`}
        name="settings.actions.groups.summarySaveButton"
        label="Summary save button action"
        description="Select an action from the list"
      />
      <FormField.ControlledSelect<ProjectType, ProjectType>
        sourceField="settings.actions.list"
        getOptionValue={(option?: any) => `${option?.name}`}
        getOptionLabel={(option?: any) => `${option?.name}`}
        name="settings.actions.groups.finishButton"
        label="Next button action in the last step"
        description="Select an action from the list"
      />
      <FormField.ControlledSelect<ProjectType, ProjectType>
        sourceField="settings.actions.list"
        getOptionValue={(option?: any) => `${option?.name}`}
        getOptionLabel={(option?: any) => `${option?.name}`}
        name="settings.actions.groups.homeButton"
        label="Home button action"
        description="Select an action from the list"
      />
    </>
  );
};
