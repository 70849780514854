import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as iframeScriptsSelectors from './iframeScriptsSelectors';
import iframeScriptsActions from './iframeScriptsActions';

export const useFactoryMapsList = () => {
  const dispatch = useDispatch();
  const request = useSelector(iframeScriptsSelectors.selectIframeScriptsRequest);
  const list = useSelector(iframeScriptsSelectors.selectIframeScriptList);

  useEffect(() => {
    dispatch(iframeScriptsActions.fetchIframeScripts());
  }, [dispatch]);

  return { request, list };
};

export const useIframeScript = id => {
  const list = useSelector(iframeScriptsSelectors.selectIframeScripts);

  return list[id];
};

export const useFetchIframeScript = id => {
  const dispatch = useDispatch();
  const iframeScript = useIframeScript(id);
  const request = useSelector(iframeScriptsSelectors.selectIframeScriptsRequest);

  useEffect(() => {
    if (!iframeScript) {
      dispatch(iframeScriptsActions.fetchIframeScripts());
    }
  }, [dispatch, iframeScript]);

  return { iframeScript, request };
};

export const useIframeScriptsFlags = () => {
  const dispatch = useDispatch();
  const created = useSelector(iframeScriptsSelectors.selectIframeScriptsCreatedFlag);
  const updated = useSelector(iframeScriptsSelectors.selectIframeScriptsUpdatedFlag);
  const deleted = useSelector(iframeScriptsSelectors.selectIframeScriptsDeletedFlag);

  const reset = useCallback(() => {
    dispatch(iframeScriptsActions.resetFlags());
  }, [dispatch]);

  return { created, updated, deleted, reset };
};
