import { useContext, useMemo, useState, useCallback } from 'react';
import _mergeWith from 'lodash/mergeWith';
import _get from 'lodash/get';
import { useController } from 'react-hook-form';
import { FormText, UncontrolledPopover, PopoverBody } from '../..';
import { FormContext } from '../FormContext';
import { FieldName } from '../types';
import Icon from '../../Icon/Icon';

const MASTER_LEVEL = 'master';
const CURRENT_LEVEL = 'current';
const SEED_LEVEL = 'public seed';

interface ShadowValueType {
  name?: string;
  value?: any;
}

interface ShadowSimpleProps {
  name: string;
  shadowValues: ShadowValueType[];
}

interface ShadowValueProps {
  name: string;
}

const useClearValues = (list: ShadowValueType[], fieldName: string) => {
  return useMemo(
    () =>
      list.map(level => {
        return level.name === CURRENT_LEVEL ? level : { name: level.name, value: _get(level.value, fieldName) };
      }),
    [list, fieldName]
  );
};

const useField = <T,>(name: FieldName<T>) => {
  const { field } = useController<T>({ name });

  const setUndefined = useCallback(() => {
    field.onChange(undefined);
  }, [field]);

  return { field, setUndefined };
};

const parseValue = (value?: string) => {
  if (value === undefined) {
    return typeof value;
  }

  if (value === '') {
    return 'empty string';
  }

  if (typeof value === 'boolean') {
    return Boolean(value).toString();
  }

  return value;
};

function customizer(_: any, sourceValue: any[]) {
  return Array.isArray(sourceValue) ? sourceValue : undefined;
}

const mergeValues = (values: any[]): ShadowValueType => {
  return _mergeWith({}, ...values.map(level => ({ value: level.value })), customizer);
};

const ShadowValue = <T,>({ name }: ShadowValueProps) => {
  const { shadowValues } = useContext(FormContext);

  if (!shadowValues) return null;

  return <ShadowSimpleValue<T> shadowValues={shadowValues} name={name} />;
};

const RemoveValueButton = ({ value, onClick }: any) =>
  value !== undefined ? (
    <div className="d-inline" role="button" onKeyDown={e => e.preventDefault()} tabIndex={-1} onClick={onClick}>
      <Icon type="mdiTrashCanOutline" mix="text-primary" />
    </div>
  ) : null;

const ShadowSimpleValue = <T,>({ name, shadowValues }: ShadowSimpleProps) => {
  const [result, setResult] = useState<ShadowValueType>({});

  const clearValues = useClearValues(shadowValues, name);
  const { field: value, setUndefined } = useField<T>(name as FieldName<T>);

  const values: ShadowValueType[] = clearValues.map(level =>
    level.name === CURRENT_LEVEL ? { name: CURRENT_LEVEL, value: value.value } : level
  );
  const merged = mergeValues(values);
  const source: ShadowValueType | undefined = values.reverse().find(level => {
    return _get(level.value, 'name') === _get(merged.value, 'name');
  });

  const res = { name: source?.name, value: parseValue(_get(source, 'value')) };

  if (res.value !== result.value || res.name !== result.name) {
    setResult(res);
  }

  let iconComponent = <></>;

  if (result.name === CURRENT_LEVEL) {
    iconComponent = <Icon mix="text-primary" type="mdiFileCheckOutline" />;
  }

  if (result.name === SEED_LEVEL && result.value !== 'undefined') {
    iconComponent = <Icon mix="text-warning" type="mdiFileReplaceOutline" />;
  }

  if (result.name === MASTER_LEVEL && result.value !== 'undefined') {
    iconComponent = <Icon mix="text-muted" type="mdiFileUndoOutline" />;
  }

  const id = useMemo(() => `${name.replace(/\W/g, '_')}_`, [name]);

  return (
    <FormText className="d-inline">
      <span id={id}>{iconComponent}</span>
      <RemoveValueButton onClick={setUndefined} title="Remove value" value={value} />
      <UncontrolledPopover placement="bottom" trigger="hover" target={id}>
        <PopoverBody>
          {clearValues.map(level =>
            level.name === CURRENT_LEVEL ? (
              <span className="d-block text-muted" key={level.name}>
                {CURRENT_LEVEL}: <strong>{parseValue(value.value)}</strong>
              </span>
            ) : (
              <span className="d-block text-muted" key={level.name}>
                {level.name}: <strong>{parseValue(level.value)}</strong>
              </span>
            )
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </FormText>
  );
};

export default ShadowValue;
