import { useController, useFormContext } from 'react-hook-form';
import React, { useCallback } from 'react';
import { getConvertedRegisterOptions } from '../helpers';
import { CodeProps, FieldType } from '../../types';
import FieldWrapper from '../FieldWrapper';
import CodeEditor, { CodeEditorErrorsType } from '../../../CodeEditor/CodeEditor';

const Code = <T,>({ name, label, description, required, readonly, placeholder, mode }: CodeProps<T>) => {
  const {
    setError,
    clearErrors,
    trigger,
    formState: { isValid }
  } = useFormContext<T>();
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });
  const fieldError: any = fieldState.error;

  const handleValidate = useCallback(
    (annotations: CodeEditorErrorsType) => {
      const firstError = annotations.find(annotation => annotation.type === 'error');

      if (firstError) {
        setError(name, {
          type: 'manual',
          message: firstError.text
        });

        return;
      }

      if (isValid) return;

      clearErrors(name);
      trigger(name);
    },
    [name, isValid, clearErrors, setError, trigger]
  );

  return (
    <FieldWrapper
      type={FieldType.CODE}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <div className={fieldError ? 'is-invalid border border-danger' : ''}>
        <CodeEditor
          mode={mode}
          name={name}
          readonly={readonly}
          placeholder={placeholder}
          value={field.value as string}
          onChange={field.onChange}
          onValidate={handleValidate}
        />
      </div>
    </FieldWrapper>
  );
};

export default Code;
