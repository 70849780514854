import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '../../../Atoms';

const CanvasControls = ({ className, onZoomExtents, onSubmit, isPristine, onReset, onReorder, graphActions }) => {
  return (
    <ButtonGroup className={className}>
      {onSubmit ? (
        <Button title="Save" color="primary" disabled={isPristine} onClick={onSubmit}>
          <i className="fa fa-fw fa-save" />
          Save
        </Button>
      ) : null}
      {onReset ? (
        <Button title="Reset" color="secondary" disabled={isPristine} onClick={onReset}>
          <i className="fa fa-undo fa-fw" />
        </Button>
      ) : null}
      <Button onClick={onReorder} title="Reorder items algorithmically">
        <span className="fa fa-fw fa-random" /> Reorder
      </Button>
      <Button title="Zoom extents" color="secondary" outline onClick={onZoomExtents}>
        <i className="fa fa-fw fa-search" />
      </Button>
      {graphActions.map(({ name, onClick, label }) => (
        <Button color="light" onClick={onClick} key={name}>
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

CanvasControls.propTypes = {
  className: PropTypes.string.isRequired,
  onZoomExtents: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  isPristine: PropTypes.bool.isRequired,
  graphActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

CanvasControls.defaultProps = {
  onSubmit: undefined,
  onReset: undefined
};

export default CanvasControls;
