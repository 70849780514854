import { all, call, takeLatest, put } from 'redux-saga/effects';
import api from '../../utils/api';
import { sagas } from '../@helpers';
import { uiActions } from '../ui';
import assetsActions from './assetsActions';
import ASSET_TYPES from './assetTypes';

const makeAssetSaga = assetType => {
  const moduleActions = assetsActions[assetType];

  function* doFetchAssetsSaga({ payload }) {
    yield call(sagas.doBaseAsyncSaga, moduleActions.fetchList, api.assets[assetType].getList, payload.projectId);
  }

  function* watchFetchAssetsSaga() {
    yield takeLatest(moduleActions.FETCH_LIST, doFetchAssetsSaga);
  }

  function* doDeleteAssetSaga({ payload }) {
    const { data } = yield call(
      sagas.doBaseAsyncSaga,
      moduleActions.delete,
      api.assets[assetType].delete,
      payload.id,
      payload.unsafe
    );

    if (data) {
      yield put(uiActions.showSuccessNotification(`Asset ${payload.unsafe ? 'deleted' : 'removed'} successfully`));
    }
  }

  function* watchDeleteAssetSaga() {
    yield takeLatest(moduleActions.DELETE, doDeleteAssetSaga);
  }

  function* modelsSaga() {
    yield all([watchFetchAssetsSaga(), watchDeleteAssetSaga()]);
  }

  if (assetType === ASSET_TYPES.MODELS) {
    return modelsSaga;
  }

  /*
    Analyise image to find out which parts, seeds and projects it is used in
  */

  function* doGetAssetParentSaga({ payload }) {
    yield call(sagas.doBaseAsyncSaga, moduleActions.getParent, api.assets[assetType].getParent, payload.fileName);
  }

  function* watchGetAssetParentSaga() {
    yield takeLatest(moduleActions.GET_PARENT, doGetAssetParentSaga);
  }

  /*
  Set image's project
  */

  function* doSetProjectSaga({ payload }) {
    const { data } = yield call(
      sagas.doBaseAsyncSaga,
      moduleActions.setProject,
      api.assets[assetType].setProject,
      payload.assetId,
      payload.projectId
    );

    if (data) {
      yield put(uiActions.showSuccessNotification('Asset project changed successfully'));
    }
  }

  function* watchSetProjectSaga() {
    yield takeLatest(moduleActions.SET_PROJECT, doSetProjectSaga);
  }

  /*
    Copy asset to another project - will reupload on api side with changes to metadata.
  */
  function* doCopyAssetToProjectSaga({ payload }) {
    const { data } = yield call(
      sagas.doBaseAsyncSaga,
      moduleActions.copyToProject,
      api.assets[assetType].copyToProject,
      payload.assetId,
      payload.projectId
    );

    if (data) {
      yield put(uiActions.showSuccessNotification('Asset copied to another project successfully'));
    }
  }

  function* watchCopyAssetToProjectSaga() {
    yield takeLatest(moduleActions.COPY_TO_PROJECT, doCopyAssetToProjectSaga);
  }

  function* moduleSaga() {
    yield all([
      watchFetchAssetsSaga(),
      watchSetProjectSaga(),
      watchGetAssetParentSaga(),
      watchDeleteAssetSaga(),
      watchCopyAssetToProjectSaga()
    ]);
  }

  return moduleSaga;
};

const assetsSaga = {
  images: makeAssetSaga(ASSET_TYPES.IMAGES),
  textures: makeAssetSaga(ASSET_TYPES.TEXTURES),
  models: makeAssetSaga(ASSET_TYPES.MODELS)
};

export default assetsSaga;
