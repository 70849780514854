import { actions } from '../@helpers';
import ASSET_TYPES from './assetTypes';

const moduleNames = {
  [ASSET_TYPES.IMAGES]: 'IMAGES',
  [ASSET_TYPES.TEXTURES]: 'TEXTURES',
  [ASSET_TYPES.MODELS]: 'MODELS'
};

const makeAssetActions = assetType => {
  const MODULE_NAME = moduleNames[assetType];

  const moduleActions = actions.createModuleAsyncActionCreators(MODULE_NAME);

  const setProject = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'SET_PROJECT'),
    { base: type => (assetId, projectId) => ({ type, payload: { assetId, projectId } }) }
  );

  const fetchList = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'FETCH_LIST'),
    {
      base:
        type =>
        (projectId = '') => ({ type, payload: { projectId } })
    }
  );

  const getParent = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'GET_PARENT'),
    {
      base: type => fileName => ({ type, payload: { fileName } })
    }
  );

  const copyToProject = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'COPY_TO_PROJECT'),
    {
      base: type => (assetId, projectId) => ({ type, payload: { assetId, projectId } })
    }
  );

  const deleteEntity = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'DELETE_ENTITY'),
    {
      base: type => (id, unsafe) => {
        return { type, payload: { id, unsafe } };
      },
      success: type => id => ({ type, payload: { id } })
    }
  );

  const updateAssetsAfterUpload = actions.createAsyncActionCreator(
    actions.createModulePrefixForActionType(MODULE_NAME, 'UPDATE_ASSET_LIST_AFTER_UPLOAD'),
    {
      base: type => assets => ({ type, payload: { assets } })
    }
  );

  const commonActions = {
    ...moduleActions,
    fetchList,
    delete: deleteEntity,
    updateAssetsAfterUpload,

    FETCH_LIST: fetchList.ACTION_TYPE,
    DELETE: deleteEntity.ACTION_TYPE,
    UPDATE_ASSET_LIST_AFTER_UPLOAD: updateAssetsAfterUpload.ACTION_TYPE
  };

  if (assetType === ASSET_TYPES.MODELS) return commonActions;

  return {
    ...commonActions,

    setProject,
    getParent,
    copyToProject,

    SET_PROJECT: setProject.ACTION_TYPE,
    GET_PARENT: getParent.ACTION_TYPE,
    COPY_TO_PROJECT: copyToProject.ACTION_TYPE
  };
};

const assetsActions = {
  images: makeAssetActions(ASSET_TYPES.IMAGES),
  textures: makeAssetActions(ASSET_TYPES.TEXTURES),
  models: makeAssetActions(ASSET_TYPES.MODELS)
};

export default assetsActions;
