import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BaseEntityType } from 'types';
import { DropdownItem, DropdownMenu, DropdownToggle, Icon, UncontrolledDropdown } from '../Atoms';
import { getSeedUrl } from '../../utils/appRoutes';
import { useSeedListByProject } from '../../store/seed';
import maxHeightDropdownModifier from '../../utils/maxHeightDropdownModifier';

interface ProjectListItemVersionsProps {
  projectId: string;
  publicSeedId: string;
}

const compareFunction =
  (publicSeedId: string) =>
  (seedA: BaseEntityType, seedB: BaseEntityType): number => {
    if (seedA._id === publicSeedId) return -1;

    if (seedB._id === publicSeedId) return 1;

    const dateA = new Date(seedA.updatedAt);
    const dateB = new Date(seedB.updatedAt);

    if (dateA > dateB) return -1;

    if (dateA < dateB) return 1;

    return 0;
  };

const ProjectListItemVersions: FC<ProjectListItemVersionsProps> = ({ projectId, publicSeedId }) => {
  const { seeds } = useSeedListByProject(projectId);

  return (
    <UncontrolledDropdown className="p-0">
      <DropdownToggle color="icon" className="text-primary text-decoration-underline" size="sm" caret>
        Version
      </DropdownToggle>
      <DropdownMenu modifiers={[maxHeightDropdownModifier]}>
        {seeds.sort(compareFunction(publicSeedId)).map(seed => (
          <DropdownItem key={seed._id} className="px-3 d-flex" tag={Link} to={getSeedUrl(seed._id)}>
            <Icon type="mdiFileOutline" />
            <span className={`ps-2${seed._id === publicSeedId ? ' fw-bolder' : ''}`}>
              {`${seed.name}: ${seed.version}`}
            </span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProjectListItemVersions;
