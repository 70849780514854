import { createSelector } from 'reselect';
import { nanoid } from 'nanoid/non-secure';
import { seedsSelectors } from '../seeds';
import makeAssetUrl from '../../utils/api/makeAssetUrl';
import { ASSET_TYPES } from '../assets';

export const selectParts = state => state.parts?.list;
export const selectPart = createSelector([selectParts, (_, partId) => partId], (parts, partId) => parts[partId]);
export const selectPartsRequest = state => state.parts.request;
export const selectPartsUnsavedPositions = state => state.parts.unsavedPositions;
export const selectPartsCreatedFlag = state => state.parts.created;
export const selectPartsUpdatedFlag = state => state.parts.updated;
export const selectPartsDeletedFlag = state => state.parts.deleted;
export const selectPartsUpdatingFlag = state => state.parts.updatingParts;
export const selectPartList = createSelector([selectParts], (parts = {}) => Object.values(parts));

export const selectArePartsUnsaved = createSelector(
  [selectPartsUnsavedPositions],
  positions => Object.keys(positions).length
);

export const selectPartListBySeedMemoized = createSelector([selectPartList, (_, seedId) => seedId], (parts, seedId) =>
  parts.filter(part => part.seed === seedId)
);

export const selectPartListBySeedForSuggestions = createSelector([selectPartListBySeedMemoized], parts =>
  parts.map(part => ({
    label: `${part.ReferenceName} ${part.Option}`,
    value: part._id
  }))
);

export const selectRemoteControlsListBySeed = createSelector(
  [selectPartListBySeedMemoized, seedsSelectors.selectSeedMemoized],
  (parts, seed = {}) => {
    const { remoteControls = [] } = seed;

    const controls = parts.reduce((result, part) => {
      if (part.Controls && Array.isArray(part.Controls.Object)) {
        part.Controls.Object.forEach(control => {
          if (control.remoteControlEnabled) {
            result.push({ ...control, partId: part._id, label: `${part.ReferenceName} ${part.Option}` });
          }
        });
      }

      return result;
    }, []);

    return controls
      .map(control => ({
        ...control,
        remote: remoteControls.find(rc => rc.name === control.name && rc.partId === control.partId)
      }))
      .sort((controlA, controlB) => controlA.name.localeCompare(controlB.name));
  }
);

export const selectRemoteSwitchesListBySeed = createSelector(
  [selectPartListBySeedMemoized, seedsSelectors.selectSeedMemoized],
  (parts, seed = {}) => {
    const { remoteSwitches = [] } = seed;
    const options = {};

    const remoteSwitchesMap = remoteSwitches.reduce((result, current) => {
      result[current.name] = current; // eslint-disable-line no-param-reassign

      return result;
    }, {});

    const list = parts
      .reduce((result, part) => {
        if (part.Controls && Array.isArray(part.Controls.Object)) {
          part.Controls.Object.forEach(control => {
            if (control.remoteSwitchEnabled) {
              const currentSwitch = remoteSwitchesMap[control.name];

              const newControl =
                currentSwitch && currentSwitch.partId === part._id
                  ? currentSwitch
                  : { name: control.name, partId: part._id, label: `${part.ReferenceName} ${part.Option}` };

              result.push({ control: newControl, shadowValue: { name: control.name, value: control.value } });

              options[control.name] = (control.list || []).map(option => ({ value: option.name, label: option.name }));
            }
          });
        }

        return result;
      }, [])
      .sort((itemA, itemB) => itemA.control.name.localeCompare(itemB.control.name));

    const switches = [];
    const shadowValues = [];

    list.forEach((item, index) => {
      switches.push({ ...item.control, index });
      shadowValues.push(item.shadowValue);
    });

    return { options, switches, shadowValues };
  }
);

export const selectPartsFlag = createSelector(
  [selectPartsCreatedFlag, selectPartsUpdatedFlag, selectPartsDeletedFlag],
  (created, updated, deleted) => created || updated || deleted
);

export const selectPartsReferenceNames = createSelector([selectPartListBySeedMemoized], parts =>
  [...new Set(parts.map(part => part.ReferenceName))].map(value => ({ value, label: value }))
);

export const selectViews = createSelector([selectPartListBySeedMemoized], parts => {
  const views = [];

  parts
    .filter(part => part.Views && Array.isArray(part.Views.list))
    .forEach(({ _id, Views, ReferenceName, Option }) => {
      Views.list.forEach(view =>
        views.push({
          label: `${view.name} (${ReferenceName} ${Option})`,
          value: view.name,
          partId: _id,
          view
        })
      );
    });

  return views.sort((a, b) => {
    const aIndex = a.view.viewIndex || 0;
    const bIndex = b.view.viewIndex || 0;

    if (aIndex > bIndex) return 1;

    if (aIndex < bIndex) return -1;

    return 0;
  });
});

export const selectPossibleOptionNames = controlName =>
  createSelector([selectPartListBySeedMemoized], parts =>
    parts
      .filter(part => part.ReferenceName === controlName && part.Option)
      .map(part => ({ label: part.Option, value: part.Option }))
  );

export const selectMaterials = createSelector(
  [selectPartListBySeedMemoized, seedsSelectors.selectSeedMemoized],
  (list, seed) =>
    Object.values(
      [{ Materials: seed.materials || [] }, ...list].reduce((result, part) => {
        if (Array.isArray(part.Materials)) {
          part.Materials.forEach(material => {
            result[material.Name] = material; // eslint-disable-line no-param-reassign
          });
        }

        return result;
      }, {})
    )
);

export const selectMaterialsForPreview = createSelector(
  [selectMaterials, seedsSelectors.selectSeedMemoized],
  (materials, seed) => {
    const assetURL = makeAssetUrl(ASSET_TYPES.TEXTURES);

    return materials.reduce((result, { Name, DiffuseTexture, ColorDiffuse, Textures }) => {
      const diffuse = Textures?.Diffuse?.Texture?.fileName || DiffuseTexture;

      result.push({
        name: Name,
        label: Name,
        url: diffuse ? assetURL({ fileName: diffuse, projectId: seed.projectId }) : '',
        color: ColorDiffuse || 0xffffff,
        key: nanoid(10)
      });

      return result;
    }, []);
  }
);

export const selectCameraViews = createSelector([selectPartListBySeedMemoized], parts =>
  Object.values(parts).reduce((result, part) => {
    const { Views = {} } = part;
    const { list = [] } = Views;

    result.push(...list.map(item => ({ ...item, partId: part._id })));

    return result;
  }, [])
);

export const selectCameraViewsForSuggestions = createSelector([selectCameraViews, selectParts], (list, parts) => {
  return list.map(({ name, displayName, partId }) => {
    const { ReferenceName, Option } = parts[partId] || {};

    return { label: `${name} (${ReferenceName} ${Option})`, value: name };
  });
});

export const selectControlsForSeed = createSelector([selectPartListBySeedMemoized], parts =>
  parts.reduce((result, { Controls = {}, ReferenceName, Option }) => {
    const { Object: list = [] } = Controls;

    result.push(...list.map(item => ({ ...item, parent: { ReferenceName, Option } })));

    return result;
  }, [])
);

export const selectControls = createSelector([selectPartListBySeedMemoized], parts =>
  parts.reduce((result, part) => {
    if (Array.isArray(part.Controls?.Object)) {
      part.Controls.Object.forEach(control => {
        if (control.name !== 'ViewControls') {
          result.push({ control, parent: part });
        }
      });
    }

    return result;
  }, [])
);

export const selectTabs = createSelector([selectPartListBySeedMemoized], parts =>
  parts
    .reduce((result, part) => {
      if (Array.isArray(part.Phases?.Object)) {
        part.Phases.Object.forEach(tab => result.push({ name: tab.name, definition: tab, partId: part._id }));
      }

      return result;
    }, [])
    .sort((a, b) => {
      const aIndex = a.definition.tabIndex || 0;
      const bIndex = b.definition.tabIndex || 0;

      if (aIndex > bIndex) return 1;

      if (aIndex < bIndex) return -1;

      return 0;
    })
);

export const selectTabNamesForSuggestions = createSelector([selectTabs, selectParts], (tabs, parts) =>
  tabs.map(({ partId, name }) => ({
    label: `${name} (${parts[partId].ReferenceName} ${parts[partId].Option})`,
    value: name
  }))
);

export const selectControlListForSuggestions = createSelector([selectControls], controls =>
  controls.map(({ control, parent }) => ({
    label: `${control.name} (${parent.ReferenceName} ${parent.Option})`,
    name: control.name,
    value: control.name,
    list: (control.list || []).map(option => ({ value: option.name, label: option.displayName })),
    partId: parent._id
  }))
);

const selectPartReferenceOptionList = createSelector([selectPartListBySeedMemoized], partsList => {
  return partsList.reduce((result, { ReferenceName = 'unnamed', _id }) => {
    /* eslint-disable no-param-reassign */
    result[ReferenceName] = result[ReferenceName] || [];
    result[ReferenceName].push(_id);

    /* eslint-enable no-param-reassign  */
    return result;
  }, {});
});

/**
 * Returns object of partId: true for each part that has a parent.
 * Exported for tests
 */
export const selectPartHasParentsList = createSelector(
  [selectPartReferenceOptionList, selectPartListBySeedMemoized],
  (partReferenceNames, partsList) => {
    return partsList.reduce((result, { Position = [] }) => {
      Position.forEach(({ Reference }) => {
        if (Array.isArray(partReferenceNames[Reference])) {
          partReferenceNames[Reference].forEach(id => {
            result[id] = true; // eslint-disable-line no-param-reassign
          });
        }
      });

      return result;
    }, {});
  }
);

export const selectPopulatedPartListBySeed = createSelector(
  [selectPartListBySeedMemoized, selectPartHasParentsList],
  (parts, parents) => parts.map(part => ({ ...part, populated: { ...part.populated, isOrphan: !parents[part._id] } }))
);

const selectPartsNodes = createSelector(
  [selectPartListBySeedMemoized, selectPartHasParentsList],
  (parts, partParents) =>
    parts.map(part => ({
      id: part._id,
      label: `${part.ReferenceName} ${part.Option}`,
      x: part.Config?.graphX || 0,
      y: part.Config?.graphY || 0,
      height: (2 + (Array.isArray(part?.Controls?.Object) ? part.Controls.Object.length : 0)) * 60,
      hasParent: Boolean(partParents[part._id])
    }))
);

const selectPartsNodesWithUnsavedPositions = createSelector(
  [selectPartsNodes, selectPartsUnsavedPositions],
  (nodes, parts) =>
    nodes.map(node => ({
      ...node,
      x: parts[node.id]?.graphX || node.x,
      y: parts[node.id]?.graphY || node.y,
      pristine: !parts[node.id]
    }))
);

const selectPartsEdges = createSelector(
  [selectPartListBySeedMemoized, selectPartReferenceOptionList],
  (parts, referencesMap) => {
    const edges = [];

    parts.forEach(part => {
      const { Position = [], _id: source } = part;

      Position.forEach((position, positionIndex) => {
        const { Reference } = position;

        if (referencesMap[Reference]) {
          edges.push(
            ...referencesMap[Reference].map(target => {
              return { source, target, id: `${source}-${target}-${positionIndex}` };
            })
          );
        }
      });
    });

    return edges;
  }
);

const selectPartsEdgesWithCoordinates = createSelector(
  [selectPartsEdges, selectPartsNodesWithUnsavedPositions],
  (edges, nodes) => {
    const map = nodes.reduce((result, node) => {
      result[node.id] = node; // eslint-disable-line no-param-reassign

      return result;
    }, {});

    return edges.map(edge => ({
      ...edge,
      sourcePosition: { x: map[edge.source].x, y: map[edge.source].y },
      targetPosition: { x: map[edge.target].x, y: map[edge.target].y }
    }));
  }
);

export const selectPartsNodesWithEdges = createSelector(
  [selectPartsNodesWithUnsavedPositions, selectPartsEdgesWithCoordinates, seedsSelectors.selectRootPartId],
  (nodes, edges, rootNodeId) => ({
    nodes,
    edges,
    rootNodeId
  })
);

export const selectPartControls = createSelector([selectPart], part => part?.Controls?.Object || []);

export const selectLights = createSelector([selectPartListBySeedMemoized], parts =>
  parts.reduce(
    (result, part) => {
      if (!part.Lights) {
        return result;
      }

      const mapPartLightId = light => ({ ...light, partId: part._id });

      if (Array.isArray(part.Lights.customLights)) {
        result.customLights.push(...part.Lights.customLights.map(mapPartLightId));
      }

      if (Array.isArray(part.Lights.skylights)) {
        result.skylights.push(...part.Lights.skylights.map(mapPartLightId));
      }

      if (Array.isArray(part.Lights.sunlights)) {
        result.sunlights.push(...part.Lights.sunlights.map(mapPartLightId));
      }

      return result;
    },
    { customLights: [], skylights: [], sunlights: [] }
  )
);

const covertLightsToSuggestions = (lights, parts) =>
  lights.map(light => ({
    value: light.name,
    label: `${light.name} (${parts[light.partId].ReferenceName} ${parts[light.partId].Option})`
  }));

export const selectSunlightsList = createSelector([selectLights], lights => lights.sunlights);
export const selectSunlightsListForSuggestions = createSelector([selectSunlightsList, selectParts], (lights, parts) => [
  { value: '_default', label: 'Default sunlight' },
  ...covertLightsToSuggestions(lights, parts)
]);

export const selectSkylightsList = createSelector([selectLights], lights => lights.skylights);
export const selectSkylightsListForSuggestions = createSelector([selectSkylightsList, selectParts], (lights, parts) => [
  { value: '_default', label: 'Default skylight' },
  ...covertLightsToSuggestions(lights, parts)
]);

export const selectCustomLightsList = createSelector([selectLights], lights => lights.customLights);
export const selectCustomLightsListForSuggestions = createSelector(
  [selectCustomLightsList, selectParts],
  covertLightsToSuggestions
);

export const selectLightsNames = createSelector(
  [selectSunlightsList, selectSkylightsList, selectCustomLightsList],
  (sunlights, skylights, customLights) =>
    [...customLights, ...sunlights, ...skylights].reduce((map, light) => {
      // eslint-disable-next-line no-param-reassign
      map[light.name] = map[light.name] ? map[light.name] + 1 : 1;

      return map;
    }, {})
);

export const selectLightsConflict = createSelector([selectLightsNames], names =>
  Object.keys(names)
    .map(name => ({ name, count: names[name] }))
    .filter(({ count }) => count > 1)
);

export const selectPartsPriceCodesMap = createSelector(
  [seedsSelectors.selectSeedSettingsMemoized, selectControlsForSeed],
  ({ seedSettings, projectSettings }, controls) => {
    /*
  prices are defined in
    option.priceCode
    option.priceCode_psq
    option.material[].priceCode_psq,
    seed.settings.ppsqmLayers.priceCode_psq
    (priceCode, priceCode_psq)
    option material (priceCode_psq),
    layer.priceCode_psq

  */

    const priceMap = {};

    const ppsqmLayers = seedSettings.ppsqmLayers || projectSettings.ppsqmLayers || [];

    ppsqmLayers.forEach(layer => {
      // eslint-disable-next-line no-param-reassign
      if (layer.priceCode_psq) priceMap[layer.priceCode_psq] = true;
    });

    controls.forEach(control => {
      const { list = [] } = control;

      list.forEach(option => {
        const { material = [], priceCode, priceCode_psq: priceCodePsq } = option;

        // eslint-disable-next-line no-param-reassign
        if (priceCode) priceMap[priceCode] = true;

        // eslint-disable-next-line no-param-reassign
        if (priceCodePsq) priceMap[priceCodePsq] = true;

        material.forEach(({ priceCode_psq: materialPriceCodePsq }) => {
          // eslint-disable-next-line no-param-reassign
          if (materialPriceCodePsq) priceMap[materialPriceCodePsq] = true;
        });
      });
    });

    return Object.keys(priceMap);
  }
);

const DEFAULT_SCHEME = 'default';

/** Returns two arrays for used price codes and not-used price codes */
export const selectAllPriceCodes = createSelector(
  [seedsSelectors.selectSeedPrices, selectPartsPriceCodesMap],
  (prices, usedCodes) => {
    // signature is {code, scheme1, scheme2, scheme3}

    const pricesMap = usedCodes.reduce((result, code) => {
      // eslint-disable-next-line no-param-reassign
      result[code] = { code, schemes: {}, used: true };

      return result;
    }, {});

    prices.forEach(({ code, scheme = DEFAULT_SCHEME, price }) => {
      // eslint-disable-next-line no-param-reassign
      pricesMap[code] = pricesMap[code] || { code, schemes: {} };
      // eslint-disable-next-line no-param-reassign
      pricesMap[code].schemes[scheme] = price;
    });

    return Object.values(pricesMap)
      .sort((a, b) => a.code.localeCompare(b.code))
      .sort((a, b) => {
        if (a.used && b.used) return 0;

        if (a.used) return -1;

        return 1;
      });
  }
);

export const selectAllPriceSchemes = createSelector(
  [selectAllPriceCodes, seedsSelectors.selectPriceSchemes],
  (priceCodes, schemes) => {
    const schemesMap = {};

    schemes.forEach(scheme => {
      schemesMap[scheme.name] = scheme;
    });

    priceCodes.forEach(({ schemes: schemeData }) => {
      const schemeEntries = Object.keys(schemeData);

      schemeEntries.forEach(entry => {
        if (!schemesMap[entry]) {
          schemesMap[entry] = { name: entry, displayName: entry };
        }
      });
    });

    const allSchemes = Object.values(schemesMap);

    if (allSchemes.length > 0) {
      return allSchemes;
    }

    return [{ name: DEFAULT_SCHEME, displayName: 'Default' }];
  }
);

export const selectExistingInteractionGroupsForSuggestions = createSelector([selectControls], controls => {
  const map = {};

  controls.forEach(({ control }) => {
    const { name, interactionGroups = [] } = control;

    map[name] = { label: `${name} (control)`, value: name };

    interactionGroups.forEach(({ name: groupName }) => {
      map[groupName] = { label: `${groupName} (group)`, value: groupName };
    });
  });

  return Object.values(map);
});
