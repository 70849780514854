import { FC, lazy } from 'react';
import { IAceEditorProps } from 'react-ace';
import { Ace } from 'ace-builds';
import Suspense from '../Suspense/Suspense';

const AceEditor = lazy<FC<IAceEditorProps>>(() => import('react-ace') as any);

export type CodeEditorErrorsType = Ace.Annotation[];

interface CodeEditorProps {
  value?: string;
  mode: 'json' | 'html' | 'css' | 'javascript';
  onChange?(value: string): void;
  onValidate?(annotations: CodeEditorErrorsType): void;
  placeholder?: string;
  readonly?: boolean;
  name?: string;
  className?: string;
}

const CodeEditor = ({
  value,
  mode,
  onChange,
  onValidate,
  placeholder,
  readonly,
  name = 'code-editor',
  className
}: CodeEditorProps) => {
  return (
    <Suspense>
      <AceEditor
        mode={mode}
        name={name}
        placeholder={placeholder}
        readOnly={readonly}
        theme="xcode"
        editorProps={{ $blockScrolling: true }}
        highlightActiveLine
        value={value}
        onChange={onChange}
        onValidate={onValidate}
        onBeforeLoad={ace => {
          const MODES_AND_THEMES = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/';

          ace.config.set('basePath', MODES_AND_THEMES);
        }}
        setOptions={{
          showLineNumbers: true,
          tabSize: 2
        }}
        width="100%"
        style={{ zIndex: 0 }}
        className={className}
      />
    </Suspense>
  );
};

export default CodeEditor;
