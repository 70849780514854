import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import ASSET_TYPES from './assetTypes';

// eslint-disable-next-line import/prefer-default-export
export const useActiveAssetsType = () => {
  const location = useLocation();

  return useMemo(() => {
    const parsed = queryString.parse(location.search);

    return parsed.type || ASSET_TYPES.IMAGES;
  }, [location.search]);
};
