import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { assetsActions, ASSET_TYPES } from '../../../modules/assets';
import { useModal } from '../../../utils/hooks';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import { TYPE_ASSET_TYPES } from '../../../utils/propTypes';
import FileUpload from '../../FileUpload';
import api from '../../../utils/api';
import { LOST_AND_FOUND_KEY } from '../ProjectList';

const AssetUpload = ({
  selectedProject,
  type,
  updateTexturesAfterUpload,
  updateImagesAfterUpload,
  updateModelsAfterUpload,
  size
}) => {
  let rights = [RIGHTS.IMAGES__UPLOAD];
  let updateHandler = updateImagesAfterUpload;
  let apiUpload = api.assets.images.multiUpload(selectedProject);
  let caption = 'Upload images';

  if (type === ASSET_TYPES.TEXTURES) {
    rights = [RIGHTS.TEXTURES__UPLOAD];
    updateHandler = updateTexturesAfterUpload;
    apiUpload = api.assets.textures.multiUpload(selectedProject);
    caption = 'Upload textures';
  }

  if (type === ASSET_TYPES.MODELS) {
    rights = [RIGHTS.MODELS__UPLOAD];
    updateHandler = updateModelsAfterUpload;
    apiUpload = api.assets.models.multiUpload(selectedProject);
    caption = 'Upload models';
  }

  if (!selectedProject) {
    rights = [RIGHTS.ASSETS__MANIPULATE_GLOBALS];
  }

  const { isOpen, open, close } = useModal();

  return selectedProject === LOST_AND_FOUND_KEY ? null : (
    <>
      <ProtectedButton rights={rights} color="danger" className="pull-right flex-grow-0" onClick={open} size={size}>
        <i className="fa fa-fw fa-upload" />
        Upload
      </ProtectedButton>
      {isOpen ? (
        <FileUpload
          onClose={close}
          api={apiUpload}
          caption={caption}
          onSuccess={updateHandler}
          limit={10}
          multiUpload
        />
      ) : null}
    </>
  );
};

AssetUpload.propTypes = {
  type: TYPE_ASSET_TYPES,
  selectedProject: PropTypes.string.isRequired,
  updateTexturesAfterUpload: PropTypes.func.isRequired,
  updateImagesAfterUpload: PropTypes.func.isRequired,
  updateModelsAfterUpload: PropTypes.func.isRequired,
  size: PropTypes.string
};
AssetUpload.defaultProps = {
  type: ASSET_TYPES.IMAGES,
  size: 'md'
};
const mapDispatchToProps = {
  updateTexturesAfterUpload: assetsActions.textures.updateAssetsAfterUpload,
  updateImagesAfterUpload: assetsActions.images.updateAssetsAfterUpload,
  updateModelsAfterUpload: assetsActions.models.updateAssetsAfterUpload
};

export default connect(null, mapDispatchToProps)(AssetUpload);
