import { get } from 'lodash';
import { ProjectType } from 'types';
import { FormField } from '../../Atoms';
import { State } from '../types';

interface MetricIconWrapperProps {
  index?: number;
  state?: State;
  projectImages?: { name: string; url: string }[];
  uploadImage: () => void;
  updateImagesAfterUpload: () => void;
  column: 'left' | 'right';
}

interface UnitAndCaveatProps {
  index?: number;
  state?: State;
  column: 'left' | 'right';
}

export const MetricIconWrapper = (props: MetricIconWrapperProps) => {
  if (props.index === undefined) {
    return null;
  }

  return get(props.state, `metricState[${props.index}].icon`, false) ? (
    <FormField.Image<ProjectType>
      name={`settings.metrics[${props.index}].image.fileName`}
      label="Custom image for icon"
      description=""
      list={props.projectImages}
      uploadImage={props.uploadImage}
      onUploadSuccess={props.updateImagesAfterUpload}
      column={props.column}
    />
  ) : null;
};

export const MetricUnitWrapper = (props: UnitAndCaveatProps) => {
  if (props.index === undefined) {
    return null;
  }

  return get(props.state, `metricState[${props.index}].unit`, false) ? (
    <FormField.Toggle<ProjectType>
      name={`settings.metrics[${props.index}].unitBeforeValue`}
      label="Unit before value"
      description="By default unit is after the value"
      column={props.column}
    />
  ) : null;
};

export const MetricCaveatWrapper = (props: UnitAndCaveatProps) => {
  if (props.index === undefined) {
    return null;
  }

  return get(props.state, `metricState[${props.index}].caveat`, false) ? (
    <FormField.Text<ProjectType>
      name={`settings.metrics[${props.index}].caveatTitle`}
      label="Caveat Title"
      description="Caption to show in summary panel"
      required
      column={props.column}
    />
  ) : null;
};
