import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Col, Row } from '../../Atoms';
import Field from '../../Form/Field';
import Form from '../../Form';
import { FACTORY_MAP_TYPE, TYPE_FACTORY_MAP } from '../../../utils/propTypes';
import { factoryMapsActions } from '../../../modules/factoryMaps';
import { usePartList } from '../../../utils/hooks';
import ProtectedButton from '../../ProtectedButton';
import { RIGHTS } from '../../../utils/rights';
import FactoryMapCreation from '../../FactoryMapsPage/FactoryMapCreation';
import { useSeedListForSelect } from '../../../store/seed';
import FactoryMapPreview from './FactoryMapPreview';
import FactoryMapFormValues from './FactoryMapFormValues';

const TYPE_OPTIONS = [
  { label: FACTORY_MAP_TYPE.MAP, value: FACTORY_MAP_TYPE.MAP },
  { label: FACTORY_MAP_TYPE.KEY, value: FACTORY_MAP_TYPE.KEY },
  { label: FACTORY_MAP_TYPE.KEYS, value: FACTORY_MAP_TYPE.KEYS },
  { label: FACTORY_MAP_TYPE.VALUE, value: FACTORY_MAP_TYPE.VALUE }
];

const FactoryMapForm = ({ factoryMap }) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(factoryMap);
  const handleChangeForm = useCallback(form => {
    setFormState(form.values);
  }, []);
  const handleDelete = useCallback(() => {
    // eslint-disable-next-line no-alert
    const response = window.confirm(
      `Are you sure that you want to delete factory map "${factoryMap.name} (${factoryMap._id})"?`
    );

    if (response) {
      dispatch(factoryMapsActions.deleteFactoryMap(factoryMap._id));
    }
  }, [dispatch, factoryMap._id, factoryMap.name]);
  const handleSubmitForm = useCallback(
    value => {
      dispatch(factoryMapsActions.updateFactoryMap(value));
    },
    [dispatch]
  );
  const handleTogglePublic = useCallback(() => {
    dispatch(factoryMapsActions.setPublishedFlag(factoryMap._id, !factoryMap.published));
  }, [dispatch, factoryMap._id, factoryMap.published]);

  const { request: partsRequest } = usePartList(formState.seedId);

  const { seeds } = useSeedListForSelect();

  return (
    <Row>
      <Col sm="12" lg="6" xl="8" className="mb-3">
        {factoryMap.published ? (
          <Alert color="warning">
            Changes to published factory maps cannot be saved. Unpublish the map first to make changes
          </Alert>
        ) : null}
        <Form
          initialValues={factoryMap}
          onChange={handleChangeForm}
          onSubmit={handleSubmitForm}
          useBottomButtons={!factoryMap.published}
          customButton={
            <ProtectedButton
              className="pull-right"
              onClick={handleDelete}
              disabled={factoryMap.published}
              color="danger"
              outline
              rights={[RIGHTS.FACTORY_MAPS__DELETE]}
            >
              <i className="fa fa-trash fa-fw" />
              Delete
            </ProtectedButton>
          }
        >
          <div className="d-flex flex-row mb-2">
            <ProtectedButton
              onClick={handleTogglePublic}
              color="primary"
              outline
              rights={[RIGHTS.FACTORY_MAPS__PUBLISH]}
            >
              <i className={`fa fa-eye${factoryMap.published ? '-slash' : ''} fa-fw`} />
              {factoryMap.published ? 'Unpublish' : 'Publish'}
            </ProtectedButton>
            <FactoryMapCreation
              initialValues={{
                definition: factoryMap.definition,
                seedId: factoryMap.seedId,
                version: factoryMap.version + 1
              }}
              clone
            />
          </div>
          <Field.Text required name="name" label="Name" />
          <Field.Number name="version" label="Version" />
          <Field.Select required options={seeds} name="seedId" label="Select seed" />
          {partsRequest ? (
            <div className="d-flex flex-column align-items-center justify-content-center mb-3">
              <i className="fa fa-spinner fa-pulse fa-3x fa-fw text-primary" />
              <span>Loading parts</span>
            </div>
          ) : (
            <Field.ArrayObject name="definition" label="Definition">
              <Field.Text required name="name" label="Name" description="Identifier in the mapping" />
              <Field.Select required name="type" label="Type" options={TYPE_OPTIONS} />
              <FactoryMapFormValues name="values" seedId={formState.seedId} definition={formState.definition} />
            </Field.ArrayObject>
          )}
        </Form>
      </Col>
      <Col sm="12" lg="6" xl="4">
        <FactoryMapPreview factoryMap={formState} />
      </Col>
    </Row>
  );
};

FactoryMapForm.propTypes = {
  factoryMap: TYPE_FACTORY_MAP.isRequired
};

export default FactoryMapForm;
