import React from 'react';
import b from 'b_';
import ReactTable from 'react-table-6';
import { TimeSeries } from 'pondjs';
import { Col, Row } from '../../../Atoms';
import { msToTime } from '../../../../utils';
import { TYPE_SEED_STATS } from '../../../../utils/propTypes';
import StatisticsChart from './StatisticsChart';

const statisticsData = b.with('statistics-data');

function getSeries(data) {
  return new TimeSeries({
    name: 'Visits',
    columns: ['time', 'value'],
    points: data.map(item => [new Date(item.date), item.value / 1000 / 60])
  });
}

class StatisticsData extends React.PureComponent {
  render() {
    const { statistics } = this.props;
    const columns = [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: row => new Date(row.value).toLocaleString(),
        minWidth: 170
      },
      {
        Header: 'Time',
        accessor: 'value',
        Cell: row => msToTime(row.value),
        minWidth: 170
      }
    ];

    return (
      <div className={statisticsData()}>
        <h5 className="text-primary mt-3">Visits</h5>
        <Row>
          <Col lg="6" className="mb-3">
            <ReactTable
              data={statistics.visits}
              showPagination
              columns={columns}
              defaultPageSize={10}
              className="-striped"
            />
          </Col>
          <Col lg="6">
            <StatisticsChart series={getSeries(statistics.visits)} />
          </Col>
        </Row>
      </div>
    );
  }
}

StatisticsData.propTypes = {
  statistics: TYPE_SEED_STATS.isRequired
};

export default StatisticsData;
