import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RIGHTS } from '../../utils/rights';
import ProtectedButton from '../ProtectedButton';
import { instancesActions } from '../../modules/instances';
import { TYPE_INSTANCE } from '../../utils/propTypes';

export const DeleteRequestButton = ({ instance, deleteRequest }) => {
  const handleDeleteButtonClick = () => {
    const response = window.confirm('Are you sure that you want to delete request ?'); // eslint-disable-line no-alert

    if (response) {
      deleteRequest(instance._id);
    }
  };

  if (instance.lead.sentToProduction && !instance.lead.cancelOrder) {
    return null;
  }

  return (
    <ProtectedButton
      rights={[RIGHTS.REQUESTS__DELETE]}
      onClick={handleDeleteButtonClick}
      color="danger"
      size="sm"
      outline
    >
      <i className="fa fa-trash fa-fw" />
      Delete request
    </ProtectedButton>
  );
};

DeleteRequestButton.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  deleteRequest: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  deleteRequest: instancesActions.deleteRequest
};

export default connect(null, mapDispatchToProps)(DeleteRequestButton);
