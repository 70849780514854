import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const PARSER_WITH_EMPTY = input => {
  return input ? Number(input) : '';
};

function NumberField(props) {
  return <Input {...props} parse={PARSER_WITH_EMPTY} type="number" />;
}

NumberField.defaultProps = {
  allowEmpty: false
};

NumberField.propTypes = {
  // not used currently, left to not break forms and keep track of this property usage
  allowEmpty: PropTypes.bool
};

export default NumberField;
