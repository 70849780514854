import { PropsWithChildren, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Fade } from '../Bootstrap';
import { FormProps } from './types';
import FormSpy from './FormSpy';
import NavigationInterceptor from './NavigationInterceptor';
import { FormContext } from './FormContext';

const Form = <T,>({
  defaultValues,
  className = '',
  onSubmit: onSubmitProp,
  onInvalid,
  onChangeFormState,
  onChangeFormValues,
  children,
  interruptNavigation = true,
  modalMode = false,
  onCancel,
  shadowValues
}: PropsWithChildren<FormProps<T>>) => {
  const methods = useForm<T>({
    mode: 'onChange',
    defaultValues
  });
  const { handleSubmit, reset, formState } = methods;

  const { isDirty, isValid, isSubmitSuccessful } = formState;

  useEffect(() => {
    if (!onChangeFormState) return;

    onChangeFormState(formState);
  }, [formState, onChangeFormState]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit = useMemo(() => handleSubmit(onSubmitProp, onInvalid), [handleSubmit, onInvalid, onSubmitProp]);

  return (
    <FormContext.Provider value={{ shadowValues }}>
      <FormProvider<T> {...methods}>
        <form onSubmit={onSubmit} className={className}>
          <div className={modalMode ? 'modal-body' : 'mb-4'}>{children}</div>

          {onChangeFormValues ? <FormSpy<T> onChange={onChangeFormValues} /> : null}

          {interruptNavigation ? (
            <NavigationInterceptor
              isDirty={isDirty}
              isValid={isValid}
              isSubmitSuccessful={isSubmitSuccessful}
              onSubmit={onSubmit}
            />
          ) : null}

          <Fade
            in={isDirty || modalMode}
            appear={modalMode}
            className={
              modalMode ? 'modal-footer' : 'position-sticky d-flex justify-content-end align-items-center bg-white p-3'
            }
            style={{ bottom: '0' }}
            mountOnEnter
            unmountOnExit
          >
            <small className="text-muted me-3">You have unsaved changes</small>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => {
                reset();
                onCancel && onCancel();
              }}
            >
              Discard
            </button>
            <button type="submit" className="btn btn-primary" disabled={!isValid}>
              Submit
            </button>
          </Fade>
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
};

export default Form;
