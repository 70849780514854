import React from 'react';
import PropTypes from 'prop-types';

export const NODE_WIDTH = 300;

const GraphEdge = ({ source, target, className }) => {
  const first = { x: source.x + NODE_WIDTH, y: source.y + 30 };
  const last = { x: target.x, y: target.y + 15 };
  const t = (last.x - first.x) / 2;
  const path = ['M', first.x, first.y, 'C', first.x + t, first.y, last.x - t, last.y, last.x, last.y].join(' ');

  return <path d={path} className={className} />;
};

GraphEdge.propTypes = {
  source: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }).isRequired,
  target: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }).isRequired,
  className: PropTypes.string.isRequired
};

export default GraphEdge;
