import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import saveAs from 'file-saver';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from '../../Atoms';
import ProtectedButton from '../../ProtectedButton';
import AssetInfo from '../AssetInfo';
import { RIGHTS } from '../../../utils/rights';
import { TYPE_ASSET, TYPE_ASSET_PARENT } from '../../../utils/propTypes';
import ProjectList from '../ProjectList';
import { useUserRights } from '../../../utils/hooks';
import { useProjects } from '../../../store/project';

const AssetModal = props => {
  const {
    onClose,
    getAssetParent,
    asset,
    copyAssetToProject,
    setAssetProject,
    deleteAsset,
    getAssetUrl,
    assetInfo,
    assetParentsRequesting,
    selectedProject,
    hideChangingProjects
  } = props;

  const [isInfoFetched, setInfoFetched] = useState(false);
  const { projects: projectsMap } = useProjects();
  const hasRights = useUserRights();

  const fetchInfoHandler = useCallback(() => {
    setInfoFetched(true);
    getAssetParent(asset.filename);
  }, [asset.filename, getAssetParent]);

  const renderProjectSelect = useCallback(() => {
    const projectSelectHandler = result => setAssetProject(asset._id, result);
    const metadata = asset.metadata || {};
    const { projectId = '' } = metadata;
    const { name = 'none' } = projectsMap[projectId] || {};

    return hasRights([RIGHTS.TEXTURES__UPDATE, RIGHTS.IMAGES__UPDATE]) && !hideChangingProjects ? (
      <ProjectList onSelect={projectSelectHandler} selectedProject={projectId} isClearable />
    ) : (
      <span>{`${name} (${projectId})`}</span>
    );
  }, [asset._id, asset.metadata, hasRights, hideChangingProjects, projectsMap, setAssetProject]);

  const deleteAssetHandler = useCallback(
    e => {
      const response = window.confirm(`Are you sure that you want to delete asset "${asset.filename}"?`); // eslint-disable-line no-alert

      if (response) {
        deleteAsset(asset._id, e.currentTarget.dataset.unsafe);
      }
    },
    [asset._id, asset.filename, deleteAsset]
  );

  const url = getAssetUrl({ fileName: asset._id, projectId: asset.metadata ? asset.metadata.projectId : '' });

  const handleDownload = useCallback(() => {
    saveAs(url, asset.filename);
  }, [asset.filename, url]);

  let softDeleteRights;
  let hardDeleteRights;

  if (selectedProject) {
    softDeleteRights = [RIGHTS.IMAGES__SOFT_DELETE, RIGHTS.TEXTURES__SOFT_DELETE];
    hardDeleteRights = [RIGHTS.IMAGES__HARD_DELETE, RIGHTS.TEXTURES__HARD_DELETE];
  } else {
    softDeleteRights = [RIGHTS.ASSETS__MANIPULATE_GLOBALS];
    hardDeleteRights = [RIGHTS.ASSETS__MANIPULATE_GLOBALS];
  }

  return (
    <Modal size="xl" isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>{asset.filename}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <img className="d-block w-100" src={url} alt={asset.filename} />
          </Col>
          <Col lg="4" sm="12">
            <section>Project: {renderProjectSelect()}</section>
            <p>Upload date: {new Date(asset.uploadDate).toLocaleString()}</p>
            <p>Size: {filesize(asset.length)}</p>
            <hr />
            <p>
              <Button size="sm" color="primary" outline block onClick={handleDownload}>
                Download
              </Button>
            </p>
            <p>
              <ProtectedButton
                rights={[RIGHTS.IMAGES__ANALYSE, RIGHTS.TEXTURES__ANALYSE]}
                size="sm"
                outline
                block
                color="info"
                onClick={fetchInfoHandler}
                disabled={assetParentsRequesting}
              >
                <i className={`fa ${assetParentsRequesting ? 'fa-circle-o-notch fa-spin' : 'fa-info'} fa-fw`} />
                Fetch info
              </ProtectedButton>
            </p>
            <p>
              <ProtectedButton
                rights={softDeleteRights}
                size="sm"
                outline
                block
                color="danger"
                onClick={deleteAssetHandler}
              >
                <i className="fa fa-ban fa-fw" />
                Remove
              </ProtectedButton>
            </p>
            <p>
              <ProtectedButton
                rights={hardDeleteRights}
                outline
                block
                size="sm"
                color="danger"
                onClick={deleteAssetHandler}
                data-unsafe="true"
              >
                <i className="fa fa-trash fa-fw" />
                Delete
              </ProtectedButton>
            </p>
          </Col>
        </Row>
        {isInfoFetched ? (
          <AssetInfo
            asset={asset}
            assetInfo={assetInfo}
            setAssetProject={setAssetProject}
            copyAssetToProject={copyAssetToProject}
            requesting={assetParentsRequesting}
            hideChangingProjects={hideChangingProjects}
          />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          <i className="fa fa-times fa-fw" />
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AssetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  asset: TYPE_ASSET.isRequired,
  getAssetUrl: PropTypes.func.isRequired,
  setAssetProject: PropTypes.func.isRequired,
  getAssetParent: PropTypes.func.isRequired,
  deleteAsset: PropTypes.func.isRequired,
  assetInfo: TYPE_ASSET_PARENT,
  copyAssetToProject: PropTypes.func.isRequired,
  assetParentsRequesting: PropTypes.bool.isRequired,
  selectedProject: PropTypes.string,
  hideChangingProjects: PropTypes.bool
};

AssetModal.defaultProps = {
  assetInfo: {},
  selectedProject: '',
  hideChangingProjects: false
};

export default AssetModal;
