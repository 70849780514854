import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { TYPE_GRAPH_EDGES, TYPE_GRAPH_NODES, TYPE_NODE_ACTIONS } from '../../../utils/propTypes';
import GraphNode from '../GraphNode';
import GraphEdge, { NODE_WIDTH } from '../GraphEdge';
import CanvasControls from './CanvasControls';

const graph = b.with('graph');

const useCanvasControlsCallbacks = (nodes, edges, onMapSet, graphContainer, onReorderNodes) => {
  const [minX, maxX, minY, maxY] = useMemo(() => {
    const arrX = [];
    const arrY = [];

    nodes.forEach(({ x, y }) => {
      arrX.push(x);
      arrY.push(y);
    });

    // also add 10 px for padding
    return [Math.min(...arrX) - 10, Math.max(...arrX) + 10, Math.min(...arrY) - 10, Math.max(...arrY) + 10];
  }, [nodes]);

  const zoomExtentsHandler = useCallback(() => {
    const { clientWidth, clientHeight } = graphContainer.current;

    const scaleX = clientWidth / (maxX - minX);
    const scaleY = clientHeight / (maxY - minY);
    const scale = scaleX < scaleY ? scaleX : scaleY;

    const cappedScale = scale < 1 ? scale : 1;

    const midY = (maxY + minY) / 2;
    const midX = (maxX + minX) / 2;

    const newX = 0.5 * clientWidth - midX * cappedScale;
    const newY = 0.5 * clientHeight - midY * cappedScale;

    onMapSet({ scale: cappedScale, translation: { x: newX, y: newY } });
  }, [graphContainer, maxX, maxY, minX, minY, onMapSet]);

  const reorderHandler = useCallback(() => {
    import('dagre')
      .then(dagre => {
        const Graph = new dagre.graphlib.Graph();

        Graph.setGraph({
          rankdir: 'LR',
          nodesep: 40,
          ranksep: NODE_WIDTH,
          labelpos: 'l',
          marginx: 0,
          marginy: 0
        });

        // eslint-disable-next-line func-names
        Graph.setDefaultEdgeLabel(function () {
          return {};
        });

        nodes.forEach(node => {
          Graph.setNode(node.id, { id: node.id, width: NODE_WIDTH, height: node.height });
        });

        edges.forEach(edge => Graph.setEdge(edge.source, edge.target));

        dagre.layout(Graph);

        const layoutNodes = Graph.nodes().map(node => Graph.node(node));

        onReorderNodes(layoutNodes.filter(node => node));

        setHasZoomed(false);
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [edges, nodes, onReorderNodes]);

  const [hasZoomed, setHasZoomed] = useState(false);

  useEffect(() => {
    if (!hasZoomed) {
      setHasZoomed(true);
      zoomExtentsHandler();
    }
  }, [hasZoomed, zoomExtentsHandler]);

  return { zoomExtentsHandler, reorderHandler };
};

const GraphCanvas = ({
  translation,
  scale,
  onMapSet,
  nodes,
  edges,
  onSelectNode,
  onDeselectNode,
  selectedNodes,
  onOpenNode,
  onOpenEdge,
  nodeActions,
  NodeChildren,
  onDragStop,
  onDrag,
  onDragStart,
  onReorderNodes,
  draggedNode,
  onSubmit,
  onReset,
  isPristine,
  graphActions,
  rootNodeId
}) => {
  const graphContainer = useRef({ current: {} });

  const { zoomExtentsHandler, reorderHandler } = useCanvasControlsCallbacks(
    nodes,
    edges,
    onMapSet,
    graphContainer,
    onReorderNodes
  );

  /* Memoizing nodes and edges speeds up rendering considerably */

  const childNodes = useMemo(
    () =>
      nodes.map(node => {
        const isSelected = Boolean(selectedNodes[node.id]);
        const isDragged = node.id === draggedNode;
        /* If node is not dragged, we supply it with scale 1 so the node wouldn't update */

        const scaleFactor = isDragged ? scale : 1;

        return (
          <GraphNode
            className={graph('node')}
            onSelect={onSelectNode}
            onDeselect={onDeselectNode}
            isSelected={isSelected}
            key={node.id}
            node={node}
            actions={nodeActions}
            NodeChildren={NodeChildren}
            onDragStart={onDragStart}
            onDragStop={onDragStop}
            onDrag={onDrag}
            isDragged={isDragged}
            scale={scaleFactor}
            color={node.hasParent || node.id === rootNodeId ? 'light' : 'danger'}
          />
        );
      }),
    [
      NodeChildren,
      draggedNode,
      nodeActions,
      nodes,
      onDeselectNode,
      onDrag,
      onDragStart,
      onDragStop,
      onSelectNode,
      rootNodeId,
      scale,
      selectedNodes
    ]
  );

  const childEdges = useMemo(
    () =>
      edges.map(edge => (
        <GraphEdge key={edge.id} source={edge.sourcePosition} target={edge.targetPosition} className={graph('edge')} />
      )),
    [edges]
  );

  return (
    <>
      <div className={graph('nodes-container')} ref={graphContainer}>
        <svg width="100%" height="100%" className={graph('edges')} xmlns="http://www.w3.org/2000/svg">
          <g transform={`translate(${translation.x}, ${translation.y}) scale(${scale})`}>{childEdges}</g>
        </svg>
        <div
          className={graph('nodes')}
          style={{ transform: `translate(${translation.x}px, ${translation.y}px) scale(${scale})` }}
        >
          {childNodes}
        </div>
        <CanvasControls
          className={graph('canvas-controls')}
          onZoomExtents={zoomExtentsHandler}
          onReorder={reorderHandler}
          onSubmit={onSubmit}
          onReset={onReset}
          isPristine={isPristine}
          graphActions={graphActions}
        />
      </div>
    </>
  );
};

GraphCanvas.propTypes = {
  translation: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }).isRequired,
  scale: PropTypes.number.isRequired,
  nodes: TYPE_GRAPH_NODES.isRequired,
  edges: TYPE_GRAPH_EDGES.isRequired,
  onSelectNode: PropTypes.func.isRequired,
  onDeselectNode: PropTypes.func.isRequired,
  onOpenNode: PropTypes.func,
  onOpenEdge: PropTypes.func,
  onReorderNodes: PropTypes.func,
  selectedNodes: PropTypes.objectOf(PropTypes.string).isRequired,
  nodeActions: TYPE_NODE_ACTIONS,
  NodeChildren: PropTypes.func,
  onMapSet: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  draggedNode: PropTypes.string,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  isPristine: PropTypes.bool,
  graphActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  rootNodeId: PropTypes.string.isRequired
};

GraphCanvas.defaultProps = {
  onOpenNode: () => null,
  onOpenEdge: () => null,
  onReorderNodes: () => null,
  nodeActions: [],
  NodeChildren: () => null,
  draggedNode: '',
  onSubmit: undefined,
  onReset: undefined,
  isPristine: true,
  graphActions: []
};

export default GraphCanvas;
