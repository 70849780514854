import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import b from 'b_';
import { TYPE_INSTANCE } from '../../../utils/propTypes';
import { RIGHTS } from '../../../utils/rights';
import DeleteRequestButton from '../../DeleteRequestButton';
import ProtectedButton from '../../ProtectedButton';
import { authSelectors } from '../../../modules/auth';
import { instancesActions } from '../../../modules/instances';
import FileUpload from '../../FileUpload';
import api from '../../../utils/api';
import PDFViewer from '../../PDFViewer';
import OpenConfiguratorLink from '../../OpenConfiguratorLink';
import LegalesignSigner from './LegalesignSigner';
import SendToProduction from './SendToProduction';
import InstanceEdit from './InstanceEdit';
import MappedInstance from './MappedInstance';

import './InstanceDetails.scss';

const instanceDetails = b.with('instance-details');

export class InstanceDetails extends React.PureComponent {
  state = {
    pdf: {
      id: '',
      signed: false
    },
    editing: false
  };

  get hasLead() {
    const { instance } = this.props;

    return instance.lead && instance.lead.firstName;
  }

  get hasPDFFile() {
    const { instance } = this.props;

    return instance.lead && instance.lead.pdfFileId;
  }

  get isLegaleSignAvailable() {
    const { isLegaleSignAvailable } = this.props;

    return isLegaleSignAvailable && this.hasLead;
  }

  get isSendToProductionAvailable() {
    const { isSendToProductionAvailable } = this.props;

    return isSendToProductionAvailable && this.hasPDFFile;
  }

  handleSuccessUploadPDF = result => {
    this.props.signedPDFUploaded(result[0]);
  };

  handleMakeLeadPrimary = () => {
    const { makeLeadPrimary, instance } = this.props;

    makeLeadPrimary(instance._id);
  };

  handleShowPDF = () => {
    this.handleOpenPDFModal(false);
  };

  handleShowSignedPDF = () => {
    this.handleOpenPDFModal(true);
  };

  handleOpenPDFModal = (signed = false) => {
    const { instance } = this.props;

    this.setState(prevState => ({ ...prevState, pdf: { id: instance._id, signed } }));
  };

  handleOpenUploadPDFModal = () => {
    const { instance } = this.props;

    this.setState({ uploadPDF: instance._id });
  };

  handleClosePDFModal = () => {
    this.setState(prevState => ({ ...prevState, pdf: {} }));
  };

  handleCloseUploadPDFModal = () => {
    this.setState({ uploadPDF: false });
  };

  handleOpenEditingModal = () => {
    this.setState({ editing: true });
  };

  handleCloseEditingModal = () => {
    this.setState({ editing: false });
  };

  renderActions() {
    const { instance, isSendToProductionAvailable } = this.props;

    if (!this.hasLead) {
      return null;
    }

    return (
      <>
        <ProtectedButton
          rights={[RIGHTS.VISITORS__MAKE_LEAD_PRIMARY]}
          onClick={this.handleMakeLeadPrimary}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-fw fa-bookmark" />
          Make Primary
        </ProtectedButton>
        <ProtectedButton
          rights={[RIGHTS.VISITORS__READ_PDF]}
          onClick={this.handleShowPDF}
          size="sm"
          color="info"
          outline
        >
          <i className="fa fa-fw fa-file-pdf-o" />
          Open PDF
        </ProtectedButton>
        <ProtectedButton
          rights={[RIGHTS.VISITORS__UPLOAD_SIGNED_PDF]}
          onClick={this.handleOpenUploadPDFModal}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-fw fa-upload" />
          Upload signed PDF
        </ProtectedButton>
        {this.hasPDFFile ? (
          <ProtectedButton
            rights={[RIGHTS.VISITORS__READ_SIGNED_PDF]}
            onClick={this.handleShowSignedPDF}
            size="sm"
            color="info"
            outline
          >
            <i className="fa fa-fw fa-download" />
            Open signed PDF
          </ProtectedButton>
        ) : null}
        <DeleteRequestButton instance={instance} />
        <ProtectedButton
          rights={[RIGHTS.VISITORS__UPDATE]}
          onClick={this.handleOpenEditingModal}
          size="sm"
          color="primary"
          outline
        >
          <i className="fa fa-edit fa-fw" />
          Edit
        </ProtectedButton>
        {isSendToProductionAvailable ? <MappedInstance instance={instance} /> : null}
      </>
    );
  }

  renderInstanceDetails() {
    const { instance } = this.props;

    return (
      <>
        <h5 className="text-primary">Lead details</h5>
        <dl className="d-flex flex-wrap">
          <dt className={instanceDetails('label')}>Lead id</dt>
          <dd className={instanceDetails('info')}>
            <OpenConfiguratorLink type="id" link={instance._id} caption={instance._id} icon="" />
          </dd>
          <dt className={instanceDetails('label')}>Lead name</dt>
          <dd className={instanceDetails('info')}>{instance.instanceName}</dd>
          <dt className={instanceDetails('label')}>Created</dt>
          <dd className={instanceDetails('info')}>{new Date(instance.createdAt).toLocaleString()}</dd>
          <dt className={instanceDetails('label')}>Modified</dt>
          <dd className={instanceDetails('info')}>{new Date(instance.updatedAt).toLocaleString()}</dd>
          <dt className={instanceDetails('label')}>PDF notes</dt>
          <dd className={instanceDetails('info')}>{instance.pdfNotes}</dd>
        </dl>
      </>
    );
  }

  renderLeadDetails() {
    if (!this.hasLead) {
      return null;
    }

    const { instance } = this.props;
    const { lead } = instance;

    return (
      <>
        <dl className="d-flex flex-wrap">
          <dt className={instanceDetails('label')}>Request name</dt>
          <dd className={instanceDetails('info')}>{`${lead.firstName} ${lead.lastName}`}</dd>
          <dt className={instanceDetails('label')}>Request email</dt>
          <dd className={instanceDetails('info')}>{lead.email}</dd>
          <dt className={instanceDetails('label')}>Request message</dt>
          <dd className={instanceDetails('info')}>{lead.message}</dd>
          <dt className={instanceDetails('label')}>Downloaded PDF</dt>
          <dd className={instanceDetails('info')}>
            <span className={`fa fa-fw ${lead.downloadedPDF ? 'fa-check text-success' : 'fa-ban text-info'}`} />
          </dd>
        </dl>
      </>
    );
  }

  renderFeatures() {
    const { instance } = this.props;

    return (
      <div className={instanceDetails('features')}>
        {this.isLegaleSignAvailable ? (
          <div className={instanceDetails('signature')}>
            <LegalesignSigner instance={instance} />
          </div>
        ) : null}
        {this.isSendToProductionAvailable ? (
          <div className={instanceDetails('production')}>
            <SendToProduction instance={instance} />
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const { instance } = this.props;
    const { pdf, uploadPDF, editing } = this.state;

    return (
      <div className={instanceDetails()}>
        <div className={instanceDetails('actions')}>{this.renderActions()}</div>
        <div className={instanceDetails('details')}>
          {this.renderInstanceDetails()}
          {this.renderLeadDetails()}
          {this.renderFeatures()}
        </div>
        {pdf.id ? <PDFViewer id={pdf.id} signed={pdf.signed} onClose={this.handleClosePDFModal} /> : null}
        {uploadPDF ? (
          <FileUpload
            onSuccess={this.handleSuccessUploadPDF}
            onClose={this.handleCloseUploadPDFModal}
            api={api.instances.uploadSignedPDF(uploadPDF)}
            caption="Upload signed PDF"
            mimeTypes={['application/pdf']}
          />
        ) : null}
        {editing ? <InstanceEdit instance={instance} onClose={this.handleCloseEditingModal} /> : null}
      </div>
    );
  }
}

InstanceDetails.defaultProps = {
  isLegaleSignAvailable: false,
  isSendToProductionAvailable: false
};

InstanceDetails.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  makeLeadPrimary: PropTypes.func.isRequired,
  signedPDFUploaded: PropTypes.func.isRequired,
  isLegaleSignAvailable: PropTypes.bool,
  isSendToProductionAvailable: PropTypes.bool
};

const mapStateToProps = state => ({
  isLegaleSignAvailable: authSelectors.selectUserIsLegaleSignAvailable(state),
  isSendToProductionAvailable: authSelectors.selectUserRequestsShowSendToProduction(state)
});

const mapDispatchToProps = {
  makeLeadPrimary: instancesActions.makeLeadPrimary,
  signedPDFUploaded: instancesActions.signedPDFUploaded
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceDetails);
