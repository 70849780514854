import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { Button, Toast, ToastBody, ToastHeader } from '../../Atoms';
import { TYPE_NOTIFICATION } from '../../../utils/propTypes';
import './Notification.scss';

const className = b.with('notification');

const TRANSITION_TIMEOUT = 200;

const icons = {
  error: <i className="fa fa-fw fa-exclamation text-danger" />,
  success: <i className="fa fa-fw fa-check text-success" />,
  info: <i className="fa fa-fw fa-info text-primary" />
};

const header = {
  error: 'Error'
};

const Notification = ({ notification, onHide }) => {
  const [open, setOpen] = useState(true);
  const { id, message, details, type, timeout = 2000 } = notification;

  const hide = useCallback(() => {
    setOpen(false);

    setTimeout(() => onHide(id), TRANSITION_TIMEOUT);
  }, [id, onHide]);

  useEffect(() => {
    const timer = setTimeout(hide, timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [timeout, hide]);

  return (
    <Toast isOpen={open} className={className({ type })} transition={{ timeout: TRANSITION_TIMEOUT }}>
      <ToastHeader icon={icons[type]} className={className('header', { type })}>
        {header[type]}
        <Button close className={className('button')} title="Hide notification" onClick={hide}>
          <i className="fa fa-fw fa-close" />
        </Button>
      </ToastHeader>
      <ToastBody className={className('body', { type })}>
        <span className="d-block">{message}</span>
        {details ? <small>{details}</small> : null}
      </ToastBody>
    </Toast>
  );
};

Notification.propTypes = {
  notification: TYPE_NOTIFICATION.isRequired,
  onHide: PropTypes.func.isRequired
};

export default Notification;
