import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MapInteraction } from 'react-map-interaction';
import GraphCanvas from './GraphCanvas';

import './Graph.scss';

const useDrag = updateHandler => {
  const [draggedNode, setDraggedNode] = useState('');

  const [panDisabled, setPanDisabled] = useState(false);
  const dragStartHandler = useCallback(nodeId => {
    setDraggedNode(nodeId);
    setPanDisabled(true);
  }, []);
  const dragStopHandler = useCallback(
    (nodeId, dX, dY, initialGraphX, initialGraphY) => {
      if (nodeId && dX && dY) {
        updateHandler({ nodeId, dX, dY, initialGraphX, initialGraphY });
      }
      setPanDisabled(false);
      setDraggedNode('');
    },
    [updateHandler]
  );

  /* For future, we might do something while dragging the node */

  const dragHandler = useCallback(() => null, []);

  return { dragStopHandler, dragStartHandler, dragHandler, panDisabled, draggedNode };
};

const Graph = ({ onUpdateNode, ...props }) => {
  const [selectedNodes, setSelectedNodes] = useState({});
  const [value, setValue] = useState({ scale: 1, translation: { x: 0, y: 0 } });
  const { dragStopHandler, dragStartHandler, dragHandler, panDisabled, draggedNode } = useDrag(onUpdateNode);

  /*

  Groundwork to enable multiple nodes to be selected. It's an object so multiple ids
  could be in it. Currently it enables nothing but in the future it might be useful

  Problem with selecting multiple objects is that selectNodeHandler needs to
  know currently selected objects in order to switch only one off/on. This will trigger
  selectNodeHandler to update after each selection which will trigger updates to
  all nodes, since everyone will have a new handler for selecting itself. So currently
  not yet implemented

*/
  const selectNodeHandler = useCallback(e => {
    const { id } = e.currentTarget.dataset;

    setSelectedNodes({ [id]: id });
  }, []);
  const deselectNodeHandler = useCallback(() => {
    setSelectedNodes({});
  }, []);

  return (
    <MapInteraction disablePan={panDisabled} value={value} onChange={setValue}>
      {({ translation: mapTranslation, scale: mapScale }) => (
        <GraphCanvas
          translation={mapTranslation}
          scale={mapScale}
          {...props}
          onSelectNode={selectNodeHandler}
          onDeselectNode={deselectNodeHandler}
          selectedNodes={selectedNodes}
          onMapSet={setValue}
          onDragStop={dragStopHandler}
          onDrag={dragHandler}
          onDragStart={dragStartHandler}
          draggedNode={draggedNode}
        />
      )}
    </MapInteraction>
  );
};

Graph.propTypes = {
  onUpdateNode: PropTypes.func.isRequired
};

export default Graph;
