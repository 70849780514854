import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '../../Atoms';

const Expander = ({ isExpanded, original }) => {
  if (original.list.length < 2) return null;

  return (
    <Badge color="primary">
      {original.list.length} <i className={`fa ${isExpanded ? 'fa-caret-down' : 'fa-caret-right'}`} />
    </Badge>
  );
};

Expander.propTypes = {
  isExpanded: PropTypes.shape({}),
  original: PropTypes.shape({ list: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired
};
Expander.defaultProps = {
  isExpanded: undefined
};

export default Expander;
