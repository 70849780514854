import React from 'react';
import filesize from 'filesize';
import { ListGroup, ListGroupItem } from '../../Atoms';

const AssetNameGroup = assetClickHandler => row =>
  (
    <ListGroup flush>
      {row.original.list.map(asset => {
        const { _id, filename, length, uploadDate } = asset;

        return (
          <ListGroupItem
            action
            type="button"
            tag="button"
            onClick={assetClickHandler}
            data-asset-id={_id}
            key={_id}
            color="info"
          >
            {filename}{' '}
            <small className="text-muted">
              ({uploadDate.toLocaleString()} / {filesize(length)})
            </small>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );

export default AssetNameGroup;
