import { createSelector } from 'reselect';
import * as seedsSelectors from '../seeds/seedsSelectors';
import makeAssetUrl from '../../utils/api/makeAssetUrl';
import ASSET_TYPES from './assetTypes';

const filterListById = filterProjectId => asset => {
  const metadata = asset.metadata || {}; // might be null as well, cannot destructure
  const { projectId = '' } = metadata;

  // matching projectId
  return projectId === filterProjectId;
};

const makeAssetSelectors = assetType => {
  const getAssetUrl = makeAssetUrl(assetType);

  const selectAssets = state => state[assetType].list;
  const selectList = createSelector([selectAssets], assets => Object.values(assets));
  const selectAreAssetsLoading = state => state[assetType].request;
  const selectAssetsParents = state => state[assetType].parents;
  const selectAssetsParentsRequesting = state => Boolean(state[assetType].parentsRequest);

  const selectListByProjectId = createSelector([selectList, (_, projectId) => projectId], (list, projectId) =>
    projectId ? list.filter(filterListById(projectId)) : []
  );

  const selectListByProjectIdForForm = createSelector([selectListByProjectId], list =>
    list.map(({ filename, _id, metadata }) => {
      const assetProjectId = metadata ? metadata.projectId : '';

      return { name: filename, url: getAssetUrl({ fileName: _id, projectId: assetProjectId }) };
    })
  );

  const selectListBySeed = createSelector([selectList, seedsSelectors.selectSeedMemoized], (list, seed) => {
    if (seed) {
      const { projectId } = seed;

      return list.filter(filterListById(projectId));
    }

    return [];
  });

  return {
    selectAssets,
    selectList,
    selectAreAssetsLoading,
    selectAssetsParents,
    selectAssetsParentsRequesting,
    selectListBySeed,
    selectListByProjectId,
    selectListByProjectIdForForm
  };
};

export const images = makeAssetSelectors(ASSET_TYPES.IMAGES);
export const textures = makeAssetSelectors(ASSET_TYPES.TEXTURES);
export const models = makeAssetSelectors(ASSET_TYPES.MODELS);
