import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import { usePartList } from '../../../utils/hooks';
import { partsSelectors } from '../../../modules/parts';
import { TYPE_SEED } from '../../../utils/propTypes';
import Loader from '../../Loader/Loader';
import Form from '../../Form/Form';
import { useUpdateSeedPrices } from '../../../store/seed';
import PriceList from './PriceList';

const subscription = { submitting: true, pristinge: true };

const PriceEditor = ({ seed }) => {
  const { request } = usePartList(seed._id);
  const { updateSeedPrices } = useUpdateSeedPrices();

  const pricesList = useSelector(state => partsSelectors.selectAllPriceCodes(state, seed._id));

  const explodePrices = useCallback(
    values =>
      values.reduce((result, item) => {
        Object.keys(item.schemes).forEach(itemKey => {
          result.push({ code: item.code, scheme: itemKey, price: item.schemes[itemKey] });
        });

        return result;
      }, []),
    []
  );

  const onSubmit = useCallback(
    value => {
      updateSeedPrices({ _id: seed._id, prices: explodePrices(value.prices) });
    },
    [updateSeedPrices, explodePrices, seed._id]
  );

  return (
    <Loader loading={request}>
      <Form name="Prices" onSubmit={onSubmit} initialValues={{ prices: pricesList }}>
        <p>Each cell corresponds to a price code and pricing scheme used in the model.</p>
        <FieldArray name="prices" subscription={subscription} seed={seed} component={PriceList} />
        <br />
      </Form>
    </Loader>
  );
};

PriceEditor.propTypes = {
  seed: TYPE_SEED.isRequired
};

export default PriceEditor;
