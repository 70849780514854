import { useUpdateSeed } from '../../store/seed';
import SeedSettings from './Seed/SeedSettings';
import SeedPageWrapper from './SeedPageWrapper';

const SeedSettingsPage = () => {
  const { updateSeedMainScope } = useUpdateSeed();

  return <SeedPageWrapper>{seed => <SeedSettings seed={seed} onSubmit={updateSeedMainScope} />}</SeedPageWrapper>;
};

export default SeedSettingsPage;
