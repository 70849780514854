import { useState, useCallback, useMemo } from 'react';
import { ProjectType } from 'types';
import { get, set, cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Alert, Modal, ModalHeader, ModalBody, Container, Row, Col } from 'reactstrap';
import { FormField, Form, Box } from '../Atoms';
import { makeSuggestions } from '../../utils';
import { useFetchGlobalSettings } from '../../modules/settings/settingsHooks';
import { useSeed } from '../../store/seed';
import { useSwitch, useAssetsActions, usePartList } from '../../utils/hooks';
import { useUpdateProject } from '../../store/project';
import { partsSelectors } from '../../modules/parts';
import { FIELDS, ContactFormFieldsWrapper } from './SettingsFormComponents/ContactForm';
import { MetricIconWrapper, MetricUnitWrapper, MetricCaveatWrapper } from './SettingsFormComponents/Metrics';
import { Actions } from './SettingsFormComponents/Actions';
import { PriceSchemes } from './SettingsFormComponents/PriceSchemes';
import {
  State,
  GroupLink,
  SettingsGroup,
  ProjectSettingsDashboardProps,
  Image,
  PanImage,
  IntroSettings,
  InfoPanel,
  ExtraInfoCollection,
  OptionStatus,
  TranslationDefinition,
  Translation,
  PpsqmLayer,
  SelectOptions,
  ContactFormFields,
  Metric
} from './types';

// icons from Configurator icon module
const ICONS = [
  'alert',
  'format-list-numbered',
  'cash-multiple',
  'chevron-up',
  'chevron-double-up',
  'chevron-down',
  'chevron-double-down',
  'chevron-right',
  'chevron-double-right',
  'chevron-left',
  'chevron-double-left',
  'chevron-down-up',
  'chevron-up-down',
  'close',
  'cogs',
  'cube',
  'floor-plan',
  'fullscreen',
  'fullscreen-exit',
  'loading',
  'home',
  'home-outline',
  'menu',
  'minus-circle',
  'open-in-new',
  'plus-circle',
  'target',
  'menu-dots',
  'menu-down',
  'menu-right',
  'check',
  'check-bold',
  'save',
  'trash',
  'warning',
  'open-with',
  'content-copy',
  'cancel',
  'cursor-move',
  'rotate-left',
  'undo',
  'bedroom',

  // Share dialog icons
  'facebook',
  'messenger',
  'twitter',
  'whatsapp',
  'mail',
  'web-share',
  'unfold-less-horizontal',
  'unfold-more-horizontal',
  'co2'
];
const iconOptions = [
  ...ICONS.map(icon => ({ value: icon, label: icon })),
  { value: '_custom', label: 'Custom - choose image from assets' }
];

const ProjectSettingsDashboard = ({ project }: ProjectSettingsDashboardProps) => {
  const { updateProject } = useUpdateProject();
  const { isOpen, toggle } = useSwitch(false);
  const [activeLink, setActiveLink] = useState<GroupLink>();
  const globalSettings = useFetchGlobalSettings();
  const { seed: publicSeed } = useSeed(project.publicSeedId);

  const shadowValues = useMemo(() => {
    return {
      list: [
        {
          name: 'master',
          value: {
            settings: globalSettings.raw
          }
        },
        {
          name: 'current'
        },
        {
          name: 'public seed',
          value: {
            settings: publicSeed?.settings
          }
        }
      ]
    };
  }, [globalSettings.raw, publicSeed]);

  const cameraViewSuggestions = useSelector(state =>
    partsSelectors.selectCameraViewsForSuggestions(state, project.publicSeedId)
  );

  usePartList(project.publicSeedId);

  const contactFormFieldSettings = get(project, 'settings.contactForm.fields', []).map(
    (setting: ContactFormFields) => setting.type
  );

  const [state, setState] = useState<State>({ contactFormType: contactFormFieldSettings, metricState: [] });

  const handleUpdate = useCallback(
    formValue => {
      updateProject({ ...project, ...formValue });
    },
    [project, updateProject]
  );

  const contactFormTypeChangeHandler = (type: SelectOptions | null, index?: number) => {
    if (!type || index === undefined) {
      return;
    }
    set(state, `contactFormType[${index}]`, type?.value);
    setState(cloneDeep(state));
  };

  const metricCaveatStateChangeHandler = (show: boolean, index?: number) => {
    set(state, `metricState[${index}].caveat`, show);

    setState(cloneDeep(state));
  };
  const metricUnitStateChangeHandler = (show: boolean, index?: number) => {
    set(state, `metricState[${index}].unit`, show);

    setState(cloneDeep(state));
  };
  const metricIconStateChangeHandler = (value: { value: string; label: string } | null, index?: number) => {
    const show = value?.value === '_custom';

    set(state, `metricState[${index}].icon`, show);
    setState(cloneDeep(state));
  };

  const {
    projectImages,
    projectTextures,
    uploadImage,
    uploadTexture,
    updateImagesAfterUpload,
    updateTexturesAfterUpload
  } = useAssetsActions(project._id);

  const settingsGroups: SettingsGroup[] = [
    {
      name: 'Content',
      links: [
        {
          title: 'Content settings',
          description: 'Title, intro, gallery, video',
          icon: 'mdiImageText',
          inactive: p => get(p, 'settings.interface.hideGallery') === undefined && !get(p, 'settings.intro', []).length,
          component: (_, projectImagesList) => (
            <>
              <FormField.Toggle<ProjectType> name="settings.interface.hideGallery" label="Hide gallery" />
              <FormField.ArrayWrapper<ProjectType>
                headerFormat={value => get(value, 'title', 'untitled')}
                name="settings.intro"
                label="Intro settings"
                twoColumns
              >
                <FormField.ControlledSelect<IntroSettings, ProjectType>
                  name="language"
                  label="language"
                  description="Select language from previously defined interface languages list"
                  sourceField="settings.translations"
                  getOptionValue={(option?: any) => `${option?.name}`}
                  getOptionLabel={(option?: any) => `${option?.name}`}
                  column="left"
                />
                <FormField.Text<IntroSettings> name="title" label="Intro title" column="left" />
                <FormField.Markdown<IntroSettings> name="content" label="Intro content" column="right" />
                <FormField.ArrayWrapper<IntroSettings>
                  headerFormat={value => get(value, 'fileName', 'untitled')}
                  name="images"
                  label="Shown in gallery"
                  column="right"
                >
                  <FormField.Image<Image>
                    name="fileName"
                    label="images"
                    list={projectImagesList}
                    uploadImage={uploadImage}
                    onUploadSuccess={updateImagesAfterUpload}
                  />
                  <FormField.Toggle<PanImage> name="isPanorama" label="Panorama" />
                </FormField.ArrayWrapper>
              </FormField.ArrayWrapper>
            </>
          )
        },
        {
          title: 'Summary',
          description: 'Gallery, title, paragraph',
          icon: 'mdiTranslate',
          inactive: p => p.settings.summary === undefined,
          component: (_, projectImagesList) => (
            <>
              <FormField.Text<ProjectType> name="settings.summary.title" label="Summary title" description="" />
              <FormField.Textarea<ProjectType> name="settings.summary.text" label="Summary paragraph" description="" />
              <FormField.ArrayWrapper<ProjectType>
                headerFormat={value => get(value, 'fileName', 'untitled')}
                name="settings.summary.gallery"
                label="Shown in gallery"
              >
                <FormField.Image<Image>
                  name="fileName"
                  label="images"
                  list={projectImagesList}
                  uploadImage={uploadImage}
                  onUploadSuccess={updateImagesAfterUpload}
                />
              </FormField.ArrayWrapper>
            </>
          )
        },
        {
          title: 'Extra Info Collections',
          description: 'Used in UI',
          icon: 'mdiCardBulletedSettingsOutline',
          inactive: p => !get(p, 'settings.extraInfoCollections', []).length,
          component: (_, projectImagesList) => (
            <>
              <FormField.ArrayWrapper<ProjectType>
                headerFormat={value => get(value, 'name', 'untitled')}
                name="settings.extraInfoCollections"
                label="Extra info collections"
                description="These collections can be used for options' and 3D sprites' more info panels"
              >
                <FormField.Text<ExtraInfoCollection>
                  name="name"
                  label="Name"
                  description="Unique name for the collection"
                  required
                />
                <FormField.ArrayWrapper<ExtraInfoCollection>
                  headerFormat={value => get(value, 'name', 'untitled')}
                  name="infoPanels"
                  label="Image and description panels"
                  description="These will be shown in a gallery with descriptive texts"
                >
                  <FormField.Text<InfoPanel> name="name" label="Name" description="" />
                  <FormField.Code<InfoPanel> name="text" label="Content" description="" mode="html" />
                  <FormField.Text<InfoPanel> name="caption" label="Caption" description="" />

                  <FormField.Image<InfoPanel>
                    name="image.fileName"
                    label="Image"
                    description=""
                    list={projectImagesList}
                    uploadImage={uploadImage}
                    onUploadSuccess={updateImagesAfterUpload}
                  />

                  <FormField.Toggle<InfoPanel>
                    name="image.isPanorama"
                    label="Panorama"
                    description="Loads panorama viewer for equirectangular images."
                  />
                </FormField.ArrayWrapper>
                <FormField.Text<ExtraInfoCollection>
                  name="externalLink.label"
                  label="External link label"
                  description={
                    <span>
                      This is used in <strong>imagesFullscreenControls</strong>.
                    </span>
                  }
                />
                <FormField.Text<ExtraInfoCollection>
                  name="externalLink.url"
                  label="External link url"
                  description={
                    <span>
                      This is used in <strong>imagesFullscreenControls</strong>.
                    </span>
                  }
                />
              </FormField.ArrayWrapper>
            </>
          )
        },
        {
          title: 'Option Statuses',
          description: 'Disable, lock, inactive, hide price, hide area, etc.',
          icon: 'mdiListStatus',
          inactive: p => !get(p, 'settings.optionStatuses', []).length,
          component: () => (
            <>
              <FormField.ArrayWrapper<ProjectType>
                headerFormat={value => get(value, 'name', 'untitled')}
                name="settings.optionStatuses"
                label="Option statuses"
                description="Option statuses defined here can be used in option definitions and remote controls to change how options are shown in the Configurator"
                twoColumns
              >
                <FormField.Text<OptionStatus>
                  name="name"
                  label="Name"
                  description="Name which the status is referred by elsewhere. Must be unique"
                  column="right"
                />
                <FormField.Text<OptionStatus>
                  name="label"
                  label="Label"
                  description="Label which is displayed in Option and Remote Controls panel"
                  column="right"
                />
                <FormField.Text<OptionStatus>
                  name="description"
                  label="Description"
                  description="Additional hints"
                  column="right"
                />
                <FormField.Toggle<OptionStatus>
                  name="forceReselectState"
                  label="Force reselect"
                  description="Does this option force reselection of control if it's disabled"
                  column="left"
                />
                <FormField.Toggle<OptionStatus>
                  name="disabledState"
                  label="Disable"
                  description="Disable option and remove it from ui"
                  column="left"
                />
                <FormField.Toggle<OptionStatus>
                  name="lockedState"
                  label="Lock"
                  description="Locked options are visible, muted and not selectable"
                  column="left"
                />
                <FormField.Toggle<OptionStatus>
                  name="inactiveState"
                  label="Inactivate"
                  description="Inactive options are visible, muted but selectable"
                  column="left"
                />
                <FormField.Toggle<OptionStatus>
                  name="priceHiddenState"
                  label="Hide price"
                  description="Hide price of this option in Configurator"
                  column="left"
                />
                <FormField.Toggle<OptionStatus>
                  name="areaHiddenState"
                  label="Hide area"
                  description="Hide area of this option in Configurator"
                />
                <FormField.Toggle<OptionStatus>
                  name="optionHintHiddenState"
                  label="Hide option hint"
                  description="Hide hint of this option in Configurator"
                  column="left"
                />
              </FormField.ArrayWrapper>
            </>
          )
        }
      ]
    },
    {
      name: 'UI',
      links: [
        {
          title: 'Translations',
          description: 'For multilingual configurators',
          icon: 'mdiTranslate',
          inactive: p => !get(p, 'settings.translations', []).length,
          component: () => (
            <>
              <FormField.ArrayWrapper<ProjectType>
                headerFormat={value => get(value, 'name', 'untitled')}
                name="settings.translations"
                label="Translations"
                description="Here add various translations for different languages. Base language is English."
              >
                <FormField.Text<Translation> name="name" label="Language" description="Must be unique" required />
                <FormField.Toggle<Translation> name="disabled" label="Disable language" />
                <FormField.ArrayWrapper<Translation>
                  headerFormat={value => get(value, 'key', 'untitled')}
                  name="definitions"
                  label="Definitions"
                  twoColumns
                >
                  <FormField.Text<TranslationDefinition> name="key" label="Original" column="left" />
                  <FormField.Text<TranslationDefinition> name="value" label="Translation" column="right" />
                </FormField.ArrayWrapper>
              </FormField.ArrayWrapper>
            </>
          )
        },
        {
          title: 'Branding',
          description: 'Brand name, logo, links, favicon',
          icon: 'mdiTagTextOutline',
          inactive: p =>
            get(p, 'settings.interface.favicon') === undefined &&
            get(p, 'settings.interface.header') === undefined &&
            get(p, 'settings.interface.headerIcon') === undefined,
          component: (_, projectImagesList) => (
            <>
              <FormField.Image<ProjectType>
                name="settings.interface.favicon"
                label="Favicon"
                description="The optimal size is 32x32 pixels"
                list={projectImagesList}
                header="Select favicon"
                uploadImage={uploadImage}
                onUploadSuccess={updateImagesAfterUpload}
              />
              <FormField.Text<ProjectType> name="settings.interface.header" label="Header label" />
              <FormField.Image<ProjectType>
                name="settings.interface.headerIcon"
                label="Header icon"
                description="Preferably svg image to show as logo next to header"
                list={projectImagesList}
                header="Select header icon"
                uploadImage={uploadImage}
                onUploadSuccess={updateImagesAfterUpload}
              />
            </>
          )
        },
        {
          title: 'Theming',
          description: 'Custom CSS styling',
          icon: 'mdiPaletteOutline',
          inactive: p => !get(p, 'theme', null),
          component: () => (
            <FormField.Code<ProjectType> mode="css" description="Project theme" name="theme" label="Theme" />
          )
        }
      ]
    },
    {
      name: 'Pricing',
      links: [
        {
          title: 'Use Procecodes',
          description: 'Special variables to control the final price programmatically',
          icon: 'mdiCash',
          inactive: p => get(p, 'settings.price.usePriceCodes') === undefined,
          component: () => (
            <>
              <FormField.Toggle<ProjectType>
                name="settings.price.usePriceCodes"
                label="Use pricecodes"
                description="Enable using pricecodes for this seed. If defined here not in root part settings, option prices will not be remotely controllable as a side effect. Overrides root part settings. This will also enable pricing schemes"
              />
              <FormField.Toggle<ProjectType>
                name="settings.interface.summaryPriceHidden"
                label="Hide Total Price from Summary"
              />
            </>
          )
        },
        {
          title: 'Pricing Schemes',
          description: 'Pricing Schemes',
          icon: 'mdiCashMultiple',
          inactive: p =>
            get(p, 'settings.price.schemes') === undefined && get(p, 'settings.price.defaults') === undefined,
          component: () => <PriceSchemes />
        },
        {
          title: 'Per Layer Pricing',
          description: 'Special pricing mode',
          icon: 'mdiLayersTripleOutline',
          inactive: p => !get(p, 'settings.model.ppsqmLayers', []).length,
          component: () => (
            <>
              <FormField.ArrayWrapper<ProjectType>
                name="settings.model.ppsqmLayers"
                label="Price per sq unit layers"
                description="List of layer names to calculate price per square unit from"
                headerFormat={value => get(value, 'layer', 'untitled')}
              >
                <FormField.Text<PpsqmLayer> name="layer" label="Layer name" required />
                <FormField.Number<PpsqmLayer> name="ppsqm" label="Price" description="Price per square unit" />
                <FormField.Text<PpsqmLayer>
                  name="priceCode_psq"
                  label="Pricecode"
                  description="Price code per square unit"
                />
              </FormField.ArrayWrapper>
            </>
          )
        }
      ]
    },
    {
      name: 'User flow',
      links: [
        {
          title: 'User Flow Settings',
          description: 'Control how users navigate inside the configurator',
          icon: 'mdiTransitConnectionVariant',
          inactive: p =>
            get(p, 'settings.interface.aboutTabHidden') === undefined &&
            get(p, 'settings.interface.reviewTabHidden') === undefined &&
            get(p, 'settings.interface.summaryTabHidden') === undefined,
          component: () => (
            <>
              <FormField.Toggle<ProjectType>
                useShadowValue
                name="settings.interface.aboutTabHidden"
                label="Hide About page"
              />
              <FormField.Toggle<ProjectType>
                useShadowValue
                name="settings.interface.reviewTabHidden"
                label="Hide Review step"
              />
              <FormField.Toggle<ProjectType>
                useShadowValue
                name="settings.interface.summaryTabHidden"
                label="Hide Summary page"
              />
            </>
          )
        },
        {
          title: 'Saving',
          description: 'Require email to save anonymous designs',
          icon: 'mdiContentSave',
          inactive: p => get(p, 'settings.partner.requireEmailToSaveOprhanInstances', false),
          component: () => (
            <FormField.Toggle<ProjectType>
              label="Required"
              description="Require email to save anonymous designs"
              name="settings.partner.requireEmailToSaveOprhanInstances"
            />
          )
        },
        {
          title: 'Actions',
          description: 'Control what users can do inside the configurator',
          icon: 'mdiGestureTap',
          component: () => <Actions seedId={project.publicSeedId} />
        },
        {
          title: 'Contact Form',
          description: 'Form settings and fields configuration',
          icon: 'mdiFormTextbox',
          inactive: p => !get(p, 'settings.contactForm', null),
          component: (pageState?: State) => (
            <Container>
              <Row>
                <Col>
                  <FormField.Text<ProjectType>
                    name="settings.contactForm.title"
                    label="Contact form title"
                    description=""
                  />
                  <FormField.Text<ProjectType>
                    name="settings.contactForm.titleContacted"
                    label="Contact form title (contacted)"
                    description="Title to show if client has already contacted"
                  />
                  <FormField.Text<ProjectType> name="settings.contactForm.subtitle" label="Subtitle" />
                  <FormField.Text<ProjectType>
                    name="settings.contactForm.consentMessage"
                    label="Contact form consent message"
                    description=""
                  />
                  <FormField.Text<ProjectType>
                    name="settings.contactForm.requestReceiverNotice"
                    label="Notice about request receiver"
                  />
                  <FormField.Toggle<ProjectType>
                    name="settings.contactForm.sendPdf"
                    label="Send an email with a PDF file to clients instead of getting in contact"
                  />
                </Col>

                <Col>
                  <FormField.ArrayWrapper<ProjectType>
                    name="settings.contactForm.fields"
                    label="Fields"
                    headerFormat={value => get(value, 'name', 'untitled')}
                  >
                    <FormField.Select<ContactFormFields>
                      name="type"
                      label="Type"
                      description="Choose field type from the list"
                      options={FIELDS}
                      required
                      onChange={contactFormTypeChangeHandler}
                    />

                    <ContactFormFieldsWrapper state={pageState} />
                  </FormField.ArrayWrapper>
                </Col>
              </Row>
            </Container>
          )
        }
      ]
    },
    {
      name: 'Publishing',
      links: [
        {
          title: 'Sharing & SEO',
          description: 'Favicon, description, share image, etc.',
          icon: 'mdiShareVariant',
          inactive: p => get(p, 'settings.sharing') === undefined,
          component: (_, projectImagesList) => {
            return (
              <>
                <FormField.Image<ProjectType>
                  name="settings.sharing.image"
                  label="Share image"
                  description="Default image to show when sharing"
                  list={projectImagesList}
                  uploadImage={uploadImage}
                  onUploadSuccess={updateImagesAfterUpload}
                />
                <FormField.Text<ProjectType>
                  name="settings.sharing.title"
                  label="Share title"
                  description="Default title to show when sharing"
                />
                <FormField.Text<ProjectType>
                  name="settings.sharing.description"
                  label="Share description"
                  description="Default text to show when sharing"
                />
                <FormField.Text<ProjectType>
                  name="settings.sharing.twitter"
                  label="Twitter share description"
                  description="Default text to show when sharing to Twitter"
                />
                <FormField.Select<ProjectType>
                  name="settings.sharing.view"
                  options={cameraViewSuggestions}
                  label="Share view"
                  description="View that is rendered to the snapshot image"
                />
              </>
            );
          }
        },
        {
          title: 'PDF',
          description: 'Enable exportitng configuration as a file',
          icon: 'mdiFilePdfBox',
          inactive: p => get(p, 'settings.pdf') === undefined,
          component: () => (
            <>
              <FormField.Text<ProjectType> name="settings.pdf.caption" label="Pdf caption" description="" />
              <FormField.Text<ProjectType> name="settings.pdf.partnerName" label="Pdf partner name" description="" />
              <FormField.Text<ProjectType>
                name="settings.pdf.development"
                label="Pdf development name"
                description=""
              />
              <FormField.ArrayWrapper<ProjectType>
                name="settings.pdf.address"
                component={<FormField.Text<ProjectType> name="settings.pdf.address" label="" />}
                label="Pdf address"
                description="Add as many lines as needed"
              />
              <FormField.Toggle<ProjectType>
                name="settings.pdf.showNotes"
                label="Display notes in pdf"
                description=""
              />
              <FormField.Toggle<ProjectType>
                name="settings.pdf.showFooter"
                label="Display footer in pdf"
                description=""
              />
              <FormField.ArrayWrapper<ProjectType>
                name="settings.pdf.instruction"
                component={<FormField.Text<ProjectType> name="settings.pdf.instruction" label="" />}
                label="Instructions"
                description=""
              />
              <FormField.Text<ProjectType>
                name="settings.pdf.customTotal"
                label="Custom total"
                description="Custom string for 'total' in pdf"
              />
              <FormField.Text<ProjectType>
                name="settings.pdf.customNotes"
                label="Custom notes"
                description="Custom notes to add to pdf"
              />
            </>
          )
        },
        {
          title: 'Cookies',
          description: 'Cookie consent dialog and link to privacy policy',
          icon: 'mdiCookieOutline',
          inactive: p => get(p, 'settings.cookies') === undefined,
          component: () => (
            <>
              <FormField.Text<ProjectType>
                name="settings.cookies.header"
                label="Cookie consent dialog header"
                description=""
              />
              <FormField.Text<ProjectType>
                name="settings.cookies.message"
                label="Cookie consent dialog message"
                description=""
              />
              <FormField.Text<ProjectType>
                name="settings.cookies.policyLink.text"
                label="Cookie consent policy link text"
                description=""
              />
              <FormField.Text<ProjectType>
                name="settings.cookies.policyLink.link"
                label="Cookie consent policy link label"
                description=""
              />
              <FormField.Text<ProjectType>
                name="settings.cookies.policyLink.url"
                label="Cookie consent policy link url"
                description=""
              />
            </>
          )
        },
        {
          title: 'Embedding',
          description: 'Allowed domains',
          icon: 'mdiCodeTags',
          inactive: p => !get(p, 'allowedDomains', []).length,
          component: () => (
            <FormField.MultiSelect<ProjectType, { label: string; value: string }>
              creatable
              name="allowedDomains"
              label="Allowed domains"
              description="List of domains where embedding is allowed"
              options={[]}
              getNewOptionData={(value: string) => {
                return { label: value, value };
              }}
            />
          )
        }
      ]
    },
    {
      name: 'Model',
      links: [
        {
          title: 'Model Settings',
          description: 'Metric or imperial units, max instance conut, etc.',
          icon: 'mdiCubeOutline',
          inactive: p =>
            get(p, 'settings.model.forceFetchingAssets') === undefined &&
            get(p, 'settings.model.maxInstanceCount') === undefined &&
            get(p, 'settings.model.modelUnits') === undefined,
          component: () => (
            <>
              <FormField.Toggle<ProjectType>
                name="settings.model.forceFetchingAssets"
                label="Force fetching assets"
                description={
                  <span>
                    Will use <strong>updatedAt</strong> field when fetching assets to ignore old cache
                  </span>
                }
              />
              <FormField.Number<ProjectType> name="settings.model.maxInstanceCount" label="Max instance count" />
              <FormField.Select<ProjectType>
                name="settings.model.modelUnits"
                options={makeSuggestions(['metric', 'imperial'])}
                label="Model units"
                description=""
              />
            </>
          )
        },
        {
          title: 'Automatic Area Calculation',
          description: 'Net area, area unit and caveat',
          icon: 'mdiFloorPlan',
          inactive: p =>
            get(p, 'settings.model.netAreaLayers') === undefined &&
            get(p, 'settings.model.areaUnit') === undefined &&
            get(p, 'settings.model.netAreaCaveat') === undefined,
          component: () => (
            <>
              <Alert color="info">
                In order to show <b>Net Area</b> in <b>Summary</b> both layers and unit have to be set
              </Alert>
              <FormField.ArrayWrapper<ProjectType>
                component={<FormField.Text<ProjectType> name="settings.model.netAreaLayers" label="" />}
                name="settings.model.netAreaLayers"
                label="Net area layers"
                description="List of layer names to get geometry for area calculations from. Both Layers and Unit has to be set in order to show Net area"
              />
              <FormField.Select<ProjectType>
                name="settings.model.areaUnit"
                label="Area unit"
                description="If this is not set, area is not shown"
                options={[
                  {
                    value: 'm2',
                    label: 'Square meters (m2)'
                  },
                  {
                    value: 'm²',
                    label: 'Square meters (m²)'
                  },
                  {
                    value: 'sq.m',
                    label: 'Square meters (sq.m)'
                  },
                  {
                    value: 'sq.ft',
                    label: 'Square feet (sq.ft)'
                  },
                  {
                    value: 'ft²',
                    label: 'Square feet (ft²)'
                  }
                ]}
              />
              <FormField.ArrayWrapper<ProjectType>
                component={<FormField.Text<ProjectType> name="settings.model.netAreaCaveat" label="" />}
                name="settings.model.netAreaCaveat"
                label="Net area Caveat"
                description="Paragraphs to show additional information about net area"
              />
            </>
          )
        },
        {
          title: 'Custom Metrics',
          description: 'Display and conut any non-standard metrics',
          icon: 'mdiCounter',
          inactive: p => get(p, 'settings.metrics') === undefined,
          component: (pageState, projectImagesList) => {
            return (
              <>
                <h3>Metrics</h3>
                <p>Area, price and bedrooms are counted by default, add items here to enable counting.</p>
                <FormField.ArrayWrapper<ProjectType> name="settings.metrics" label="Metrics" twoColumns>
                  <FormField.Text<Metric> name="name" label="Name" required description="Unique name" column="left" />
                  <FormField.Text<Metric>
                    name="displayName"
                    label="Label"
                    description="Caption shown under value"
                    column="left"
                  />
                  <FormField.Select<Metric>
                    name="unit"
                    label="Unit"
                    description="Metrics unit like m², cm, kg. You can also define a custom one"
                    options={[
                      { value: 'm', label: 'm' },
                      { value: 'kg', label: 'kg' },
                      { value: 'mm', label: 'mm' },
                      { value: 'cm', label: 'cm' },
                      { value: 'm²', label: 'm²' },
                      { value: 'in', label: 'in' },
                      { value: 'ft', label: 'ft' },
                      { value: 'ft²', label: 'ft²' },
                      { value: 's', label: 's' },
                      { value: '€', label: '€' },
                      { value: '£', label: '£' },
                      { value: '$', label: '$' }
                    ]}
                    onChange={(value, index) => metricUnitStateChangeHandler(!!value, index)}
                    column="left"
                  />
                  <MetricUnitWrapper column="left" state={pageState} />
                  <FormField.Textarea<Metric>
                    name="caveat"
                    label="Caveat"
                    description="Additional information about the metric"
                    onChange={(e, index) => {
                      metricCaveatStateChangeHandler(!!e.target.value, index);
                    }}
                    column="right"
                  />
                  <MetricCaveatWrapper column="right" state={pageState} />
                  <FormField.Text<Metric>
                    name="format"
                    label="Format"
                    description="Use hashes to format numbers like ####.##"
                    column="right"
                  />
                  <FormField.Select<Metric>
                    name="icon"
                    label="Icon"
                    options={iconOptions}
                    onChange={(value, index) => metricIconStateChangeHandler(value, index)}
                    column="right"
                  />
                  <MetricIconWrapper
                    uploadImage={uploadImage}
                    updateImagesAfterUpload={updateImagesAfterUpload}
                    state={pageState}
                    projectImages={projectImagesList}
                    column="right"
                  />
                </FormField.ArrayWrapper>
              </>
            );
          }
        },
        {
          title: 'Skybox',
          description: 'Ehable skybox and setup textures',
          icon: 'mdiPanorama',
          inactive: p =>
            get(p, 'settings.skybox.enabled') === undefined &&
            get(p, 'settings.skybox.useAsEnvMap') === undefined &&
            get(p, 'settings.skybox.equirectangular') === undefined,
          component: (_1, _2, projectTextureList) => (
            <>
              <FormField.Toggle<ProjectType>
                name="settings.skybox.enabled"
                label="Enable skybox"
                description="Will show background as defined by skybox texture"
              />
              <FormField.Toggle<ProjectType>
                name="settings.skybox.useAsEnvMap"
                label="Use skybox textures as environment map"
                description="Will enable reflections based on skybox texture"
              />
              <FormField.Image<ProjectType>
                name="settings.skybox.equirectangular.Texture.fileName"
                label="Skybox"
                description="Equirectangular texture for skybox"
                list={projectTextureList}
                header="Select texture"
                uploadImage={uploadTexture}
                onUploadSuccess={updateTexturesAfterUpload}
              />
            </>
          )
        },
        {
          title: 'Part Animation',
          description: 'Enable part animation',
          icon: 'mdiPlay',
          inactive: p => get(p, 'settings.partAnimation.enabled') === undefined,
          component: () => (
            <FormField.Toggle<ProjectType>
              name="settings.partAnimation.enabled"
              label="Enable part animation"
              description="Will animate changing parts"
            />
          )
        }
      ]
    }
  ];

  return (
    <>
      {settingsGroups.map((group, index) => (
        <div key={group.name} className="mt-3 mb-1">
          <h3 className="ml-4">{group.name}</h3>
          <div className="d-flex flex-wrap pb-2 gap-3">
            {group.links.map((link: GroupLink) => (
              <div key={link.title}>
                <Box
                  onClick={() => {
                    setActiveLink(link);
                    toggle();
                  }}
                  color="#2D9CDB"
                  icon={link.icon}
                  title={link.title}
                  description={link.description}
                  inactive={link.inactive ? link.inactive(project) : true}
                />
              </div>
            ))}
          </div>
        </div>
      ))}

      <Modal size="xl" isOpen={isOpen}>
        <ModalHeader toggle={toggle}>{activeLink?.title}</ModalHeader>
        <ModalBody>
          <Form<ProjectType>
            defaultValues={project}
            onCancel={toggle}
            shadowValues={shadowValues.list}
            onSubmit={formValues => {
              handleUpdate(formValues);
              toggle();
            }}
          >
            {activeLink?.component && activeLink.component(state, projectImages, projectTextures)}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProjectSettingsDashboard;
