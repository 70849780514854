import { useController } from 'react-hook-form';
import RSelect from 'react-select';
import { DefaultOptionType, FieldType, SelectProps } from '../../types';
import FieldWrapper from '../FieldWrapper';
import { getConvertedRegisterOptions, getThemeForSelectComponent } from '../helpers';

const Select = <T, K = DefaultOptionType>({
  name,
  description,
  label,
  required,
  options = [],
  getOptionValue = (option?: any) => `${option?.value}`,
  getOptionLabel = (option?: any) => `${option?.label}`,
  disabled,
  onChange,
  index
}: SelectProps<T, K>) => {
  const { field, fieldState } = useController<T>({ name, rules: getConvertedRegisterOptions({ required }) });

  const fieldError: any = fieldState.error;

  const value = field.value !== undefined ? options.find(option => getOptionValue(option) === field.value) : null;

  return (
    <FieldWrapper
      type={FieldType.SELECT}
      name={name}
      label={label}
      description={description}
      required={required}
      error={fieldError?.message}
    >
      <RSelect<K>
        value={value}
        options={options}
        onChange={option => {
          // console.info('!!!');
          field.onChange(getOptionValue(option));
          onChange && onChange(option, index);
        }}
        onBlur={field.onBlur}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isDisabled={disabled}
        isClearable={!required}
        className={fieldState.invalid ? 'is-invalid' : ''}
        theme={theme => getThemeForSelectComponent(theme, fieldState.invalid)}
      />
    </FieldWrapper>
  );
};

export default Select;
