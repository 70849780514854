import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import FormSection from '../../Section';
import { ArrayField } from '../../types';
import ArrayRemoveButton from './ArrayRemoveButton';
import ArraySortableItemWrapper from './ArraySortableItemWrapper';
import ArrayContext from './ArrayContext';

interface ArrayItemProps<T> {
  item: ArrayField<T>;
  title: string;
  index: number;
  twoColumns?: boolean;
  singleComponentMode?: boolean;
}

const ArrayItem = <T,>({
  item,
  title,
  index,
  children,
  twoColumns,
  singleComponentMode
}: PropsWithChildren<ArrayItemProps<T>>) => {
  const { isSortable, isStatic, arrayName } = useContext(ArrayContext);

  const transformedChildren = useMemo(
    () =>
      React.Children.map(children, (child: any) => {
        const resultingName = singleComponentMode
          ? `${child.props.name}.${index}`
          : `${arrayName}.${index}.${child.props.name}`;

        return child?.props?.name
          ? React.createElement(child.type, {
              ...child.props,
              key: child.props.name,
              name: resultingName,
              index
            })
          : React.createElement(child.type, { ...child.props, index });
      }),
    [arrayName, children, index, singleComponentMode]
  );

  const RemoveButton = useMemo(() => (isStatic ? null : <ArrayRemoveButton index={index} />), [isStatic, index]);

  const getArrayItem = (listeners?: DraggableSyntheticListeners) => {
    if (singleComponentMode) {
      return <>{transformedChildren}</>;
    }

    return (
      <FormSection title={title} headerButton={RemoveButton} dragHandleListeners={listeners}>
        {twoColumns ? (
          <Container>
            <Row>
              <Col xs="12" md="6">
                {transformedChildren?.filter(child => child.props.column === 'left')}
              </Col>
              <Col xs="12" md="6">
                {transformedChildren?.filter(child => child.props.column === 'right')}
              </Col>
            </Row>
          </Container>
        ) : (
          <>{transformedChildren}</>
        )}
      </FormSection>
    );
  };

  return isSortable ? (
    <ArraySortableItemWrapper id={item.id}>{listeners => getArrayItem(listeners)}</ArraySortableItemWrapper>
  ) : (
    getArrayItem()
  );
};

export default ArrayItem;
