import React, { useCallback, useMemo, useState } from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import b from 'b_';
import { ButtonDropdown, Card, CardHeader, DropdownItem, DropdownMenu, DropdownToggle } from '../../Atoms';
import { TYPE_GRAPH_NODE, TYPE_NODE_ACTIONS } from '../../../utils/propTypes';
import { useSwitch } from '../../../utils/hooks';

const graph = b.with('graph');

const GraphNode = ({
  node,
  className,
  onSelect,
  isSelected,
  actions,
  color,
  NodeChildren,
  onDragStart,
  onDragStop,
  onDrag,
  scale,
  onDeselect,
  primaryActionIndex
}) => {
  const { x, y, label, id, pristine } = node;
  const { isOpen, toggle } = useSwitch();

  const toggleHandler = useCallback(
    e => {
      /* Propagation is stopped to not (de)select the node */
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const dropdownItems = useMemo(
    () =>
      actions.map(({ name, label: dropdownLabel, clickHandler, disabled = false }) => (
        <DropdownItem key={name} onClick={clickHandler} data-id={id} disabled={disabled}>
          {dropdownLabel}
        </DropdownItem>
      )),
    [actions, id]
  );

  /** Is used on node header doubleclick. Defaults to first action  */
  const nodePrimaryActionHandler = useMemo(() => {
    const primary = actions[primaryActionIndex];

    return primary?.clickHandler;
  }, [actions, primaryActionIndex]);

  const dropdown = useMemo(
    () =>
      actions.length ? (
        <ButtonDropdown isOpen={isOpen} toggle={toggleHandler} className="pull-right" size="sm">
          <DropdownToggle caret color="light" />
          <DropdownMenu persist={false}>{dropdownItems}</DropdownMenu>
        </ButtonDropdown>
      ) : null,
    [actions.length, dropdownItems, isOpen, toggleHandler]
  );

  const [dragStartState, setDragStartState] = useState({ x: 0, y: 0 });
  const dragStartHandler = useCallback(
    ({ clientX: startX, clientY: startY }) => {
      onDragStart(node.id);
      setDragStartState({ startX, startY });
    },
    [node.id, onDragStart]
  );

  const dragStopHandler = useCallback(
    ({ clientX: endX, clientY: endY }) => {
      const { startX, startY } = dragStartState;

      onDragStop(node.id, (endX - startX) / scale, (endY - startY) / scale, x, y);
    },
    [dragStartState, node.id, onDragStop, scale, x, y]
  );

  // memoized so it wouldn't create a new object each render
  const position = useMemo(() => ({ x, y }), [x, y]);

  /** Node color */
  const nodeColor = useMemo(() => {
    if (pristine) {
      return isSelected ? 'primary' : color;
    }

    return isSelected ? 'info' : 'warning';
  }, [color, isSelected, pristine]);

  return useMemo(() => {
    return (
      <Draggable onStart={dragStartHandler} scale={scale} position={position} onStop={dragStopHandler} onDrag={onDrag}>
        <Card className={className} color={nodeColor}>
          <CardHeader
            className={`p-2 ${graph('node-header')}`}
            data-id={node.id}
            onClick={isSelected ? onDeselect : onSelect}
            onDoubleClick={nodePrimaryActionHandler}
          >
            {label}
            {dropdown}
          </CardHeader>
          <NodeChildren nodeId={node.id} />
        </Card>
      </Draggable>
    );
  }, [
    className,
    dragStartHandler,
    dragStopHandler,
    dropdown,
    isSelected,
    label,
    node.id,
    nodeColor,
    nodePrimaryActionHandler,
    onDeselect,
    onDrag,
    onSelect,
    position,
    scale
  ]);
};

GraphNode.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  className: PropTypes.string,
  node: TYPE_GRAPH_NODE.isRequired,
  isSelected: PropTypes.bool.isRequired,
  actions: TYPE_NODE_ACTIONS.isRequired,
  primaryActionIndex: PropTypes.number,
  color: PropTypes.string,
  NodeChildren: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragStop: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  scale: PropTypes.number,
  isDragged: PropTypes.bool.isRequired
};
GraphNode.defaultProps = {
  className: '',
  color: 'light',
  scale: 1,
  primaryActionIndex: 0
};

export default GraphNode;
