import ASSET_TYPES from '../../modules/assets/assetTypes';
import api from './instance';

const CONTENT_TYPES = {
  JSON: 'application/json',
  FORM_DATA: 'application/x-www-form-urlencoded',
  PDF: 'application/pdf'
};

export function getContentType(type = CONTENT_TYPES.JSON) {
  return { 'Content-Type': type };
}

export const SERVER_ROUTE = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  FORGOT_PASSWORD: 'forgot-password',
  VERIFY_TOKEN: 'verify-token',
  VERIFY_INVITATION: 'verify-invitation',
  RESET_PASSWORD: 'reset-password',
  USER_INFO: 'user-info',
  LOGOUT: 'logout',
  LOGIN_FACEBOOK: 'facebook-login',
  LOGIN_GOOGLE: 'google-login',
  ACCEPT_TERMS_OF_USE: 'accept-terms',

  ROLES: 'roles',
  UPDATE_ROLE: id => `${SERVER_ROUTE.ROLES}/${id}`,
  DELETE_ROLE: id => `${SERVER_ROUTE.ROLES}/${id}`,

  PARTNERS: 'partners',
  UPDATE_PARTNER: id => `${SERVER_ROUTE.PARTNERS}/${id}`,
  DELETE_PARTNER: id => `${SERVER_ROUTE.PARTNERS}/${id}`,
  CREATE_API_KEY: id => `${SERVER_ROUTE.PARTNERS}/${id}/create-api-key`,

  TEMPLATES: partnerId => `${SERVER_ROUTE.PARTNERS}/${partnerId}/templates`,
  UPDATE_TEMPLATE: (partnerId, id) => `${SERVER_ROUTE.TEMPLATES(partnerId)}/${id}`,
  DELETE_TEMPLATE: (partnerId, id) => `${SERVER_ROUTE.TEMPLATES(partnerId)}/${id}`,

  GLOBAL_SETTINGS: 'global-settings',
  GLOBAL_THEME: 'global-theme',

  USERS: 'users',
  UPDATE_USER: id => `${SERVER_ROUTE.USERS}/${id}`,
  DELETE_USER: id => `${SERVER_ROUTE.USERS}/${id}`,

  INVITATIONS: 'invitations',
  UPDATE_INVITATION: id => `${SERVER_ROUTE.INVITATIONS}/${id}`,
  DELETE_INVITATION: id => `${SERVER_ROUTE.INVITATIONS}/${id}`,

  PROJECTS: 'projects',
  UPDATE_PROJECT: id => `${SERVER_ROUTE.PROJECTS}/${id}`,
  DELETE_PROJECT: id => `${SERVER_ROUTE.PROJECTS}/${id}`,

  SEEDS: 'seeds',
  UPDATE_SEED: id => `${SERVER_ROUTE.SEEDS}/${id}`,
  DELETE_SEED: id => `${SERVER_ROUTE.SEEDS}/${id}`,
  CLONE_SEED: id => `${SERVER_ROUTE.SEEDS}/${id}/clone`,
  UPDATE_PRICES: id => `${SERVER_ROUTE.SEEDS}/${id}/update-prices`,
  UPDATE_REMOTE_CONTROL: id => `${SERVER_ROUTE.SEEDS}/${id}/update-remote-control`,
  DELETE_REMOTE_CONTROL: id => `${SERVER_ROUTE.SEEDS}/${id}/delete-remote-control`,
  UPDATE_MATERIALS: id => `${SERVER_ROUTE.SEEDS}/${id}/update-materials`,
  UPDATE_REMOTE_SWITCHES: id => `${SERVER_ROUTE.SEEDS}/${id}/update-remote-switches`,

  PARTS: id => `${SERVER_ROUTE.SEEDS}/${id}/parts`,
  UPDATE_PART: (seedId, id) => `${SERVER_ROUTE.PARTS(seedId)}/${id}`,
  DELETE_PART: (seedId, id) => `${SERVER_ROUTE.PARTS(seedId)}/${id}`,

  PLOTS: 'plots',
  UPDATE_PLOT: id => `${SERVER_ROUTE.PLOTS}/${id}`,
  DELETE_PLOT: id => `${SERVER_ROUTE.PLOTS}/${id}`,

  INSTANCES: 'instances',
  INSTANCES_BY_PARTNER: partnerId => `${SERVER_ROUTE.PARTNERS}/${partnerId}/instances`,
  INSTANCES_BY_SEED: id => `${SERVER_ROUTE.SEEDS}/${id}/instances`,
  INSTANCE: id => `${SERVER_ROUTE.INSTANCES}/${id}`,
  UPDATE_INSTANCE: id => `${SERVER_ROUTE.INSTANCES}/${id}/update`,
  MARK_REQUEST_AS_READ: id => `${SERVER_ROUTE.INSTANCES}/${id}/mark-request-as-read`,
  MARK_REQUEST_AS_UNREAD: id => `${SERVER_ROUTE.INSTANCES}/${id}/mark-request-as-unread`,
  DELETE_REQUEST: id => `${SERVER_ROUTE.INSTANCES}/${id}/delete-request`,
  MAKE_LEAD_PRIMARY: id => `${SERVER_ROUTE.INSTANCES}/${id}/primary-lead`,
  INSTANCE_PDF: id => `${SERVER_ROUTE.INSTANCES}/${id}/pdf`,
  INSTANCE_HTML_PREVIEW: id => `${SERVER_ROUTE.INSTANCES}/${id}/html-preview`,
  INSTANCE_SIGNED_PDF: id => `${SERVER_ROUTE.INSTANCES}/${id}/signed-pdf`,
  INSTANCE_UPLOAD_SIGNED_PDF: id => `${SERVER_ROUTE.INSTANCES}/${id}/upload-signed-pdf`,
  SEND_TO_LEGALESIGN: id => `${SERVER_ROUTE.INSTANCES}/${id}/send-to-legalesign`,
  CHECK_LEGALESIGN_STATUS: id => `${SERVER_ROUTE.INSTANCES}/${id}/check-legalesign-status`,
  SEND_TO_PRODUCTION: id => `${SERVER_ROUTE.INSTANCES}/${id}/send-to-production`,
  CANCEL_ORDER: id => `${SERVER_ROUTE.INSTANCES}/${id}/cancel-order`,
  RUN_INTEGRATION: id => `${SERVER_ROUTE.INSTANCES}/${id}/run-integration`,

  STATISTICS: 'statistics',
  USER_STATISTICS: id => `${SERVER_ROUTE.STATISTICS}/${id}`,

  FACTORY_MAPS: 'factory-maps',
  UPDATE_FACTORY_MAP: id => `${SERVER_ROUTE.FACTORY_MAPS}/${id}`,
  DELETE_FACTORY_MAP: id => `${SERVER_ROUTE.FACTORY_MAPS}/${id}`,
  SET_PUBLISHED_FLAG: id => `${SERVER_ROUTE.FACTORY_MAPS}/${id}/publish`,

  IFRAME_SCRIPTS: 'iframe-scripts',
  UPDATE_IFRAME_SCRIPT: id => `${SERVER_ROUTE.IFRAME_SCRIPTS}/${id}`,
  DELETE_IFRAME_SCRIPT: id => `${SERVER_ROUTE.IFRAME_SCRIPTS}/${id}`,
  SET_LATEST_IFRAME_SCRIPT: id => `${SERVER_ROUTE.IFRAME_SCRIPTS}/${id}/set-latest`,

  MAPPED_INSTANCES: 'mapped-instances',
  GET_MAPPED_INSTANCE: id => `${SERVER_ROUTE.MAPPED_INSTANCES}/${id}`,
  MAPPED_INSTANCES_PREVIEW: 'mapped-instances-preview',
  UPDATE_MAPPED_INSTANCE: id => `${SERVER_ROUTE.MAPPED_INSTANCES}/${id}`,

  ASSETS: 'assets',
  ASSETS_SET_PROJECT: (assetType, id) => `${SERVER_ROUTE.ASSETS}/${assetType}/${id}/set-project`,
  ASSETS_GET_LIST: assetType => `${SERVER_ROUTE.ASSETS}/${assetType}/`,
  ASSETS_UPLOAD: assetType => `${SERVER_ROUTE.ASSETS}/${assetType}/`,
  ASSETS_MULTI_UPLOAD: assetType => `${SERVER_ROUTE.ASSETS}/${assetType}/multi-upload`,
  ASSETS_FIND_PARENTS: (assetType, assetId) => `${SERVER_ROUTE.ASSETS}/${assetType}/parent/${assetId}`,
  ASSETS_DELETE: (assetType, assetId) => `${SERVER_ROUTE.ASSETS}/${assetType}/${assetId}`,
  ASSETS_COPY_TO_PROJECT: (assetType, assetId) => `${SERVER_ROUTE.ASSETS}/${assetType}/${assetId}/copy-to-project`,

  PDF_PREVIEW: 'pdf-preview'
};

const auth = {
  login: (username, password) =>
    api.post(SERVER_ROUTE.LOGIN, `username=${encodeURIComponent(username)}&password=${password}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  registration: (username, password, invitationId) =>
    api.post(
      SERVER_ROUTE.REGISTRATION,
      `username=${encodeURIComponent(username)}&password=${password}&invitationId=${invitationId}`,
      {
        headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
      }
    ),
  loginFacebook: token =>
    api.post(SERVER_ROUTE.LOGIN_FACEBOOK, `access_token=${token}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  loginGoogle: token =>
    api.post(SERVER_ROUTE.LOGIN_GOOGLE, `id_token=${token}`, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    }),
  forgotPassword: (username, host) => api.post(SERVER_ROUTE.FORGOT_PASSWORD, { username, host }),
  verifyToken: token => api.post(SERVER_ROUTE.VERIFY_TOKEN, { token }),
  verifyInvitation: invitationId => api.post(SERVER_ROUTE.VERIFY_INVITATION, { invitationId }),
  resetPassword: (password, token) => api.post(SERVER_ROUTE.RESET_PASSWORD, { password, token }),
  getUserInfo: () => api.get(SERVER_ROUTE.USER_INFO),
  logout: () => api.get(SERVER_ROUTE.LOGOUT),
  acceptTermsOfUse: () => api.post(SERVER_ROUTE.ACCEPT_TERMS_OF_USE)
};

const factoryMaps = {
  getList: () => api.get(SERVER_ROUTE.FACTORY_MAPS),
  create: factoryMap => api.post(SERVER_ROUTE.FACTORY_MAPS, factoryMap),
  update: factoryMap => api.put(SERVER_ROUTE.UPDATE_FACTORY_MAP(factoryMap._id), factoryMap),
  delete: id => api.delete(SERVER_ROUTE.DELETE_FACTORY_MAP(id)),
  publish: (id, published) => api.patch(SERVER_ROUTE.SET_PUBLISHED_FLAG(id), { published })
};

const iframeScripts = {
  getList: () => api.get(SERVER_ROUTE.IFRAME_SCRIPTS),
  create: iframeScript => api.post(SERVER_ROUTE.IFRAME_SCRIPTS, iframeScript),
  update: iframeScript => api.put(SERVER_ROUTE.UPDATE_IFRAME_SCRIPT(iframeScript._id), iframeScript),
  delete: id => api.delete(SERVER_ROUTE.DELETE_IFRAME_SCRIPT(id)),
  setLatest: id => api.patch(SERVER_ROUTE.SET_LATEST_IFRAME_SCRIPT(id))
};

const instances = {
  // union request for both pages: visitors and requests
  // only for visitors page => { orphan: false, email: false }
  // only for requests page => { orphan: true, email: true }
  getList: () => api.get(SERVER_ROUTE.INSTANCES, { params: { orphan: true, email: false }, timeout: 20000 }),
  getOne: (id, fields) => api.get(SERVER_ROUTE.INSTANCE(id), { params: { fields } }),
  delete: id => api.delete(SERVER_ROUTE.INSTANCE(id)),
  getListByPartner: (partnerId, search, limit = 15) =>
    api.get(SERVER_ROUTE.INSTANCES_BY_PARTNER(partnerId), { params: { search, limit } }),
  getListBySeed: (seedId, fields = []) =>
    api.get(SERVER_ROUTE.INSTANCES_BY_SEED(seedId), { params: { fields }, timeout: 15000 }),
  update: instance => api.put(SERVER_ROUTE.INSTANCE(instance._id), instance),
  updateData: (instanceId, instance) => api.patch(SERVER_ROUTE.UPDATE_INSTANCE(instanceId), { instance }),
  markRequestAsRead: id => api.patch(SERVER_ROUTE.MARK_REQUEST_AS_READ(id)),
  markRequestAsUnread: id => api.patch(SERVER_ROUTE.MARK_REQUEST_AS_UNREAD(id)),
  deleteRequest: id => api.patch(SERVER_ROUTE.DELETE_REQUEST(id)),
  makeLeadPrimary: id => api.patch(SERVER_ROUTE.MAKE_LEAD_PRIMARY(id)),
  getPDF: (id, signed = false) =>
    api.get(signed ? SERVER_ROUTE.INSTANCE_SIGNED_PDF(id) : SERVER_ROUTE.INSTANCE_PDF(id), {
      responseType: 'blob',
      headers: { ...getContentType(CONTENT_TYPES.PDF) }
    }),
  getHTMLPreview: (id, source, templateId) =>
    api.post(SERVER_ROUTE.INSTANCE_HTML_PREVIEW(id), { source, templateId }, { timeout: 10000 }),
  uploadSignedPDF: id => file => {
    const body = new FormData();

    body.append('file', file);

    return api.post(SERVER_ROUTE.INSTANCE_UPLOAD_SIGNED_PDF(id), body, {
      headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) }
    });
  },
  sendToLegalesign: id => api.patch(SERVER_ROUTE.SEND_TO_LEGALESIGN(id)),
  checkLegalesignStatus: id => api.patch(SERVER_ROUTE.CHECK_LEGALESIGN_STATUS(id)),
  sendToProduction: id => api.patch(SERVER_ROUTE.SEND_TO_PRODUCTION(id)),
  cancelOrder: id => api.patch(SERVER_ROUTE.CANCEL_ORDER(id)),
  runIntegration: id => api.patch(SERVER_ROUTE.RUN_INTEGRATION(id))
};

const mappedInstances = {
  getOne: id => api.get(SERVER_ROUTE.GET_MAPPED_INSTANCE(id)),
  getPreview: instanceId => api.get(SERVER_ROUTE.MAPPED_INSTANCES_PREVIEW, { params: { instanceId } }),
  create: (instanceId, factoryId) => api.post(SERVER_ROUTE.MAPPED_INSTANCES, { instanceId, factoryId }),
  update: mappedInstance => api.put(SERVER_ROUTE.UPDATE_MAPPED_INSTANCE(mappedInstance._id), mappedInstance)
};

const partners = {
  getList: () => api.get(SERVER_ROUTE.PARTNERS),
  create: partner => api.post(SERVER_ROUTE.PARTNERS, partner),
  update: partner => api.put(SERVER_ROUTE.UPDATE_PARTNER(partner._id), partner),
  delete: id => api.delete(SERVER_ROUTE.DELETE_PARTNER(id)),
  createAPIKey: (id, remove) => api.patch(SERVER_ROUTE.CREATE_API_KEY(id), {}, { params: { remove } })
};

const templates = {
  getList: partnerId => api.get(SERVER_ROUTE.TEMPLATES(partnerId)),
  create: template => api.post(SERVER_ROUTE.TEMPLATES(template.partnerId), template),
  update: template => api.put(SERVER_ROUTE.UPDATE_TEMPLATE(template.partnerId, template._id), template),
  delete: template => api.delete(SERVER_ROUTE.DELETE_TEMPLATE(template.partnerId, template._id))
};

const plots = {
  getList: () => api.get(SERVER_ROUTE.PLOTS),
  create: plot => api.post(SERVER_ROUTE.PLOTS, plot),
  update: plot => api.put(SERVER_ROUTE.UPDATE_PLOT(plot._id), plot),
  delete: id => api.delete(SERVER_ROUTE.DELETE_PLOT(id))
};

const projects = {
  getList: () => api.get(SERVER_ROUTE.PROJECTS),
  create: project => api.post(SERVER_ROUTE.PROJECTS, project),
  update: project => api.put(SERVER_ROUTE.UPDATE_PROJECT(project._id), project),
  delete: id => api.delete(SERVER_ROUTE.DELETE_PROJECT(id))
};

const roles = {
  getList: () => api.get(SERVER_ROUTE.ROLES),
  create: role => api.post(SERVER_ROUTE.ROLES, role),
  update: role => api.put(SERVER_ROUTE.UPDATE_ROLE(role._id), role),
  delete: id => api.delete(SERVER_ROUTE.DELETE_ROLE(id))
};

const seeds = {
  getList: () => api.get(SERVER_ROUTE.SEEDS),
  create: seed => api.post(SERVER_ROUTE.SEEDS, seed),
  delete: id => api.delete(SERVER_ROUTE.DELETE_SEED(id)),
  clone: seed => api.post(SERVER_ROUTE.CLONE_SEED(seed._id), seed, { timeout: 15000 }),
  update: (seed, scope = '') => api.patch(SERVER_ROUTE.UPDATE_SEED(seed._id), seed, { params: { scope } }),
  updatePrices: (id, prices) => api.patch(SERVER_ROUTE.UPDATE_PRICES(id), { prices }),
  updateMaterials: (id, materials) => api.patch(SERVER_ROUTE.UPDATE_MATERIALS(id), { materials }),
  updateRemoteControl: (id, remoteControl) => api.patch(SERVER_ROUTE.UPDATE_REMOTE_CONTROL(id), { remoteControl }),
  deleteRemoteControl: (id, remoteControl) => api.patch(SERVER_ROUTE.DELETE_REMOTE_CONTROL(id), { remoteControl }),
  updateRemoteSwitches: (id, remoteSwitches) => api.patch(SERVER_ROUTE.UPDATE_REMOTE_SWITCHES(id), { remoteSwitches })
};

const parts = {
  getList: (seedId, fields = []) => api.get(SERVER_ROUTE.PARTS(seedId), { params: { fields } }),
  create: part => api.post(SERVER_ROUTE.PARTS(part.seed), part),
  update: part => api.put(SERVER_ROUTE.UPDATE_PART(part.seed, part._id), part),
  delete: part => api.delete(SERVER_ROUTE.DELETE_PART(part.seed, part._id)),
  multiUpdate: (seedId, list) => api.put(SERVER_ROUTE.PARTS(seedId), { parts: list })
};

const settings = {
  getGlobalSettings: () => api.get(SERVER_ROUTE.GLOBAL_SETTINGS),
  getGlobalTheme: () => api.get(SERVER_ROUTE.GLOBAL_THEME),
  updateGlobalSettings: data => api.put(SERVER_ROUTE.GLOBAL_SETTINGS, { settings: data }),
  updateGlobalTheme: theme => api.put(SERVER_ROUTE.GLOBAL_THEME, { theme })
};

const statistics = {
  getUserStatistics: id => api.get(SERVER_ROUTE.USER_STATISTICS(id))
};

const users = {
  getList: () => api.get(SERVER_ROUTE.USERS),
  update: user => api.put(SERVER_ROUTE.UPDATE_USER(user._id), user),
  delete: id => api.delete(SERVER_ROUTE.DELETE_USER(id))
};

const invitations = {
  getList: () => api.get(SERVER_ROUTE.INVITATIONS),
  create: invitation => api.post(SERVER_ROUTE.INVITATIONS, invitation),
  update: invitation => api.put(SERVER_ROUTE.UPDATE_INVITATION(invitation._id), invitation),
  delete: id => api.delete(SERVER_ROUTE.DELETE_INVITATION(id))
};

const getAssetBody = (files, projectId) => {
  const body = new FormData();

  body.append('projectId', projectId);

  if (Array.isArray(files)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      body.append('files', files[i]);
    }
  } else {
    body.append('file', files);
  }

  return body;
};

const makeAssetRoutes = assetType => {
  const common = {
    getList: projectId => api.get(SERVER_ROUTE.ASSETS_GET_LIST(assetType), { params: { projectId } }),
    setProject: (assetId, projectId) => api.patch(SERVER_ROUTE.ASSETS_SET_PROJECT(assetType, assetId), { projectId }),
    upload: projectId => file =>
      api.post(SERVER_ROUTE.ASSETS_UPLOAD(assetType), getAssetBody(file, projectId), {
        headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) },
        timeout: 60000
      }),
    multiUpload: projectId => files =>
      api.post(SERVER_ROUTE.ASSETS_MULTI_UPLOAD(assetType), getAssetBody(files, projectId), {
        headers: { ...getContentType(CONTENT_TYPES.FORM_DATA) },
        timeout: 60000
      }),
    delete: (id, unsafe = false) => api.delete(SERVER_ROUTE.ASSETS_DELETE(assetType, id), { params: { unsafe } })
  };

  if (assetType === ASSET_TYPES.MODELS) return common;

  return {
    ...common,
    getParent: assetId => api.get(SERVER_ROUTE.ASSETS_FIND_PARENTS(assetType, assetId)),
    copyToProject: (assetId, projectId) =>
      api.post(SERVER_ROUTE.ASSETS_COPY_TO_PROJECT(assetType, assetId), { projectId }, { timeout: 10000 })
  };
};

const pdf = {
  preview: (body, header, footer, options) =>
    api.post(
      SERVER_ROUTE.PDF_PREVIEW,
      { body, header, footer, options },
      {
        timeout: 10000,
        responseType: 'blob',
        headers: { ...getContentType(CONTENT_TYPES.PDF) }
      }
    )
};

const routes = {
  auth,
  factoryMaps,
  iframeScripts,
  instances,
  mappedInstances,
  partners,
  templates,
  parts,
  plots,
  projects,
  roles,
  seeds,
  settings,
  statistics,
  users,
  invitations,
  assets: {
    images: makeAssetRoutes(ASSET_TYPES.IMAGES),
    textures: makeAssetRoutes(ASSET_TYPES.TEXTURES),
    models: makeAssetRoutes(ASSET_TYPES.MODELS)
  },
  pdf
};

export default routes;
