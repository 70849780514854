import { SeedType } from 'types';
import React, { useCallback, useMemo } from 'react';
import { PageTile } from '../Atoms';
import { RIGHTS } from '../../utils/rights';
import RawEditor from '../RawEditor';
import { useUpdateSeed } from '../../store/seed';
import { useUserRights } from '../../utils/hooks';
import SeedAnalytics from './SeedAnalytics';

interface SeedDashboardActionsProps {
  seed: SeedType;
}

const SeedDashboardActions = ({ seed }: SeedDashboardActionsProps) => {
  const hasRights = useUserRights();
  const { updateSeed } = useUpdateSeed();

  const initialRawValue = useMemo(
    () => ({
      json: {
        name: seed.name,
        nameLong: seed.nameLong,
        version: seed.version,
        description: seed.description,
        projectId: seed.projectId,
        settings: seed.settings,
        rootPartId: seed.rootPartId,
        materials: seed.materials,
        assets: seed.assets
      }
    }),
    [seed]
  );

  const handleUpdate = useCallback(
    (formValue: { json: Partial<SeedType> }) => {
      updateSeed({ data: { ...seed, ...formValue.json }, scope: 'main' });
    },
    [seed, updateSeed]
  );

  return (
    <PageTile>
      <h5>Actions</h5>
      <RawEditor
        rights={[RIGHTS.SEEDS__EDIT_RAW]}
        initial={initialRawValue}
        header="Edit seed"
        onSubmit={handleUpdate}
      />
      {hasRights([RIGHTS.SEEDS__ANALYTICS]) ? (
        <div className="mt-2">
          <SeedAnalytics seedId={seed._id} />
        </div>
      ) : null}
    </PageTile>
  );
};

export default SeedDashboardActions;
