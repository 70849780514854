import { ProjectType } from 'types';
import { useCallback, useMemo } from 'react';
import { PageTile } from '../Atoms';
import { RIGHTS } from '../../utils/rights';
import RawEditor from '../RawEditor';
import { useUpdateProject } from '../../store/project';

interface ProjectDashboardActionsProps {
  project: ProjectType;
}

const ProjectDashboardActions = ({ project }: ProjectDashboardActionsProps) => {
  const { updateProject } = useUpdateProject();

  const initialRawValue = useMemo(
    () => ({
      json: {
        name: project.name,
        link: project.link,
        publicSeedId: project.publicSeedId,
        settings: project.settings,
        partnerId: project.partnerId,
        allowedDomains: project.allowedDomains
      }
    }),
    [project]
  );

  const handleUpdate = useCallback(
    formValue => {
      updateProject({ ...project, ...formValue.json });
    },
    [project, updateProject]
  );

  return (
    <PageTile>
      <h5>Actions</h5>
      <RawEditor
        rights={[RIGHTS.PROJECTS__EDIT_RAW]}
        initial={initialRawValue}
        header="Edit project"
        onSubmit={handleUpdate}
      />
    </PageTile>
  );
};

export default ProjectDashboardActions;
