import { createContext } from 'react';

interface FormContextProps {
  shadowValues?: {
    name?: string;
    value?: any;
  }[];
}

export const FormContext = createContext({ shadowValues: [] } as FormContextProps);

FormContext.displayName = 'FormContext';
