import { createSelector } from 'reselect';
import { api } from '../../store/services/api';
import { projectsSelectors } from '../projects';

// TODO: deprecated
const selector = api.endpoints.getSeeds.select();

export const selectSeeds = state => selector(state)?.data?.entities || {};
export const selectSeedMemoized = createSelector(
  [selectSeeds, (_, seedId) => seedId],
  (seeds = {}, seedId) => seeds[seedId]
);
export const selectSeedsRequest = state => selector(state)?.isLoading || selector(state)?.isUninitialized;

export const selectSeedSettingsMemoized = createSelector(
  [selectSeedMemoized, projectsSelectors.selectProjects],
  (seed = {}, projects) => ({
    seedSettings: seed?.settings || {},
    projectSettings: projects[seed.projectId]?.settings || {}
  })
);

export const selectOptionStatusList = createSelector(
  [selectSeedSettingsMemoized],
  ({ seedSettings, projectSettings }) =>
    (seedSettings.optionStatuses || projectSettings.optionStatuses || []).map(status => ({
      value: status.name,
      label: status.name
    }))
);

export const selectPriceCodesEnabled = createSelector(
  [selectSeedSettingsMemoized],
  ({ seedSettings, projectSettings }) =>
    seedSettings?.price?.usePriceCodes === undefined
      ? Boolean(projectSettings?.price?.usePriceCodes)
      : Boolean(seedSettings?.price?.usePriceCodes)
);

export const selectExtraInfoCollectionsNames = createSelector(
  [selectSeedSettingsMemoized],
  ({ seedSettings, projectSettings }) =>
    (seedSettings.extraInfoCollections || projectSettings.extraInfoCollections || []).map(collection => ({
      label: collection.name,
      value: collection.name
    }))
);

export const selectRootPartId = createSelector(
  [selectSeeds, (_, seedId) => seedId],
  (seeds, seedId) => seeds[seedId]?.rootPartId
);

export const selectSeedPrices = createSelector([selectSeedMemoized], seed => seed?.prices || []);

export const selectSeedPriceCodes = createSelector([selectSeedPrices], prices =>
  Object.values(
    prices.reduce((result, item) => {
      if (item.code) {
        // eslint-disable-next-line no-param-reassign
        result[item.code] = { value: item.code, label: item.code };
      }

      return result;
    }, {})
  )
);

export const selectPriceSchemes = createSelector(
  [selectSeedSettingsMemoized],
  ({ seedSettings, projectSettings }) => seedSettings?.price?.schemes || projectSettings?.price?.schemes || []
);

export const selectPriceSchemesSuggestions = createSelector([selectPriceSchemes], schemes =>
  schemes.map(scheme => ({ label: scheme.name, value: scheme.name }))
);

export const selectMetricsList = createSelector(
  [selectSeedSettingsMemoized],
  ({ seedSettings, projectSettings }) => seedSettings.metrics || projectSettings.metrics || []
);
