import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, ButtonGroup, ListGroup, ListGroupItem } from '../../Atoms';
import Loader from '../../Loader';
import { TYPE_ASSET, TYPE_ASSET_PARENT } from '../../../utils/propTypes';
import { useProjects } from '../../../store/project';

const AssetInfo = ({ assetInfo, setAssetProject, copyAssetToProject, asset, requesting, hideChangingProjects }) => {
  const { seeds = [], parts = [], projects = [] } = assetInfo;
  const { projects: projectsMap } = useProjects();
  const currentProjectId = asset.metadata ? asset.metadata.projectId || '' : '';

  const setProject = useCallback(
    e => setAssetProject(asset._id, e.currentTarget.dataset.projectId),
    [asset._id, setAssetProject]
  );

  const copyToProject = useCallback(
    e => copyAssetToProject(asset._id, e.currentTarget.dataset.projectId),
    [asset._id, copyAssetToProject]
  );

  const renderProjectButtons = useCallback(
    (_id, projectsList) => {
      // dont show buttons if asset already belongs to current project
      if (_id === currentProjectId || hideChangingProjects) return null;

      return (
        <ButtonGroup className="pull-right">
          {projectsList.length > 1 ? (
            <Button size="sm" color="primary" title="Copy to" data-project-id={_id} onClick={copyToProject}>
              Copy to project <i className="fa fa-clone" />
            </Button>
          ) : null}
          <Button size="sm" color="primary" outline title="Set project" data-project-id={_id} onClick={setProject}>
            Set project <i className="fa fa-caret-right" />
          </Button>
        </ButtonGroup>
      );
    },
    [copyToProject, currentProjectId, hideChangingProjects, setProject]
  );

  const renderProject = useCallback(
    ({ _id, name }, index, projectsList) => (
      <ListGroupItem key={_id}>
        {name} <span className="text-muted">{_id}</span>
        {renderProjectButtons(_id, projectsList)}
      </ListGroupItem>
    ),
    [renderProjectButtons]
  );

  const renderSeed = useCallback(
    ({ _id, name, version }) => (
      <ListGroupItem key={_id}>
        {name} <span className="text-muted">{version}</span>
      </ListGroupItem>
    ),
    []
  );

  const renderPart = useCallback(
    ({ _id, ReferenceName, Option, seed }) => (
      <ListGroupItem key={_id}>
        {ReferenceName} {Option}
        <span className="text-muted">seed: {seed}</span>
      </ListGroupItem>
    ),
    []
  );

  const renderAlert = useCallback(() => {
    if (projects.length > 0) {
      return (
        <Alert color="warning">
          <h4>Attention</h4>
          <hr />
          <p>
            The following list might not be exhaustive - please make sure all Production seeds still work after
            assigning projectIds.
          </p>
        </Alert>
      );
    }

    return (
      <Alert color="warning">
        <h4>No projects found</h4>
        <hr />
        <p>
          This means that either the asset is not used anywhere or the search algorithm needs to be adjusted. Please
          double-check projects before deleting this asset.
        </p>
      </Alert>
    );
  }, [projects.length]);

  const renderCurrentProject = useCallback(() => {
    return <p>Current project: {projectsMap[currentProjectId] ? projectsMap[currentProjectId].name : 'none'}</p>;
  }, [currentProjectId, projectsMap]);

  return (
    <section>
      <hr />

      <Loader loading={requesting}>
        {renderCurrentProject()}
        {renderAlert()}
        {projects.length > 0 ? (
          <div>
            <h4>Projects</h4>
            <ListGroup>{projects.map(renderProject)}</ListGroup>
            <hr />
          </div>
        ) : null}
        {seeds.length > 0 ? (
          <div>
            <h4>Seeds</h4>
            <ListGroup>{seeds.map(renderSeed)}</ListGroup>
            <hr />
          </div>
        ) : null}

        {parts.length > 0 ? (
          <div>
            <h4>Parts</h4>
            <ListGroup>{parts.map(renderPart)}</ListGroup>
          </div>
        ) : null}
      </Loader>
    </section>
  );
};

AssetInfo.propTypes = {
  asset: TYPE_ASSET.isRequired,
  assetInfo: TYPE_ASSET_PARENT,
  setAssetProject: PropTypes.func.isRequired,
  copyAssetToProject: PropTypes.func.isRequired,
  requesting: PropTypes.bool.isRequired,
  hideChangingProjects: PropTypes.bool
};

AssetInfo.defaultProps = {
  assetInfo: {},
  hideChangingProjects: false
};

export default AssetInfo;
