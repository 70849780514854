import { useState } from 'react';
import { get } from 'lodash';
import { useController, useFormContext } from 'react-hook-form';
import { ProjectType } from 'types';
import { FormField, Button, ButtonGroup } from '../../Atoms';

interface PriceScheme {
  name: string;
  displayName: string;
  description: string;
  currency: string;
  currencyFormat: string;
  priceRounding: string;
  caveat: string;
  disabled: boolean;
}

const SUPPORTED_CURRENCY_LIST = [
  { label: 'Euro (€)', value: '€' },
  { label: 'Dollar ($)', value: '$' },
  { label: 'Swiss Frank (CHf)', value: 'CHf' },
  { label: 'Pound (£)', value: '£' }
];

export const PriceSchemes = () => {
  const useMultiplePriceSchemesField = 'settings.price.useMultiplePriceSchemes';
  const { field } = useController<ProjectType>({ name: useMultiplePriceSchemesField });
  const { setValue } = useFormContext<ProjectType>();
  const [isMultiple, setIsMultiple] = useState<boolean>(field.value);

  const onChange = (isM: boolean) => {
    setIsMultiple(isM);
  };

  return (
    <>
      <FormField.Toggle<ProjectType>
        name="settings.price.usePriceCodes"
        label="Use pricecodes"
        description="Enable using pricecodes for this seed. If defined here not in root part settings, option prices will not be remotely controllable as a side effect. Overrides root part settings. This will also enable pricing schemes"
      />
      <FormField.Toggle<ProjectType>
        name="settings.interface.summaryPriceHidden"
        label="Hide Total Price from Summary"
      />

      <div className="d-flex flex-column flex-grow-1 mb-3 pb-4 border-bottom">
        <span className="text-uppercase font-weight-bold mb-1">how many price schemes</span>

        <ButtonGroup>
          <Button
            color="secondary"
            onClick={() => {
              setValue(useMultiplePriceSchemesField, false);
              setValue('settings.price.schemes', []);
              onChange(false);
            }}
            outline={isMultiple}
          >
            Single
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setValue(useMultiplePriceSchemesField, true);
              setValue('settings.price.defaults', undefined);
              onChange(true);
            }}
            outline={!isMultiple}
          >
            Multiple
          </Button>
        </ButtonGroup>
      </div>

      {isMultiple ? (
        <FormField.ArrayWrapper<ProjectType>
          name="settings.price.schemes"
          label="Price schemes"
          description="Price schemes which enable overriding values defined above"
          headerFormat={value => get(value, 'name', 'untitled')}
          twoColumns
        >
          <FormField.Text<PriceScheme>
            name="name"
            label="Unique name"
            description={'Must be unique and not "default"'}
            column="left"
          />
          <FormField.Text<PriceScheme> name="displayName" label="Display name" column="left" />
          <FormField.Text<PriceScheme> name="description" label="Description" column="left" />
          <FormField.Select<PriceScheme>
            name="currency"
            label="Currency"
            options={SUPPORTED_CURRENCY_LIST}
            description="Pick one from the list or type a custom symbol"
            column="left"
          />
          <FormField.Text<PriceScheme>
            name="currencyFormat"
            label="Currency format"
            description="Use hashes to format currency like ####.##"
            column="right"
          />
          <FormField.Number<PriceScheme>
            name="priceRounding"
            label="Price rounding"
            description="Price will be a multiple of this value"
            column="right"
          />
          <FormField.Textarea<PriceScheme>
            rows={4}
            name="caveat"
            label="Caveat"
            description="Additional information about price"
            column="right"
          />
          <FormField.Toggle<PriceScheme> name="disabled" label="Disable" column="right" />
        </FormField.ArrayWrapper>
      ) : (
        <>
          <FormField.Select<ProjectType>
            name="settings.price.defaults.currency"
            label="Currency"
            options={SUPPORTED_CURRENCY_LIST}
            description="Pick one from the list or type a custom symbol"
          />
          <FormField.Text<ProjectType>
            name="settings.price.defaults.currencyFormat"
            label="Currency format"
            description="Use hashes to format currency like ####.##"
          />
          <FormField.Number<ProjectType>
            name="settings.price.defaults.priceRounding"
            label="Price rounding"
            description="Price will be a multiple of this value"
          />
          <FormField.Textarea<ProjectType>
            rows={4}
            name="settings.price.defaults.caveat"
            label="Caveat"
            description="Additional information about price"
          />
        </>
      )}
    </>
  );
};
