import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ProtectedButton from '../ProtectedButton';
import Form from '../Form';
import Field from '../Form/Field';
import { useModal } from '../../utils/hooks';

const RawEditor = ({ onSubmit, initial, rights, header, onlyModal, onClose }) => {
  const { isOpen, open, close } = useModal(onlyModal);
  const handleClose = useCallback(() => {
    onClose();
    close();
  }, [close, onClose]);

  return (
    <>
      <Form
        initialValues={initial}
        inModal
        closeModal={handleClose}
        name={header}
        onSubmit={onSubmit}
        isModalOpen={isOpen}
      >
        <Field.Json name="json" />
      </Form>
      {onlyModal ? null : (
        <ProtectedButton rights={rights} onClick={open} color="primary" outline block>
          {`Edit as JSON {...}`}
        </ProtectedButton>
      )}
    </>
  );
};

RawEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initial: PropTypes.shape({}),
  rights: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string,
  onlyModal: PropTypes.bool,
  onClose: PropTypes.func
};

RawEditor.defaultProps = {
  initial: {},
  rights: [],
  header: '',
  onlyModal: false,
  onClose: () => {}
};

export default RawEditor;
