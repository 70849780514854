import React, { useMemo } from 'react';
import ReactTable from 'react-table-6';
import b from 'b_';
import { Link } from 'react-router-dom';
import Loader from '../../Loader';
import { factoryMapsHooks } from '../../../modules/factoryMaps';
import { defaultFilterMethod } from '../../../utils/helpers/table';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';
import { getFactoryMapUrl } from '../../../utils/appRoutes';

const factoryMapsList = b.with('factory-maps-list');

const FactoryMapsList = () => {
  const { request, list } = factoryMapsHooks.useFactoryMapsList();
  const hasRights = useUserRights();

  const columns = useMemo(() => {
    const className = 'fw-bold p-2';

    return [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 200,
        className,
        Cell: row =>
          hasRights([RIGHTS.FACTORY_MAPS__UPDATE]) ? (
            <Link to={getFactoryMapUrl(row.original._id)}>{row.value}</Link>
          ) : (
            row.value
          )
      },
      {
        Header: '',
        accessor: 'published',
        width: 50,
        filterable: false,
        className: 'text-center p-2',
        Cell: row => (row.value ? <i className="fa fa-fw fa-check-square-o text-success" title="Published" /> : null)
      },
      {
        Header: 'Version',
        accessor: 'version',
        width: 100,
        filterable: false,
        className: `text-center ${className}`
      },
      {
        Header: 'Seed',
        accessor: 'populated',
        width: 200,
        filterable: false,
        className,
        Cell: row => (row.value.seedName ? `${row.value.seedName} (${row.value.seedVersion})` : null)
      }
    ];
  }, [hasRights]);

  return (
    <Loader loading={request}>
      <ReactTable
        data={list}
        pageSize={list.length}
        showPagination={false}
        columns={columns}
        defaultFilterMethod={defaultFilterMethod}
        filterable
        resizable={false}
        className={`${factoryMapsList()} app-entity-list -striped -highlight`}
      />
    </Loader>
  );
};

export default FactoryMapsList;
