import React, { FC, useCallback, useContext } from 'react';
import Icon from '../../../Icon/Icon';
import ArrayContext from './ArrayContext';

interface ArrayRemoveButtonProps {
  index: number;
}

const ArrayRemoveButton: FC<ArrayRemoveButtonProps> = ({ index }) => {
  const { remove } = useContext(ArrayContext);
  const handleRemove = useCallback(
    e => {
      if (!remove) return;
      remove(Number(e.currentTarget.dataset.index));
    },
    [remove]
  );

  return (
    <button
      className="btn btn-sm border-0 btn-outline-danger flex-grow-0 ml-auto px-1"
      type="button"
      title="Remove"
      data-index={index}
      onClick={handleRemove}
    >
      <Icon type="mdiTrashCanOutline" scale={0.7} />
    </button>
  );
};

export default ArrayRemoveButton;
