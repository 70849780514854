import { get, useFormContext } from 'react-hook-form';
import FieldWrapper from '../FieldWrapper';
import { BaseInputProps, FieldType, InputType } from '../../types';
import { getConvertedRegisterOptions } from '../helpers';

const CLASSNAMES: Partial<Record<InputType, string>> = {
  [FieldType.SWITCH]: 'form-check-input',
  [FieldType.CHECK]: 'form-check-input'
};

const INPUT_TAG = 'input';

const CHECKBOX_TYPES = new Set([FieldType.SWITCH, FieldType.CHECK]);
const isCheckbox = (type: InputType) => CHECKBOX_TYPES.has(type);

const BaseInput = <T,>({
  name,
  type,
  label,
  description,
  placeholder,
  rows,
  readonly,
  registerOptions,
  append,
  prepend,
  onChange,
  index,
  useShadowValue
}: BaseInputProps<T>) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<T>();
  const Tag = type === FieldType.TEXTAREA ? type : INPUT_TAG;

  const required = Boolean(registerOptions?.required);

  const min = registerOptions?.min === undefined ? undefined : Number(registerOptions?.min);
  const max = registerOptions?.max === undefined ? undefined : Number(registerOptions?.max);

  const minLength = registerOptions?.minLength === undefined ? undefined : Number(registerOptions?.minLength);
  const maxLength = registerOptions?.maxLength === undefined ? undefined : Number(registerOptions?.maxLength);

  let className = CLASSNAMES[type] || 'form-control';

  const error = get(errors, name);

  if (error) {
    className = `${className} is-invalid`;
  }

  return (
    <FieldWrapper
      type={type}
      name={name}
      label={label}
      description={description}
      required={required}
      error={error?.message}
      isInputTag={Tag === INPUT_TAG}
      isCheckbox={isCheckbox(type)}
      useShadowValue={useShadowValue}
    >
      {prepend ? <span className="input-group-text">{prepend}</span> : null}
      <Tag
        {...register(name, getConvertedRegisterOptions<T>(registerOptions))}
        id={name}
        className={className}
        type={isCheckbox(type) ? 'checkbox' : type}
        placeholder={placeholder}
        readOnly={readonly}
        required={required}
        rows={rows}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
        role={type}
        onChange={e => {
          onChange && onChange(e, index);
        }}
      />
      {append ? <span className="input-group-text">{append}</span> : null}
    </FieldWrapper>
  );
};

export default BaseInput;
