import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field as FFField } from 'react-final-form';
import { Button, Table } from '../../../Atoms';
import Field from '../../../Form/Field';
import { partsSelectors } from '../../../../modules/parts';
import { TYPE_SEED } from '../../../../utils/propTypes';
import { RIGHTS } from '../../../../utils/rights';
import { useUserRights } from '../../../../utils/hooks';

const FieldSwitch = ({ pool = [], input }) => {
  const { value = 0 } = input;

  return pool[Number(value)] || null;
};

const PriceList = props => {
  const { seed, fields } = props;

  const getHasRights = useUserRights();

  const hasUpdateRights = getHasRights([RIGHTS.SEEDS__UPDATE]);

  const schemes = useSelector(state => partsSelectors.selectAllPriceSchemes(state, seed._id));

  const data = fields.map(field => ({ name: field }));

  const handleAdd = useCallback(() => {
    const newValues = schemes.reduce((result, scheme) => {
      // eslint-disable-next-line no-param-reassign
      result[scheme.name] = 0;

      return result;
    }, {});

    fields.push({ code: `code#${fields.length}`, schemes: newValues });
  }, [fields, schemes]);

  const handleRemove = useCallback(
    e => {
      const { index } = e.currentTarget.dataset;

      fields.remove(Number(index));
    },
    [fields]
  );

  const columns = useMemo(() => {
    const result = [
      {
        accessor: 'name',
        Header: 'Code',
        disableFilters: true,
        disableSortBy: true,
        Cell: table =>
          hasUpdateRights ? (
            <FFField
              name={`${table.value}.used`}
              component={FieldSwitch}
              pool={[
                <Field.Text required noLabel name={`${table.value}.code`} />,
                <Field.Immutable noLabel name={`${table.value}.code`} />
              ]}
            />
          ) : (
            <Field.Immutable noLabel name={`${table.value}.code`} />
          )
      }
    ];

    result.push(
      ...schemes.map(scheme => ({
        id: scheme.name,
        disableFilters: true,
        disableSortBy: true,
        Header: scheme.displayName || scheme.name,
        Cell: table => <Field.Number allowEmpty name={`${table.row.original.name}.schemes.${scheme.name}`} noLabel />
      }))
    );

    if (hasUpdateRights) {
      result.push({
        id: 'remove',
        width: 50,
        resizable: false,
        disableFilters: true,
        disableSortBy: true,
        Cell: table => (
          <FFField
            name={`${table.row.original.name}.used`}
            component={FieldSwitch}
            pool={[
              <Button color="danger" outline onClick={handleRemove} size="sm" data-index={table.row.index}>
                <i className="fa fa-trash fa-fw" title="Remove" />
              </Button>,
              <Button size="sm" disabled title="Price code in use">
                <i className="fa fa-fw fa-lock" />
              </Button>
            ]}
          />
        )
      });
    }

    return result;
  }, [handleRemove, hasUpdateRights, schemes]);

  return (
    <div className="mb-2">
      <Table columns={columns} data={data} pageSize={data.length} showPagination={false} />
      {hasUpdateRights ? (
        <Button color="primary" outline onClick={handleAdd} className="my-1">
          Add
        </Button>
      ) : null}
    </div>
  );
};

PriceList.propTypes = {
  seed: TYPE_SEED.isRequired,
  fields: PropTypes.shape({
    remove: PropTypes.func,
    push: PropTypes.func,
    length: PropTypes.number,
    map: PropTypes.func
  }).isRequired
};

export default PriceList;
