import React, { useCallback } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useProjectListForSelect } from '../../../store/project';

export const LOST_AND_FOUND_KEY = '_lostAndFound';

const ProjectList = ({ onSelect, selectedProject, includeGlobal, includeLostAndFound }) => {
  const list = useProjectListForSelect();

  const selectHandler = useCallback(
    selectedValue => {
      const value = (selectedValue && selectedValue.value) || '';

      onSelect(value);
    },
    [onSelect]
  );

  const projectSuggestions = [...list];

  if (includeGlobal) projectSuggestions.push({ label: <b>Global</b>, value: '' });

  if (includeLostAndFound) projectSuggestions.push({ label: <b>Lost & found</b>, value: LOST_AND_FOUND_KEY });

  const selectedValue = projectSuggestions.find(({ value }) => value === selectedProject);

  return (
    <Select
      onChange={selectHandler}
      placeholder="Select project..."
      value={selectedValue}
      options={projectSuggestions}
      isClearable
    />
  );
};

ProjectList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedProject: PropTypes.string,
  includeGlobal: PropTypes.bool,
  includeLostAndFound: PropTypes.bool
};
ProjectList.defaultProps = {
  selectedProject: '',
  includeGlobal: true,
  includeLostAndFound: false
};

export default ProjectList;
