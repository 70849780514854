import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from '../Atoms';
import { instancesActions, instancesSelectors } from '../../modules/instances';
import { factoryMapsActions } from '../../modules/factoryMaps';
import { TYPE_VISITOR } from '../../utils/propTypes';
import VisitorInstanceList from '../VisitorInstanceList';
import { RIGHTS } from '../../utils/rights';
import Loader from '../Loader';
import { withUserRights } from '../UserRights';
import VisitorStatistics from './VisitorStatistics';

import './VisitorPage.scss';

const visitorPage = b.with('visitor-page');

const TAB = {
  INFO: 'info',
  STATS: 'stats'
};

export class VisitorPage extends React.PureComponent {
  state = {
    activeTab: TAB.INFO
  };

  componentDidMount() {
    const { visitor, fetchInstances, fetchFactoryMaps } = this.props;

    if (!visitor) {
      fetchInstances();
    }

    fetchFactoryMaps();
  }

  get hasRightsToReadStatistics() {
    return this.props.hasRights([RIGHTS.VISITORS__STATISTICS__READ]);
  }

  toggle = e => {
    const { dataset } = e.target;

    this.setState({ activeTab: dataset.tab });
  };

  renderContent() {
    const { visitor } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink data-tab={TAB.INFO} className={activeTab === TAB.INFO ? 'active' : ''} onClick={this.toggle}>
              Info
            </NavLink>
          </NavItem>
          {this.hasRightsToReadStatistics ? (
            <NavItem>
              <NavLink data-tab={TAB.STATS} className={activeTab === TAB.STATS ? 'active' : ''} onClick={this.toggle}>
                Statistics
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TAB.INFO} className={visitorPage('list')}>
            <VisitorInstanceList visitor={visitor} />
          </TabPane>
          {this.hasRightsToReadStatistics ? (
            <TabPane tabId={TAB.STATS} className={visitorPage('statistics')}>
              <VisitorStatistics userId={visitor._id} />
            </TabPane>
          ) : null}
        </TabContent>
      </>
    );
  }

  render() {
    const { visitor, request } = this.props;

    return (
      <div className={`${visitorPage()} p-3 flex-grow-1 w-100`}>
        <Loader loading={request}>{visitor ? this.renderContent() : null}</Loader>
      </div>
    );
  }
}

VisitorPage.defaultProps = {
  request: false
};

VisitorPage.propTypes = {
  visitor: TYPE_VISITOR, // eslint-disable-line react/require-default-props
  match: PropTypes.shape({
    params: PropTypes.shape({
      visitorId: PropTypes.string.isRequired
    })
  }).isRequired,
  hasRights: PropTypes.func.isRequired,
  fetchInstances: PropTypes.func.isRequired,
  fetchFactoryMaps: PropTypes.func.isRequired,
  request: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  visitor: instancesSelectors.selectVisitor(ownProps.match.params.visitorId)(state),
  request: instancesSelectors.selectInstancesRequest(state)
});

const mapDispatchToProps = {
  fetchInstances: instancesActions.fetchInstances,
  fetchFactoryMaps: factoryMapsActions.fetchFactoryMaps
};

export default compose(withUserRights, connect(mapStateToProps, mapDispatchToProps))(VisitorPage);
