import { Link } from 'react-router-dom';
import { Col, Icon, PageTile, Row } from '../Atoms';
import { getSeedUrl } from '../../utils/appRoutes';
import { useUserRights } from '../../utils/hooks';
import { RIGHTS } from '../../utils/rights';

interface SeedDashboardLinksProps {
  seedId: string;
}

const SeedDashboardLinks = ({ seedId }: SeedDashboardLinksProps) => {
  const hasRights = useUserRights();

  return (
    <PageTile>
      {hasRights([RIGHTS.SEEDS__UPDATE]) ? (
        <>
          <h5>Settings</h5>
          <Row className="border-bottom mb-4">
            <Col className="d-flex pb-4" sm={6} md={4}>
              <span className="text-primary pe-2">
                <Icon type="mdiFileSettingsOutline" />
              </span>
              <div>
                <Link to={getSeedUrl(seedId, 'info')} className="d-block">
                  <u className="text-dark fw-bolder">Basic Version Info</u>
                </Link>
                <small className="text-muted">Version name, description</small>
              </div>
            </Col>
            <Col className="d-flex pb-4" sm={6} md={4}>
              <span className="text-primary pe-2">
                <Icon type="mdiFileCogOutline" />
              </span>
              <div>
                <Link to={getSeedUrl(seedId, 'settings')} className="d-block">
                  <u className="text-dark fw-bolder">Version Settings</u>
                </Link>
                <small className="text-muted">Applied to this version, will override project settings</small>
              </div>
            </Col>
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.PARTS__READ]) ? (
        <>
          <h5 className="mt-4">Logic</h5>
          <Row className="border-bottom">
            <Col className="d-flex pb-4" sm={6} md={4}>
              <span className="text-primary pe-2">
                <Icon type="mdiGraphOutline" />
              </span>
              <div>
                <Link to={getSeedUrl(seedId, 'graph')} className="d-block">
                  <u className="text-dark fw-bolder">Graph Editor</u>
                </Link>
                <small className="text-muted">Parts, Controls and Options</small>
              </div>
            </Col>
            <Col className="d-flex pb-4" sm={6} md={4}>
              <span className="text-primary pe-2">
                <Icon type="mdiTable" />
              </span>
              <div>
                <Link to={getSeedUrl(seedId, 'parts')} className="d-block">
                  <u className="text-dark fw-bolder">Table Editor</u>
                </Link>
                <small className="text-muted">Parts, Controls and Options</small>
              </div>
            </Col>
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.SEEDS__MATERIALS_UPDATE, RIGHTS.SEEDS__PRICING_UPDATE, RIGHTS.PARTS__UPDATE]) ? (
        <>
          <h5 className="mt-4">Components</h5>
          <Row className="border-bottom">
            {hasRights([RIGHTS.SEEDS__MATERIALS_UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <span className="text-primary pe-2">
                  <Icon type="mdiPaletteSwatchOutline" />
                </span>
                <div>
                  <Link to={getSeedUrl(seedId, 'materials')} className="d-block">
                    <u className="text-dark fw-bolder">Materials</u>
                  </Link>
                  <small className="text-muted">Colors, textures and their settings</small>
                </div>
              </Col>
            ) : null}
            {hasRights([RIGHTS.PARTS__UPDATE]) ? (
              <>
                <Col className="d-flex pb-4" sm={6} md={4}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiFormatListBulleted" />
                  </span>
                  <div>
                    <Link to={getSeedUrl(seedId, 'tabs')} className="d-block">
                      <u className="text-dark fw-bolder">Phases</u>
                    </Link>
                    <small className="text-muted">Steps of user journey</small>
                  </div>
                </Col>
                <Col className="d-flex pb-4" sm={6} md={4}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiVideoOutline" />
                  </span>
                  <div>
                    <Link to={getSeedUrl(seedId, 'views')} className="d-block">
                      <u className="text-dark fw-bolder">Cameras</u>
                    </Link>
                    <small className="text-muted">Point of view</small>
                  </div>
                </Col>
                <Col className="d-flex pb-4" sm={6} md={4}>
                  <span className="text-primary pe-2">
                    <Icon type="mdiLightbulbOnOutline" />
                  </span>
                  <div>
                    <Link to={getSeedUrl(seedId, 'lights')} className="d-block">
                      <u className="text-dark fw-bolder">Lights</u>
                    </Link>
                    <small className="text-muted">Sunlights, Skylights, Custom lights</small>
                  </div>
                </Col>
              </>
            ) : null}
            {hasRights([RIGHTS.SEEDS__PRICING_UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <span className="text-primary pe-2">
                  <Icon type="mdiCash" />
                </span>
                <div>
                  <Link to={getSeedUrl(seedId, 'prices')} className="d-block">
                    <u className="text-dark fw-bolder">Prices</u>
                  </Link>
                  <small className="text-muted">Pricing codes, Pricing schemes</small>
                </div>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}
      {hasRights([RIGHTS.REMOTE_CONTROLS__UPDATE, RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]) ? (
        <>
          <h5 className="mt-4">Remote</h5>
          <Row className="border-bottom">
            {hasRights([RIGHTS.REMOTE_CONTROLS__UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <span className="text-primary pe-2">
                  <Icon type="mdiToggleSwitchOutline" />
                </span>
                <div>
                  <Link to={getSeedUrl(seedId, 'remote-controls')} className="d-block">
                    <u className="text-dark fw-bolder">Remote Controls</u>
                  </Link>
                  <small className="text-muted">All basics</small>
                </div>
              </Col>
            ) : null}
            {hasRights([RIGHTS.SEEDS__REMOTE_SWITCHES_UPDATE]) ? (
              <Col className="d-flex pb-4" sm={6} md={4}>
                <span className="text-primary pe-2">
                  <Icon type="mdiToggleSwitchOutline" />
                </span>
                <div>
                  <Link to={getSeedUrl(seedId, 'remote-switches')} className="d-block">
                    <u className="text-dark fw-bolder">Remote Switches</u>
                  </Link>
                  <small className="text-muted">All basics</small>
                </div>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}
    </PageTile>
  );
};

export default SeedDashboardLinks;
