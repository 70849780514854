import React, { useMemo } from 'react';
import ReactTable from 'react-table-6';
import b from 'b_';
import { Link } from 'react-router-dom';
import { Badge } from '../../Atoms';
import Loader from '../../Loader';
import { defaultFilterMethod } from '../../../utils/helpers/table';
import { useUserRights } from '../../../utils/hooks';
import { RIGHTS } from '../../../utils/rights';
import { iframeScriptsHooks } from '../../../modules/iframeScripts';
import { getIframeScriptUrl } from '../../../utils/appRoutes';

const iframeScriptsList = b.with('iframe-scripts-list');

const IframeScriptsList = () => {
  const { request, list } = iframeScriptsHooks.useFactoryMapsList();
  const hasRights = useUserRights();

  const columns = useMemo(() => {
    const className = 'fw-bold p-2';

    return [
      {
        id: 'version',
        Header: 'Version',
        accessor: 'version',
        width: 120,
        resizable: false,
        filterable: false,
        className,
        Cell: row =>
          hasRights([RIGHTS.IFRAME_SCRIPT__UPDATE]) ? (
            <Link to={getIframeScriptUrl(row.original._id)}>{row.value}</Link>
          ) : (
            row.value
          )
      },
      {
        accessor: 'latest',
        resizable: false,
        filterable: false,
        sortable: false,
        className: 'p-2 text-center',
        width: 100,
        Cell: row =>
          row.value ? (
            <Badge color="warning" pill>
              Latest
            </Badge>
          ) : null
      },
      {
        id: 'updatedAt',
        Header: 'Updated',
        accessor: 'updatedAt',
        filterable: false,
        resizable: false,
        className: 'p-2',
        Cell: row => new Date(row.value).toLocaleString(),
        width: 150
      },
      {
        Header: 'Description',
        accessor: 'description',
        filterable: false,
        className: 'p-2'
      }
    ];
  }, [hasRights]);

  return (
    <Loader loading={request}>
      <ReactTable
        data={list}
        pageSize={list.length}
        showPagination={false}
        columns={columns}
        defaultFilterMethod={defaultFilterMethod}
        defaultSorted={[{ id: 'updatedAt', desc: true }]}
        filterable
        resizable={false}
        className={`${iframeScriptsList()} app-entity-list -striped -highlight`}
      />
    </Loader>
  );
};

export default IframeScriptsList;
