import { reducer } from '../@helpers';
import assetsActions from './assetsActions';
import ASSET_TYPES from './assetTypes';
/*
  This reducer collects both images and textures - it is important that assetType is declared in success payload
*/

const makeAssetModuleReducer = assetType => {
  const moduleReducer = (state, { type, payload = {} }) => {
    switch (type) {
      case assetsActions[assetType].getParent.request.ACTION_TYPE: {
        return { ...state, parentsRequest: true };
      }
      case assetsActions[assetType].getParent.success.ACTION_TYPE: {
        const { fileName, parts, projects, seeds } = payload;

        const { parents = {} } = state;

        const newParentMap = { ...parents, [fileName]: { fileName, parts, projects, seeds } };

        return { ...state, parents: newParentMap, parentsRequest: false };
      }
      case assetsActions[assetType].copyToProject.success.ACTION_TYPE:
      case assetsActions[assetType].setProject.success.ACTION_TYPE: {
        const list = { ...state.list, [payload._id]: payload };

        return { ...state, list };
      }
      case assetsActions[assetType].updateAssetsAfterUpload.ACTION_TYPE: {
        const list = { ...state.list };

        payload.assets.forEach(asset => {
          list[asset._id] = asset;
        });

        return { ...state, list };
      }

      default:
        return state;
    }
  };

  return reducer.createBaseModuleReducer(assetsActions[assetType], moduleReducer);
};

const moduleModelsReducer = (state, { type, payload = {} }) => {
  if (type === assetsActions[ASSET_TYPES.MODELS].updateAssetsAfterUpload.ACTION_TYPE) {
    const list = { ...state.list };

    payload.assets.forEach(asset => {
      list[asset._id] = asset;
    });

    return { ...state, list };
  }

  return state;
};

const assetsReducer = {
  images: makeAssetModuleReducer(ASSET_TYPES.IMAGES),
  textures: makeAssetModuleReducer(ASSET_TYPES.TEXTURES),
  models: reducer.createBaseModuleReducer(assetsActions[ASSET_TYPES.MODELS], moduleModelsReducer)
};

export default assetsReducer;
