import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { TYPE_SUGGESTION_LIST } from '../../utils/propTypes';

import './SelectFilter.scss';

const selectFilter = b.with('select-filter');

export const ALL_FILTER = 'All';

const SelectFilter = ({ filter, onChange, options }) => {
  const value = filter.value || ALL_FILTER;
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <select
      onChange={handleChange}
      className={selectFilter({ active: value !== ALL_FILTER })}
      value={value}
      title="Set filter"
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
      <option value={ALL_FILTER}>{ALL_FILTER}</option>
    </select>
  );
};

SelectFilter.defaultProps = {
  options: [],
  filter: {
    value: ''
  }
};

SelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: TYPE_SUGGESTION_LIST,
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string
  })
};

export default SelectFilter;
