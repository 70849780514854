import { flatten } from 'lodash';
import { PropsWithChildren } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import FieldWrapper from '../FieldWrapper';
import { ArrayFieldName, ArrayProps, FieldType } from '../../types';
import ArrayItem from './ArrayItem';
import ArrayContext from './ArrayContext';

const ArrayWrapper = <T, N extends ArrayFieldName<T> = ArrayFieldName<T>>({
  name,
  label,
  description,
  required,
  isStatic,
  isSortable,
  headerFormat,
  children,
  twoColumns,
  component
}: PropsWithChildren<ArrayProps<T, N>>) => {
  const { control } = useFormContext<T>();
  const { fields, append, remove, move } = useFieldArray<T>({ control, name: name as ArrayFieldName<T> });

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (active.id !== over?.id) {
      const oldIndex = fields.findIndex(item => item.id === active.id);
      const newIndex = fields.findIndex(item => item.id === over?.id);

      move(oldIndex, newIndex);
    }
  };

  const arrayItems = fields.map((item, index) => (
    <ArrayItem
      key={item.id}
      item={item}
      title={headerFormat ? headerFormat(item) : ''}
      index={index}
      twoColumns={twoColumns}
    >
      {Array.isArray(children) ? flatten(children) : children}
    </ArrayItem>
  ));

  const singleComponentItems = fields.map((item, index) => (
    <ArrayItem
      key={item.id}
      item={item}
      title={headerFormat ? headerFormat(item) : ''}
      index={index}
      twoColumns={twoColumns}
      singleComponentMode={!!component}
    >
      {component}
    </ArrayItem>
  ));

  const items = component ? singleComponentItems : arrayItems;

  const emptyState = (
    <div style={{ border: '2px dashed #bdbdbd' }} className="w-100 text-center rounded p-2">
      <small className="text-muted">empty</small>
    </div>
  );

  const itemOrEmpty = items.length ? <div className="border-start border-dark border-4 ps-2">{items}</div> : emptyState;

  return (
    <ArrayContext.Provider value={{ arrayName: name, isSortable, isStatic, remove }}>
      <FieldWrapper name={name} type={FieldType.ARRAY} label={label} description={description} required={required}>
        {isSortable ? (
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd}>
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              {itemOrEmpty}
            </SortableContext>
          </DndContext>
        ) : (
          itemOrEmpty
        )}
        {isStatic ? null : (
          <div className="w-100 text-center">
            <button
              type="button"
              className="btn btn-block btn-outline-primary mt-4 mx-auto px-5"
              onClick={() => append({})}
            >
              + Add
            </button>
          </div>
        )}
      </FieldWrapper>
    </ArrayContext.Provider>
  );
};

export default ArrayWrapper;
