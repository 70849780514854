import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { connect } from 'react-redux';
import { Form as RFForm } from 'react-final-form';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Form, Row } from '../../../Atoms';
import FormField from '../../../FormField';
import { TYPE_INSTANCE } from '../../../../utils/propTypes';
import { instancesActions } from '../../../../modules/instances';
import { authSelectors } from '../../../../modules/auth';

const instanceEdit = b.with('instance-edit');

export class InstanceEdit extends React.PureComponent {
  get fields() {
    const { isReservationFeeAvailable } = this.props;

    return [
      { key: 'lead.reservationFee', label: 'Reservation Fee', type: 'text', hide: !isReservationFeeAvailable },
      { key: 'pdfNotes', label: 'PDF notes', type: 'textarea' }
    ];
  }

  handleSubmitForm = instance => {
    this.props.update(instance);
  };

  renderForm = ({ handleSubmit, form, submitting, pristine }) => (
    <Form className={instanceEdit()} onSubmit={handleSubmit} autoComplete="off">
      <Modal
        isOpen
        toggle={this.props.onClose}
        centered
        className={instanceEdit()}
        backdrop={pristine ? true : 'static'}
        keyboard={pristine}
      >
        <ModalHeader toggle={this.props.onClose}>Edit instance</ModalHeader>
        <ModalBody className={instanceEdit('body')}>
          <Row>
            {this.fields
              .filter(field => !field.hide)
              .map(field => (
                <Col xs={12} key={field.key}>
                  <FormField field={field} />
                </Col>
              ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" disabled={submitting || pristine} onClick={form.submit}>
            Save
          </Button>
          <Button onClick={form.reset} disabled={submitting || pristine}>
            Reset
          </Button>
          <Button color="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  );

  render() {
    const { instance } = this.props;

    return <RFForm onSubmit={this.handleSubmitForm} initialValues={instance} render={this.renderForm} />;
  }
}

InstanceEdit.defaultProps = {
  isReservationFeeAvailable: false
};

InstanceEdit.propTypes = {
  instance: TYPE_INSTANCE.isRequired,
  update: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isReservationFeeAvailable: PropTypes.bool
};

const mapStateToProps = state => ({
  isReservationFeeAvailable: authSelectors.selectUserRequestsShowReservationFee(state)
});

const mapDispatchToProps = {
  update: instancesActions.updateInstance
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceEdit);
