import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { assetsSelectors, assetsActions, ASSET_TYPES } from '../../../modules/assets';
import { TYPE_ASSET_TYPES, TYPE_ASSET_LIST } from '../../../utils/propTypes';
import AssetGroup from '../AssetGroup';
import makeAssetUrl from '../../../utils/api/makeAssetUrl';

function useAssetsFunctions(type) {
  const dispatch = useDispatch();

  return {
    assetsList: useSelector(assetsSelectors[type].selectList),
    getAssetUrl: makeAssetUrl(type),
    assetsInfo: useSelector(assetsSelectors[type].selectAssetsParents),
    selectAssetsParentsRequesting: useSelector(assetsSelectors[type].selectAssetsParentsRequesting),
    deleteAsset: useCallback((id, unsafe) => dispatch(assetsActions[type].delete(id, unsafe)), [dispatch, type]),
    setProject: useCallback(
      (id, projectId) => dispatch(assetsActions[type].setProject(id, projectId)),
      [dispatch, type]
    ),
    getParent: useCallback(filename => dispatch(assetsActions[type].getParent(filename)), [dispatch, type]),
    copyToProject: useCallback(
      (id, projectId) => dispatch(assetsActions[type].copyToProject(id, projectId)),
      [dispatch, type]
    )
  };
}

const AssetGroupPicker = ({
  assetType,
  filteredImagesList,
  filteredTexturesList,
  filteredModelsList,
  selectedProject,
  hideChangingProjects
}) => {
  const {
    deleteAsset,
    setProject,
    getParent,
    copyToProject,
    getAssetUrl,
    isLoading,
    assetsInfo,
    assetsList,
    assetParentsRequesting
  } = useAssetsFunctions(assetType);

  let list = filteredImagesList;

  if (assetType === ASSET_TYPES.TEXTURES) {
    list = filteredTexturesList;
  }

  if (assetType === ASSET_TYPES.MODELS) {
    list = filteredModelsList;
  }

  return (
    <AssetGroup
      list={list}
      deleteAsset={deleteAsset}
      setAssetProject={setProject}
      getAssetParent={getParent}
      copyAssetToProject={copyToProject}
      getAssetUrl={getAssetUrl}
      isLoading={isLoading}
      assetsInfo={assetsInfo}
      assetsList={assetsList}
      assetParentsRequesting={assetParentsRequesting}
      selectedProject={selectedProject}
      hideChangingProjects={hideChangingProjects}
      assetType={assetType}
    />
  );
};

AssetGroupPicker.propTypes = {
  assetType: TYPE_ASSET_TYPES.isRequired,
  filteredImagesList: TYPE_ASSET_LIST,
  filteredTexturesList: TYPE_ASSET_LIST,
  filteredModelsList: TYPE_ASSET_LIST,
  selectedProject: PropTypes.string,
  hideChangingProjects: PropTypes.bool
};

AssetGroupPicker.defaultProps = {
  filteredImagesList: [],
  filteredTexturesList: [],
  filteredModelsList: [],
  selectedProject: '',
  hideChangingProjects: false
};

export default AssetGroupPicker;
